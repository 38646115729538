import { memo, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { IconButton, ButtonToolbar, RadioGroup, Radio, Whisper, Popover, Tag, Loader } from "rsuite";
import { MdTune } from "react-icons/md";
import InfiniteScroll from "react-infinite-scroller";

import HeaderComponent from "components/HeaderComponent";
import SelectComponent from "components/forms/SelectComponent";
// import PlaylistsOverviewComponent from "components/playlist/PlaylistsOverviewComponent";
import PlaylistsSideNavComponent from "components/playlist/PlaylistsSideNavComponent";
import PlaylistsFormComponent from "components/playlist/PlaylistsFormComponent";
import PlaylistsDetailComponent from "components/playlist/PlaylistsDetailComponent";
import { fetchPlaylists, getPlaylists, addPlaylists } from "redux/slices/playlistsSlice";
import { fetchPlayers } from "redux/slices/playersSlice";
import { fetchCategories } from "redux/slices/categoriesSlice";
import { deletePlaylist } from "redux/slices/playlistSlice";
import useLayout from "hooks/useLayout";
import useFilter from "hooks/useFilter";
import nextItems from "helpers/next-items";
import { loadingStatus } from "helpers/fetcher";
import Mobile from "./Mobile";
import Desktop from "./Desktop";
import { playlistsFormInitialData } from "constants/form";
import { CAMPAIGNS_STATES } from "constants";
import CategoriesComponent from "components/CategoriesComponent";

const contentBarContents = {
  PLAYLISTS_OVERVIEW: "playlists_overview",
  PLAYLISTS_FORM: "playlists_form",
  PLAYLISTS_DETAIL: "playlists_detail",
};

const PAGE_FILTERS = {
  all: { label: "Все плейлисты", value: "" },
  online: { label: CAMPAIGNS_STATES.running.label, value: "state=running" },
  offline: { label: CAMPAIGNS_STATES.pending.label, value: "state=pending" },
  not_activated: { label: CAMPAIGNS_STATES.finished.label, value: "state=finished" },
  deleted: { label: CAMPAIGNS_STATES.deleted.label, value: "state=deleted" },
};

const Playlists = () => {
  const [currentComponent, setCurrentComponent] = useState(contentBarContents.PLAYLISTS_OVERVIEW);
  const [selectedContentItem, setSelectedContentItem] = useState();
  const [reload, setReload] = useState();
  const [extraQueryString, setExtraQueryString] = useState("");
  const navigate  = useNavigate();
  const { "*": path } = useParams();
  const dispatch = useDispatch();
  const data = useSelector(state => state.playlists);
  const { searchState, playersCheckTreeState } = useSelector(state => state.layout);

  const {
    filterKey,
    additionalParams,
    setFilterKey,
    setAdditionalParam,
    queryString,
  } = useFilter({
    filters: PAGE_FILTERS,
    sorts: [],
    externalParams: ["name", "player_id", "type"],
  });
  const { isDesktop } = useLayout();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const paramsType = searchParams.get("type") || "schedule";

  const SideBar = memo(() => <PlaylistsSideNavComponent />);

  const handleDetailButton = (id) => {
    setCurrentComponent(contentBarContents.PLAYLISTS_DETAIL);
    navigate(`/playlists/detail/${id}?type=${paramsType}`);
  };

  const handleCreateButton = () => {
    setCurrentComponent(contentBarContents.PLAYLISTS_FORM);
    setSelectedContentItem();
    navigate(`/playlists/form?type=${paramsType}`);
  };

  const handleEditButton = (item) => {
    setCurrentComponent(contentBarContents.PLAYLISTS_FORM);
    const payload = {
      ...playlistsFormInitialData,
      id: item.id,
      type: item.type,
      name: item.name,
      description: item.description,
      requirements: item.revision.requirements,
      data: item.revision.data,
      category_id: item.category_id,
    };
    console.log(payload)
    setSelectedContentItem(payload);
    navigate(`/playlists/form/${item.id}?type=${paramsType}`);
  };
  
  const handleCloneButton = item => {
    setCurrentComponent(contentBarContents.PLAYLISTS_FORM);
    const payload = {
      isClone: true,
      ...playlistsFormInitialData,
      id: item.id,
      type: item.type,
      name: item.name,
      description: item.description,
      requirements: item.revision.requirements,
      data: item.revision.data,
    };
    setSelectedContentItem(payload);
    navigate(`/playlists/clone/${item.id}?type=${paramsType}`);
  };

  const handleDeleteButton = async id => {
    await deletePlaylist(id);
    setReload(prev => !prev);
  };

  const onLoadMore = async () => {
    const _items = await nextItems(queryString, data.meta.offset, data.meta.limit, getPlaylists);
    if (_items.objects.length > 0) dispatch(addPlaylists(_items.objects));
  }

  const contentComponents = {
    [contentBarContents.PLAYLISTS_OVERVIEW]: null,
    [contentBarContents.PLAYLISTS_FORM]: (
      <PlaylistsFormComponent
        type={paramsType}
        reload={setReload}
        defaultValues={selectedContentItem}
        onClose={() => setCurrentComponent(contentBarContents.PLAYLISTS_OVERVIEW)}
      />
    ),
    [contentBarContents.PLAYLISTS_DETAIL]: (
      <PlaylistsDetailComponent
        type={paramsType}
        reload={setReload}
        onEdit={handleEditButton}
        onClone={handleCloneButton}
        onDelete={handleDeleteButton}
        onClose={() => setCurrentComponent(contentBarContents.PLAYLISTS_OVERVIEW)}
      />
    ),
  };

  useEffect(() => setAdditionalParam("name", searchState), [searchState]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const _qs = new URLSearchParams(queryString);
      if (paramsType) _qs.set("type", paramsType);
      let _queryString = `?${_qs.toString()}`;
      navigate(_queryString);
      const qs = `${_queryString}${extraQueryString}`;
      dispatch(fetchPlaylists(qs));
    }, 10);
    return () => clearTimeout(timer);
  }, [queryString, paramsType, extraQueryString, reload]);

  useEffect(() => {
    if (!path) setCurrentComponent(contentBarContents.PLAYLISTS_OVERVIEW);
  }, [path]);

  useEffect(() => {
    dispatch(fetchCategories("?type=playlists"));
  }, []);

  const actions = {
    handleCreateButton,
    handleDetailButton,
    handleEditButton,
    handleCloneButton,
    handleDeleteButton,
    onContentbarClose: () => setCurrentComponent(null),
  };

  return (
    <>
      <HeaderComponent
        title={
          <>
            {isDesktop ? "Плейлисты" : <div>Плейлисты</div>}
            {" "}
            <Tag size="sm">{PAGE_FILTERS[filterKey].label}</Tag>
          </>
        }
        right={
          <ButtonToolbar>
            <Whisper
              trigger="click"
              placement="bottomEnd"
              speaker={
                <Popover arrow={false}>
                  <FilterForm
                    value={filterKey}
                    onChange={setFilterKey}
                    selectedPlayer={additionalParams.player_id}
                    onChangePlayer={id => setAdditionalParam("player_id", id)}
                  />
                </Popover>
              }
            >
              <IconButton icon={<MdTune size="1.5em" />} appearance="ghost" />
            </Whisper>
          </ButtonToolbar>
        }
      />
      {playersCheckTreeState ? (
        <CategoriesComponent
          onQueryString={setExtraQueryString}
          categoryType="playlists"
        />
      ) : (
        ""
      )}
      <InfiniteScroll
        initialLoad={false}
        threshold={0}
        hasMore={data.status !== loadingStatus.LOADING && data.objects.length < data.meta.total}
        loader={<p className="text-center"><Loader size="md" /></p>}
        loadMore={onLoadMore}
      >
        {isDesktop ? (
          <Desktop
            type={paramsType}
            data={data}
            sideBar={<SideBar />}
            contentBar={contentComponents[currentComponent]}
            {...actions}
          />
        ) : (
          <Mobile
            type={paramsType}
            data={data}
            sideBar={<SideBar />}
            contentBar={contentComponents[currentComponent]}
            {...actions}
          />
        )}
      </InfiniteScroll>
    </>
  );
};

const FilterForm = ({ value, onChange, selectedPlayer, onChangePlayer }) => {
  const dispatch = useDispatch();
  const { objects: players } = useSelector(state => state.players);

  useEffect(() => {
    dispatch(fetchPlayers("?hide_children=1&limit=-1"));
  }, [dispatch]);

  return (
    <div className="caps-main">
      <h6 className="mb-20">Фильтры</h6>
      <RadioGroup
        name="filter-list"
        value={value}
        onChange={onChange}
      >
        {Object.keys(PAGE_FILTERS).map(f => (
          <Radio key={`filter-${f}`} value={f}>
            {PAGE_FILTERS[f].label}
          </Radio>
        ))}
      </RadioGroup>
      <hr />
      <SelectComponent
        items={[
          { value: "", label: "Отобрать по устройству"},
          ...players.map(({ id, name }) => ({ value: id, label: name })),
        ]}
        value={selectedPlayer}
        onChange={onChangePlayer}
      />
    </div>
  );
};

export default Playlists;
