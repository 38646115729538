import { useState } from "react";
import { RadioGroup, Radio, Button } from "rsuite";

import { fetchPlayersReport } from "redux/slices/playersSlice";
import { downloadFromLink } from "helpers/utils";

const PlayerExportComponent = () => {
  const [exportPlayersString, setExportPlayersString] = useState("?type=single&format=xlsx");

  const handleExportDownload = async () => {
    const xlsxBlob = await fetchPlayersReport(exportPlayersString);
    const url = window.URL.createObjectURL(new Blob([xlsxBlob]));
    downloadFromLink(url, `Players${exportPlayersString.split("xlsx")[1]}.xlsx`);
  };

  return (
    <>
      <h6>Экспорт устройств</h6>
      <hr />
      <RadioGroup
        name="export-list"
        value={exportPlayersString}
        onChange={setExportPlayersString}
      >
        <Radio value="?type=single&format=xlsx">Все</Radio>
        <Radio value="?type=single&format=xlsx&online=1">Онлайн</Radio>
        <Radio value="?type=single&format=xlsx&online=null">Все офлайн</Radio>
        <Radio value="?type=single&format=xlsx&last_online_at__lt=-1">Офлайн более 1 суток</Radio>
        <Radio value="?type=single&format=xlsx&last_online_at__lt=-2">Офлайн более 2 суток</Radio>
        <Radio value="?type=single&format=xlsx&activated=1">Активированные</Radio>
        <Radio value="?type=single&format=xlsx&activated=2">Не активированные</Radio>
      </RadioGroup>
      <hr />
      <Button appearance="primary" onClick={handleExportDownload}>Скачать</Button>
    </>
  );
};

export default PlayerExportComponent;
