import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IconButton, Message, ButtonGroup, List } from "rsuite";
import {
  MdEdit,
  MdContentCopy,
  MdFileDownload,
  MdTextSnippet,
  MdDeleteOutline,
  MdSync,
  MdOutlineStopCircle,
} from "react-icons/md";

import HeaderComponent from "components/HeaderComponent";
import {
  fetchCampaignReport,
  patchCampaign,
  requestReports,
} from "redux/slices/campaignSlice";
import usePopup from "hooks/usePopup";
import { formatDate } from "helpers/date";
import { downloadFromLink } from "helpers/utils";
import { SERVER_URL } from "helpers/fetcher";

const CampaignsSettingComponent = ({ reload, onEdit, onClone, onDelete }) => {
  const dispatch = useDispatch();
  const {
    data: campaign,
    campaignReport: { report },
  } = useSelector((state) => state.campaign);
  const { responseToaster } = usePopup();

  const handleForceFinishCampaign = async () => {
    const res = await patchCampaign(campaign.id, { terminated: true });
    responseToaster(res);
    reload((prev) => !prev);
  };

  const handleReports = async () => {
    const res = await requestReports(campaign.id);
    if (res.ok)
      setTimeout(() => dispatch(fetchCampaignReport(campaign.id)), 3000);
    responseToaster(res);
  };

  useEffect(() => {
    dispatch(fetchCampaignReport(campaign.id));
  }, [dispatch, campaign.id]);

  return (
    <div>
      <HeaderComponent title={campaign.name} tag="h3" gutter={0} />

      <HeaderComponent title="Управление" tag="h6" className="inner-header" />
      <ButtonGroup justified>
        {["pending", "running"].includes(campaign.state) && (
          <IconButton
            appearance="ghost"
            icon={<MdEdit size="1.4em" />}
            onClick={() => onEdit(campaign)}
          />
        )}
        {campaign.state === "running" && (
          <IconButton
            appearance="ghost"
            icon={
              <MdOutlineStopCircle
                size="1.4em"
                onClick={handleForceFinishCampaign}
              />
            }
          />
        )}
        <IconButton
          appearance="ghost"
          icon={<MdContentCopy size="1.4em" />}
          onClick={() => onClone(campaign)}
        />
        {campaign.state !== "running" && (
          <IconButton
            appearance="ghost"
            icon={<MdDeleteOutline size="1.4em" />}
            onClick={() => onDelete(campaign.id)}
          />
        )}
      </ButtonGroup>

      <HeaderComponent
        title="Отчет"
        tag="h6"
        right={
          <IconButton icon={<MdSync size="1.4em" />} onClick={handleReports} />
        }
        className="inner-header"
      />
      {report ? (
        <List hover bordered={false}>
          <List.Item>
            <IconButton
              icon={<MdFileDownload className="rs-icon" />}
              placement="right"
              appearance="link"
              onClick={() =>
                downloadFromLink(
                  `${SERVER_URL}${report.url.xlsx}`,
                  report.datetime
                )
              }
            >
              <MdTextSnippet />{" "}
              {formatDate(report.datetime, ".").full_datetime}
            </IconButton>
          </List.Item>
        </List>
      ) : (
        <Message type="warning">Нет отчета</Message>
      )}
    </div>
  );
};

export default CampaignsSettingComponent;
