import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher from "helpers/fetcher";
import { players, content } from "constants/endpoints";

const fetchPlayersTags = createAsyncThunk("players/tags", async (queryString = "") => {
  const response = await fetcher(`${players.TAGS}${queryString}`);
  return response.json();
});
const createPlayersTag = createAsyncThunk("players/tags/create", async (data) => {
  const response = await fetcher(players.TAGS, {
    method: "POST",
    body: JSON.stringify(data),
  });
  return response.json();
});
const deletePlayersTag = createAsyncThunk("players/tags/delete", async (playerTagId) => {
  await fetcher(`${players.TAGS}/${playerTagId}`, { method: "DELETE" });
  return playerTagId;
});

const fetchContentTags = createAsyncThunk("content/tags", async (queryString = "") => {
  const response = await fetcher(`${content.TAGS}${queryString}`);
  return response.json();
});
const createContentTag = createAsyncThunk("content/tags/create", async (data) => {
  const response = await fetcher(content.TAGS, {
    method: "POST",
    body: JSON.stringify(data),
  });
  return response.json();
});
const deleteContentTag = createAsyncThunk("content/tags/delete", async (contentTagId) => {
  await fetcher(`${content.TAGS}/${contentTagId}`, { method: "DELETE" });
  return contentTagId;
});

const initialState = {
  playersTags: [],
  contentTags: [],
};

const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPlayersTags.fulfilled, (state, action) => {
        state.playersTags = action.payload;
      })
      .addCase(createPlayersTag.fulfilled, (state, action) => {
        state.playersTags = [action.payload, ...state.playersTags];
      })
      .addCase(deletePlayersTag.fulfilled, (state, action) => {
        state.playersTags = state.playersTags.filter(t => t.id !== action.payload);
      })

      .addCase(fetchContentTags.fulfilled, (state, action) => {
        state.contentTags = action.payload;
      })
      .addCase(createContentTag.fulfilled, (state, action) => {
        state.contentTags = [action.payload, ...state.contentTags];
      })
      .addCase(deleteContentTag.fulfilled, (state, action) => {
        state.contentTags = state.contentTags.filter(t => t.id !== action.payload);
      })
  }
});

export {
  fetchPlayersTags,
  createPlayersTag,
  deletePlayersTag,
  fetchContentTags,
  createContentTag,
  deleteContentTag,
};

export default tagsSlice.reducer;
