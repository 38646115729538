import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IconButton, ButtonToolbar, DateRangePicker, RadioGroup, Radio, Whisper, Popover } from "rsuite";
import { MdTune, MdSort } from "react-icons/md";

import HeaderComponent from "components/HeaderComponent";
import AnalyticsDetailComponent from "components/analytics/AnalyticsDetailComponent";
import SelectComponent from "components/forms/SelectComponent";

import { fetchAnalytics } from "redux/slices/analyticsSlice";
import useLayout from "hooks/useLayout";
import { formatDate } from "helpers/date";
import Desktop from "./Desktop";
import Mobile from "./Mobile";

const contentBarContents = {
  ANALYTICS_OVERVIEW: "analytics_overview",
  ANALYTICS_DETAIL: "analytics_detail",
};

const Analytics = () => {
  const [currentContent, setCurrentContent] = useState(contentBarContents.ANALYTICS_OVERVIEW);
  const [selectedContentItem, setSelectedContentItem] = useState();

  const [selectedPlayer, setSelectedPlayer] = useState();
  const [sortString, setSortString] = useState("date.ASC");

  const [dateRange, setDateRange] = useState(() => {
    let end = new Date();
    end.setDate(end.getDate() + 7);
    return [new Date(), end];
  });
  // const [reload, setReload] = useState();
  const dispatch = useDispatch();
  const data = useSelector(state => state.analytics);
  const { isDesktop } = useLayout();

  const handleAnalyticsDetailButton = (item) => {
    setCurrentContent(contentBarContents.ANALYTICS_DETAIL);
    setSelectedContentItem(item);
    // navigate(`/analytics/${id}`);
    // console.log(item)
  };

  const contentComponents = {
    [contentBarContents.ANALYTICS_OVERVIEW]: null,
    [contentBarContents.ANALYTICS_DETAIL]: <AnalyticsDetailComponent data={selectedContentItem} />,
  };

  useEffect(() => {
    if (dateRange) {
      const [s, e] = [formatDate(dateRange[0], "-").full, formatDate(dateRange[1], "-").full];
      dispatch(fetchAnalytics(`?start=${s}&end=${e}`));
    }
  }, [dispatch, dateRange]);

  const actions = {
    handleAnalyticsDetailButton,
    onContentbarClose: () => setCurrentContent(null),
  };

  return (
    <>
      <HeaderComponent
        title={
          <>
            {isDesktop ? (
              <span className="mr-20">Аналитика</span>
            ) : (
              <div className="mb-10">Аналитика</div>
            )}
            <DateRangePicker value={dateRange} onChange={setDateRange} />
          </>
        }
        right={
          <ButtonToolbar>
            <Whisper
              trigger="click"
              placement="bottomEnd"
              speaker={
                <Popover arrow={false}>
                  <FilterForm
                    players={data?.data?.players ? Object.values(data.data.players) : []}
                    value={selectedPlayer}
                    onChange={setSelectedPlayer}
                  />
                </Popover>
              }
            >
              <IconButton icon={<MdTune size="1.5em" />} appearance="ghost" />
            </Whisper>
            <Whisper
              trigger="click"
              placement="bottomEnd"
              speaker={
                <Popover arrow={false}>
                  <SortForm value={sortString} onChange={setSortString} />
                </Popover>
              }
            >
              <IconButton icon={<MdSort size="1.5em" />} appearance="ghost" />
            </Whisper>
          </ButtonToolbar>
        }
      />
      {isDesktop ? (
        <Desktop
          data={data}
          filter={selectedPlayer}
          sort={sortString}
          contentBar={contentComponents[currentContent]}
          {...actions}
        />
      ) : (
        <Mobile
          data={data}
          filter={selectedPlayer}
          sort={sortString}
          contentBar={contentComponents[currentContent]}
          {...actions}
        />
      )}
    </>
  );
};

const FilterForm = ({ players, value, onChange }) => (
  <div className="caps-main">
    <h6 className="mb-20">Фильтры</h6>
    <SelectComponent
      items={[
        { value: "", label: "Отобрать по устройству"},
        ...players.map(({ id, name }) => ({ value: id, label: name })),
      ]}
      value={value}
      onChange={onChange}
    />
  </div>
);

const SortForm = ({ value, onChange }) => (
  <div className="caps-main">
    <h6 className="mb-20">Сортировка</h6>
    <RadioGroup
      name="sort-list"
      value={value}
      onChange={onChange}
    >
      <Radio value="date.ASC">По дате создание ↓</Radio>
      <Radio value="date.DESC">По дате создание ↑</Radio>
      <Radio value="alphabet.ASC">По наименованию ↓</Radio>
      <Radio value="alphabet.DESC">По наименованию ↑</Radio>
    </RadioGroup>
  </div>
);

export default Analytics;
