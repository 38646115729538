import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { IconButton, Badge } from "rsuite";
import { MdDragHandle, MdModeEdit, MdOutlineDelete } from "react-icons/md";
import classNames from "classnames";

const FileItemComponent = ({
  id,
  draggable,
  badge,
  icon,
  title,
  subTitle,
  onClick,
  onEdit,
  onRemove,
  wrapClassName = "item-list__item",
}) => {
  const { "*": path } = useParams();
  const pathId = useMemo(() => {
    const _pathId = path.split("/").pop();
    return _pathId;
  }, [path]);

  const handleEdit = (e) => {
    e.stopPropagation();
    onEdit();
  };

  const handleRemove = (e) => {
    e.stopPropagation();
    onRemove();
  };

  return (
    <div
      className={classNames(wrapClassName, { active: `${pathId}` === `${id}` })}
      onClick={onClick}
    >
      <div className="item-list__item-actions">
        {onEdit ? (
          <IconButton
            icon={<MdModeEdit size="1.4em" />}
            appearance="ghost"
            onClick={handleEdit}
          />
        ) : null}
        {onRemove ? (
          <IconButton
            icon={<MdOutlineDelete size="1.4em" color="#FC6461" />}
            appearance="ghost"
            onClick={handleRemove}
          />
        ) : null}
      </div>
      <div className={classNames("item-list__item-content", { "with-badge": (draggable || badge) } )}>
        {draggable ? (
          <div className="item-list__item-content-badge">
            <MdDragHandle size="1.4em" />
          </div>
        ) : null}
        {badge ? (
          <div className="item-list__item-content-badge">
            <Badge color="green" content={badge} />
          </div>
        ) : null}
        {icon ? (
          <div className="item-list__item-content-icon">
            {icon}
          </div>
        ) : null}
        <div className="item-list__item-content-text">
          {title}
          {subTitle && (
            <div className="small">
              {subTitle}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileItemComponent;
