import { useEffect, useRef } from "react";
import { MdAllInclusive } from "react-icons/md";

const INTERVALS = [{ elem: <><MdAllInclusive /> / 1</>, value: 1 },2,3,4,5,6,8,10,12,15,20,24,30,36,40,60,72,120,240];

const IntervalCarouselComponent = ({ interval, onChange }) => {
  const carouselRef = useRef();

  // useEffect(() => {
  //   // carouselRef.current.children.reduce((p, c) => p + c, 0)
  //   let carouselWidth = 0;
  //   for (const el of carouselRef.current.children) {
  //     carouselWidth += parseFloat(getComputedStyle(el).width);
  //   }
  //   carouselRef.current.style.width = carouselWidth;
  // }, []);

  return (
    <div className="carousel-container">
      <ul className="carousel-list" ref={carouselRef}>
        {INTERVALS.map((item, i) => {
          if (typeof item === "object") {
            return (
              <li
                key={item.value + i}
                onClick={() => onChange(item.value)}
                className={item.value === interval ? "active" : null}
              >
                {item.elem}
              </li>
            );
          }
          return (
            <li
              key={item + i}
              onClick={() => onChange(item)}
              className={item === interval ? "active" : null}
            >
              {item}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default IntervalCarouselComponent;
