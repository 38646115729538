import {
  MdPhonelink,
  MdCloudQueue,
  MdOutlineVideoLibrary,
  MdPlaylistPlay,
  MdOutlineWidgets,
  MdShowChart,
  MdCached,
  MdOutlineNotificationsNone,
  MdSearch,
  MdOutlineSell,
  MdSettings,

  MdList,
  MdIosShare,
  MdRoom,
  MdAvTimer,
  MdBrightnessLow,
  MdShuffle,
  MdOutlineTask,
  MdCategory,
} from "react-icons/md";

import {
  players, campaigns, playlists, contents, analytics, settings,
} from "constants/urls";
import { iconStyle } from "constants";

const headerNav = {
  left: [
    {
      title: "Устройства",
      action: players.ALL,
      icon: <MdPhonelink size={iconStyle.HEADER_ICON_SIZE_SM} />,
      id: "header-players",
    },
    {
      title: "Контент",
      action: contents.FILES,
      icon: <MdCloudQueue size={iconStyle.HEADER_ICON_SIZE_SM} />,
      id: "header-cloud",
    },
    {
      title: "Кампании",
      action: campaigns.ALL,
      icon: <MdOutlineVideoLibrary size={iconStyle.HEADER_ICON_SIZE_SM} />,
      id: "header-campaigns",
    },
    {
      title: "Плейлисты",
      action: playlists.ALL,
      icon: <MdPlaylistPlay size={iconStyle.HEADER_ICON_SIZE_SM} />,
      id: "header-playlists",
    },
    {
      title: "Виджеты",
      action: contents.WIDGETS,
      icon: <MdOutlineWidgets size={iconStyle.HEADER_ICON_SIZE_SM} />,
      id: "header-widgets",
    },
    {
      title: "Аналитика",
      action: analytics.ALL,
      icon: <MdShowChart size={iconStyle.HEADER_ICON_SIZE_SM} />,
      id: "header-analytics",
    },
  ],
  right: [
    {
      title: "Синхронизация",
      action: "sync-notifications-action",
      icon: <span><div className="spin-animations-infinite"><MdCached size={iconStyle.HEADER_ICON_SIZE_SM} /></div></span>,
      id: "header-sync-notifications",
    },
    {
      title: "Оповещения",
      action: "robot-notifications-action",
      icon: <MdOutlineNotificationsNone size={iconStyle.HEADER_ICON_SIZE_SM} />,
      id: "header-robot-notifications",
    },
    {
      title: "Поиск",
      action: "entity-search-action",
      icon: <MdSearch size={iconStyle.HEADER_ICON_SIZE_SM} />,
      id: "header-search",
    },
    {
      title: "Теги",
      action: "tag-drawer-action",
      icon: <MdOutlineSell size={iconStyle.HEADER_ICON_SIZE_SM} />,
      id: "header-tags",
    },
    {
      title: "Настройки",
      action: settings.ALL,
      icon: <MdSettings size={iconStyle.HEADER_ICON_SIZE_SM} />,
      id: "header-settings",
    },
  ],
};

const sideNavPlayers = [
  {
    title: "Список устройств",
    action: players.ALL,
    icon: <MdList />,
    id: "players-list",
  },
  {
    title: "Карта устройств",
    action: players.MAP,
    icon: <MdRoom />,
    id: "players-map-pin",
  },
  {
    title: "Расписание перезагрузок",
    action: players.REBOOTS,
    icon: <MdAvTimer />,
    id: "players-reboot-schedule",
  },
  {
    title: "Расписание яркости",
    action: players.BRIGHTNESS,
    icon: <MdBrightnessLow />,
    id: "players-brightness-schedule",
  },
];

const sideNavCampaigns = [
  {
    title: "Список устройств",
    action: campaigns.ALL,
    icon: <MdList />,
    id: "campaigns-list",
  },
  {
    title: "Филлеры",
    action: campaigns.FILLERS,
    icon: <MdShuffle />,
    id: "campaigns-fillers",
  },
  {
    title: "Экспорт кампаний",
    action: "campaigns-export-action",
    icon: <MdIosShare />,
    id: "campaigns-export",
  },
  {
    title: "Расписание статистики",
    action: campaigns.REPORT_SCHEDULES,
    icon: <MdOutlineTask />,
    id: "campaigns-report-schedule",
  },
];

const sideNavPlaylists = [
  {
    title: "Список плейлистов",
    action: `${playlists.ALL}?type=schedule`,
    icon: <MdList />,
    id: "playlists-list",
  },
  {
    title: "События",
    action: `${playlists.ALL}?type=external`,
    icon: <MdList />,
    id: "triggers-list",
  },
];

export {
  headerNav,
  sideNavPlayers,
  sideNavCampaigns,
  sideNavPlaylists,
};
