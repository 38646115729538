import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  IconButton,
  Message,
  ButtonGroup,
  Tooltip,
  Whisper,
  List,
} from "rsuite";
import {
  MdEdit,
  MdContentCopy,
  MdSync,
  MdReplay,
  MdLinkOff,
  MdBlockFlipped,
  MdDeleteOutline,
  MdAttachFile,
  MdFileDownload,
  MdCameraAlt,
} from "react-icons/md";

import {
  fetchLogs,
  fetchScreenshots,
  requestLogs,
  requestScreenshots,
  requestSynchronize,
  requestReboot,
  requestReset,
  requestUnbindCampaigns,
} from "redux/slices/playerSlice";
import HeaderComponent from "components/HeaderComponent";
import usePopup from "hooks/usePopup";
import { downloadFromLink } from "helpers/utils";
import { formatDate } from "helpers/date";

const PlayerSettingComponent = ({ reload, onEdit, onClone, onDelete }) => {
  const dispatch = useDispatch();
  const {
    data: player,
    logs,
    screenshots,
  } = useSelector((state) => state.player);
  const { responseToaster } = usePopup();

  const handleLogs = async () => {
    const res = await requestLogs(player.id);
    if (res.ok) setTimeout(() => dispatch(fetchLogs(player.id)), 3000);
    responseToaster(res);
  };

  const handleScreenshots = async () => {
    const res = await requestScreenshots(player.id);
    if (res.ok) setTimeout(() => dispatch(fetchScreenshots(player.id)), 3000);
    responseToaster(res);
  };

  const handleSynchronize = async () => {
    const res = await requestSynchronize(player.id);
    responseToaster(res);
  };

  const handleReboot = async () => {
    const res = await requestReboot(player.id);
    responseToaster(res);
  };

  const handleReset = async () => {
    const res = await requestReset(player.id);
    responseToaster(res);
    reload((prev) => prev + 1);
  };

  const handleUnbindCampaigns = async () => {
    const res = await requestUnbindCampaigns(player.id);
    responseToaster(res);
  };

  useEffect(() => {
    dispatch(fetchLogs(player.id));
    dispatch(fetchScreenshots(player.id));
  }, [dispatch, player.id]);

  const SettingButton = ({ tooltip, icon, onClick }) => (
    <Whisper
      placement="top"
      trigger="hover"
      speaker={<Tooltip>{tooltip}</Tooltip>}
    >
      <IconButton appearance="ghost" icon={icon} onClick={onClick} />
    </Whisper>
  );

  return (
    <div>
      <HeaderComponent title={player.name} tag="h3" gutter={0} />
      <ButtonGroup className="space-between">
        <SettingButton
          tooltip="Редактировать"
          icon={<MdEdit size="1.4em" />}
          onClick={() => onEdit(player)}
        />
        <SettingButton
          tooltip="Клонировать"
          icon={<MdContentCopy size="1.4em" />}
          onClick={() => onClone(player)}
        />
        <SettingButton
          tooltip="Удалить"
          icon={<MdDeleteOutline size="1.4em" />}
          onClick={() => onDelete(player.id)}
        />
      </ButtonGroup>

      <HeaderComponent title="Управление" tag="h6" className="inner-header" />
      <ButtonGroup className="space-between">
        <SettingButton
          tooltip="Синхронизировать"
          icon={<MdSync size="1.4em" />}
          onClick={handleSynchronize}
        />
        <SettingButton
          tooltip="Отвязать кампании"
          icon={<MdLinkOff size="1.4em" />}
          onClick={handleUnbindCampaigns}
        />
        <SettingButton
          tooltip="Перезагрузить плеер"
          icon={<MdReplay size="1.4em" />}
          onClick={handleReboot}
        />
        <SettingButton
          tooltip="Сбросить плеер"
          icon={<MdBlockFlipped size="1.4em" />}
          onClick={handleReset}
        />
      </ButtonGroup>

      <HeaderComponent
        title="Лог-файлы"
        tag="h6"
        right={
          <IconButton
            appearance="ghost"
            icon={<MdSync size="1.4em" />}
            onClick={handleLogs}
          />
        }
        className="inner-header"
      />
      {logs.length > 0 ? (
        <List hover bordered={false}>
          {logs.map((log) => (
            <List.Item key={`${log.id}`}>
              <IconButton
                icon={<MdFileDownload className="rs-icon" />}
                placement="right"
                appearance="link"
                onClick={() => downloadFromLink(log.path, log.created_at)}
              >
                <MdAttachFile /> {formatDate(log.created_at, ".").full_datetime}
              </IconButton>
            </List.Item>
          ))}
        </List>
      ) : (
        <Message type="warning">Нет ни одного лог файла</Message>
      )}

      <HeaderComponent
        title="Скриншоты"
        tag="h6"
        right={
          <IconButton
            icon={<MdCameraAlt size="1.4em" />}
            onClick={handleScreenshots}
          />
        }
        className="inner-header"
      />
      {screenshots.length > 0 ? (
        screenshots.map((screenshot) => (
          <img
            className="flex-image"
            src={screenshot.path}
            alt={screenshot.created_at}
            key={screenshot.id}
            onClick={() => downloadFromLink(screenshot.path, screenshot.created_at, "_blank")}
          />
        ))
      ) : (
        <Message type="warning">Нет ни скриншота</Message>
      )}
    </div>
  );
};

export default PlayerSettingComponent;
