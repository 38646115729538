import { createSlice } from "@reduxjs/toolkit";
import { getUserSetting, setUserSetting } from "helpers/storage-operations";

const initialState = {
  robotNotificationsDrawerState: getUserSetting("notifications-drawer-state"),
  tagDrawerState: getUserSetting("tag-drawer-state"),
  sideNavState: getUserSetting("side-nav-state"),
  searchState: "",
  contentListView: getUserSetting("content-list-view"),
  playersCheckTreeState: getUserSetting("players-check-tree-state"),
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    robotNotificationsDrawerToggle: (state) => {
      state.robotNotificationsDrawerState = !state.robotNotificationsDrawerState;
      setUserSetting("manual-notifications-drawer-state", state.robotNotificationsDrawerState);
    },
    tagDrawerToggle: (state) => {
      state.tagDrawerState = !state.tagDrawerState;
      setUserSetting("tag-drawer-state", state.tagDrawerState);
    },
    sideNavToggle: (state) => {
      state.sideNavState = !state.sideNavState;
      setUserSetting("side-nav-state", state.sideNavState);
    },
    searchAction: (state, { payload }) => {
      state.searchState = payload;
    },
    contentListSwitch: (state, { payload }) => {
      console.log(state)
      state.contentListView = payload;
      setUserSetting("content-list-view", payload);
    },
    playersCheckTreeToggle: (state) => {
      state.playersCheckTreeState = !state.playersCheckTreeState;
      setUserSetting("players-check-tree-state", state.playersCheckTreeState);
    },
  },
});

export const {
  robotNotificationsDrawerToggle,
  tagDrawerToggle,
  sideNavToggle,
  searchAction,
  contentListSwitch,
  playersCheckTreeToggle,
} = layoutSlice.actions;

export default layoutSlice.reducer;
