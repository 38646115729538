import PlaylistsGridComponent from "components/playlist/PlaylistsGridComponent";
import ButtonCreateComponent from "components/buttons/ButtonCreateComponent";
import DesktopLayout from "components/layouts/DesktopLayout";

const TITLE = { schedule: "Плейлист", external: "Событие" };

const Desktop = ({
  type,
  data: { objects, meta, status, error },
  sideBar,
  contentBar,
  handleCreateButton,
  handleDetailButton,
  handleEditButton,
  handleCloneButton,
  handleDeleteButton,
  onContentbarClose,
}) => {

  return (
    <DesktopLayout sideBar={sideBar} contentBar={contentBar} onContentbarClose={onContentbarClose}>
      <PlaylistsGridComponent
        buttonCreate={
          <ButtonCreateComponent onClick={handleCreateButton}>
            {`Создать ${TITLE[type]}`}
          </ButtonCreateComponent>
        }
        items={objects}
        status={status}
        error={error}
        onClick={handleDetailButton}
        onEdit={handleEditButton}
        onClone={handleCloneButton}
        onDelete={handleDeleteButton}
      />
    </DesktopLayout>
  );
};

export default Desktop;
