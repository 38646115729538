import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { robotNotificationsDrawerToggle, tagDrawerToggle } from "redux/slices/layoutSlice";
import DrawerComponent from "components/DrawerComponent";
import TagsFormComponent from "components/forms/TagsFormComponent";
import RobotNotificationsComponent from "components/notifications/RobotNotificationsComponent";

const TagDrawerComponent = memo(({ open, onClose, children }) => (
  <DrawerComponent open={open} onClose={onClose}>
    {children}
  </DrawerComponent>
));

const DrawersComponent = () => {
  const dispatch = useDispatch();
  const { robotNotificationsDrawerState, tagDrawerState } = useSelector(state => state.layout);

  const handleRobotNotificationsDrawerToggle = () => dispatch(robotNotificationsDrawerToggle());
  const handleTagDrawerToggle = () => dispatch(tagDrawerToggle());

  return (
    <>
      <TagDrawerComponent
        open={robotNotificationsDrawerState}
        onClose={handleRobotNotificationsDrawerToggle}
      >
        <RobotNotificationsComponent />
      </TagDrawerComponent>
      <TagDrawerComponent
        open={tagDrawerState}
        onClose={handleTagDrawerToggle}
      >
        <TagsFormComponent />
      </TagDrawerComponent>
    </>
  );

};

export default DrawersComponent;
