import { Avatar, Message, Tag } from "rsuite";
import FileItemComponent from "components/files/FileItemComponent";
import { convertBytes, getThumbnail } from "helpers/utils";

const AnalyticsDetailComponent = ({ data }) => {

  // console.log(data);

  // const findIntervalValue = (values, player_id, index) => (
  //   Math.ceil((values?.[index]?.players?.[player_id] || 0) * 100)
  // );

  if (!data) return (<div>Для получения отчета выберите</div>);

  if (!data.data?.length) return (<div>Нет данных для отображения</div>);

  return (
    <div className="analytic-device-header">
      <h5 className="analytic-device-name">{data.player.player}</h5>
      <div className="analytic-device-address mb-1">{data.player.address}</div>
      {data.data.map((t, index) => {
        const interval = (t.start === '00:00' && t.end === '00:00') ? 'сутки' : `с ${t.start} по ${t.end}`

        return (
          <>
            <div key={`p_data-${index}`} className="mb-75">
              <div className="mb-10">
                <Message>{`${data.display.week} / ${data.display.date} / ${interval}`}</Message>
              </div>
              <div className="d-flex justify-content-between">
                <div className="ad-campaigns-left">
                  <div className="ad-cam-status mb-10">
                    Общая длительность: <strong>{`${t.total_duration.toFixed(3)} сек`}</strong>
                  </div>
                  <div className="ad-cam-status">
                    Длительность рекламы: <strong>{`${t.ad_duration.toFixed(3)} сек`}</strong>
                  </div>
                </div>
                {/* <div className="ad-campaigns-right">
                  <h6 className="text-center">Эффективность</h6>
                  <div className="ad-cam-profit">
                    {`${findIntervalValue(data.display.intervalValues, data.player.player_id, index)}%`}
                  </div>
                </div> */}
              </div>
            </div>
            
            <div className="timeline-playlist-wr">
                <div className="item-list">
                {t.playlist.map((p, p_index) => {
                  const { content_item: contentItem } = p.content_items[0]
                  const file = contentItem.content_files[0].content_file;
                  const duration = `${contentItem.data.default_duration || file.data.duration} сек`;
                  return (
                    <div key={`p_index-${p_index + index}`}>
                      <FileItemComponent
                        icon={<Avatar src={getThumbnail(contentItem)} size="lg" />}
                        title={contentItem.name}
                        subTitle={<><Tag>{convertBytes(file.size).mb}</Tag> <Tag>{duration}</Tag></>}
                      />
                    </div>
                  )}
                )}
              </div>
            </div>
          </>
        )
      })}
    </div>
  );
};

export default AnalyticsDetailComponent;