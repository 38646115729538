import { useState } from "react";

import WidgetsGridComponent from "components/widgets/WidgetsGridComponent";
import DrawerComponent from "components/DrawerComponent";

const Mobile = ({
  data,
  contentBar,
  handleInstallButton,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleOnInstallDrawer = (item) => {
    setDrawerOpen(true);
    handleInstallButton(item);
  };

  const handleOnCloseDrawer = () => setDrawerOpen(false);

  return (
    <>
      <WidgetsGridComponent
        items={data.objects}
        status={data.status}
        error={data.error}
        onClick={handleOnInstallDrawer}
      />
      <DrawerComponent
        open={drawerOpen}
        onClose={handleOnCloseDrawer}
        size="full"
        title="Детали записи"
      >
        {contentBar}
      </DrawerComponent>
    </>
  );
};

export default Mobile;
