import { IconButton } from "rsuite";
import { MdClose } from "react-icons/md";

import useLayout from "hooks/useLayout";

const CloseButtonComponent = ({ onClose }) => {
  const { isDesktop } = useLayout();

  return isDesktop ? (
    <IconButton
      size="lg"
      appearance="ghost"
      icon={<MdClose />}
      onClick={onClose}
    />
  ) : null;
};

export default CloseButtonComponent;
