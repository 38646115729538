import { useRef, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PlacesAutocomplete from "react-google-autocomplete";
import { cloneDeep } from "lodash";
import {
  Form,
  ButtonToolbar,
  Button,
  FlexboxGrid,
  Schema,
  TagPicker,
  Message,
  InputNumber,
  CheckTreePicker,
  RadioGroup,
  Radio,
} from "rsuite";

import HeaderComponent from "components/HeaderComponent";
import InputFieldComponent from "components/forms/InputFieldComponent";
import { createBlockSets, createPlayer, patchPlayer, putPlayer } from "redux/slices/playerSlice";
import { fetchCategories } from "redux/slices/categoriesSlice";
import { buildDeepObjectFromFlat, listToTree } from "helpers/utils";
import { playersFormInitialData } from "constants/form";

const { StringType, NumberType } = Schema.Types;
const model = Schema.Model({
  name: StringType().isRequired("Обязательное поле"),
  "data.x": NumberType("Только число"),
  "data.y": NumberType("Только число"),
  "data.width": NumberType("Только число").isRequired("Обязательное поле"),
  "data.height": NumberType("Только число").isRequired("Обязательное поле"),
});

const PlayerFormComponent = ({ reload, defaultValues = {} }) => {
  const formRef = useRef();
  const placeRef = useRef();
  const [tagSelected, setTagSelected] = useState([]);
  const [categorySelected, setCategorySelected] = useState({});
  const [formValue, setFormValue] = useState(playersFormInitialData);

  const dispatch = useDispatch();
  const { playersTags } = useSelector(state => state.tags);
  const categories = useSelector(state => state.categories);
  const treeCategories = useMemo(() => listToTree(cloneDeep(categories.data)), [categories.data]);

  // onPlaceSelect = place => {
  //   //console.log(place)
  //   const { location } = place.geometry
  //   if(!location)
  //     return

  //   this.onAddresSelect()
    

  //   reqAPI.outer_get('https://maps.googleapis.com/maps/api/timezone/json', {
  //     params: {
  //       location: `${location.lat()},${location.lng()}`,
  //       timestamp: (Math.round((new Date().getTime())/1000)).toString(),
  //       key: Config.GOOGLE_API_KEY
  //     }
  //   }).then(res => {
  //     this.setState({
  //       location: [location.lat(), location.lng()],
  //       timeZoneId: res.data.timeZoneId
  //     })
  //   })
  // }

  const handleSetAddress = async (place, a, c) => {
    const { location } = place.geometry
    if(!placeRef.current.value) return
    
    const p = new URLSearchParams({
      location: `${location.lat()},${location.lng()}`,
      timestamp: (Math.round((new Date().getTime()) / 1000)).toString(),
      key: process.env.REACT_APP_GOOGLE_API_KEY,
    });
    const response = await fetch(`https://maps.googleapis.com/maps/api/timezone/json?${p.toString()}`);
    const res = await response.json();

    setFormValue({
      ...formValue,
      "user_data.address" : placeRef.current.value,
      "user_data.coords.lat" : location.lat(),
      "user_data.coords.lng" : location.lng(),
      "user_data.coords.location" : [location.lat(), location.lng()],
      "timezone" : res.timeZoneId,
    })
  }

  const handleSubmit = async () => {
    if (!formRef.current.check()) {
      return;
    }
    formValue.category_id = categorySelected.value;
    formValue.tags = tagSelected;
    const { id, isClone, algorithm, ..._formValue } = formValue;
    const payload = buildDeepObjectFromFlat(_formValue, model);
    if (id && !isClone) {
      await putPlayer(id, payload);
    } else {
      const createdPlayer = await createPlayer(payload);
      if (algorithm === "each_n") {
        await patchPlayer(createdPlayer.id, { ad_campaign_algorithm: algorithm });
      } else {
        await createBlockSets({
          ad_blocks: [{ duration: 30, start: null }],
          description: "",
          name: `Рекламный блок устройства: ${createdPlayer.id}`,
          players: [createdPlayer.id]
        })
      }
      setFormValue(playersFormInitialData);
    }
    reload(prev => !prev);
  };

  useEffect(() => {
    dispatch(fetchCategories("?type=players"));
  }, []);

  useEffect(() => {
    if (defaultValues.id) {
      setFormValue(defaultValues);
      setTagSelected(defaultValues.tags);
      setCategorySelected({ value: defaultValues.category_id });
    } else {
      setFormValue(playersFormInitialData);
      // if (tagSelected.length > 0) setTagSelected([])
    }
  }, [defaultValues.id, defaultValues.isClone]);

  return (
    <Form
      model={model}
      ref={formRef}
      onChange={setFormValue}
      formValue={formValue}
      fluid
    >
      <HeaderComponent
        title={`${formValue.id ? (formValue.isClone ? "Клонировать" : "Изменить") : "Создать"} устройство`}
        tag="h3"
        gutter={0}
      />
      <InputFieldComponent name="name" label="Название*" />
      <InputFieldComponent name="description" label="Описание" />
      {/* <InputFieldComponent name="user_data.address" label="Адрес" /> */}
      <InputFieldComponent
        className="rs-form-control rs-form-control-wrapper"
        label="Адрес"
        help={formValue.timezone}
      >
        <PlacesAutocomplete
          ref={placeRef}
          className="rs-input"
          defaultValue={formValue["user_data.address"]}
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          types={["geocode"]}
          onPlaceSelected={handleSetAddress}
        />
      </InputFieldComponent>
      <Form.Group>
        <FlexboxGrid justify="space-between">
          <FlexboxGrid.Item colspan={11}>
            <InputFieldComponent name="user_data.coords.lat" label="Широта" accepter={InputNumber} help="Только цифры и точка" />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={11}>
            <InputFieldComponent name="user_data.coords.lng" label="Долгота" accepter={InputNumber} help="Только цифры и точка" />
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Form.Group>
      {(playersTags.length > 0) ? (
        <Form.Group>
          <Form.ControlLabel>Теги</Form.ControlLabel>
          <TagPicker
            data={playersTags.map(({ id, name }) => ({ label: name, value: id }))}
            onChange={setTagSelected}
            value={tagSelected}
            block
          />
        </Form.Group>
      ) : (
        <Message type="warning">Ни один тег устройства не создан</Message>
      )}
      {(treeCategories.length > 0) ? (
        <Form.Group>
          <Form.ControlLabel>Категория</Form.ControlLabel>
          <CheckTreePicker
            cascade={false}
            showIndentLine
            defaultExpandAll
            data={treeCategories}
            value={[categorySelected.value]}
            onSelect={(item) => setCategorySelected(item)}
            onClean={() => setCategorySelected({ value: 0 })}
            style={{ width: "100%" }}
          />
        </Form.Group>
      ) : (
        <Message type="warning">Не привязан к категории</Message>
      )}
      <Form.Group>
        <FlexboxGrid justify="space-between">
          <FlexboxGrid.Item colspan={11}>
            <InputFieldComponent name="data.x" label="X" accepter={InputNumber} />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={11}>
            <InputFieldComponent name="data.y" label="Y" accepter={InputNumber} />
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Form.Group>
      <Form.Group>
        <FlexboxGrid justify="space-between">
          <FlexboxGrid.Item colspan={11}>
            <InputFieldComponent name="data.width" label="Ширина*" accepter={InputNumber} />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={11}>
            <InputFieldComponent name="data.height" label="Высота*" accepter={InputNumber} />
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Form.Group>
      {!formValue.id && (
        <InputFieldComponent label="Алгоритм рекламного блока">
          <RadioGroup
            style={{ width: "100%" }}
            name="algorithm"
            inline
            appearance="picker"
            value={formValue.algorithm}
            onChange={(v) => setFormValue({ ...formValue, algorithm: v })}
          >
            <Radio value="each_n">Автоматический</Radio>
            <Radio value="ad_block">Фиксированный</Radio>
          </RadioGroup>
        </InputFieldComponent>
      )}
      <br />

      <Form.Group>
        <ButtonToolbar>
          <Button appearance="primary" onClick={handleSubmit}>Сохранить</Button>
        </ButtonToolbar>
      </Form.Group>
    </Form>
  );
};

export default PlayerFormComponent;
