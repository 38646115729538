import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";
import { content } from "constants/endpoints";

const fetchWidgets = createAsyncThunk("widgets", async (query = "") => {
  const response = await fetcher(`${content.WIDGETS}${query}`);
  return response.json();
});

const initialState = {
  objects: [],
  meta: {},
  status: loadingStatus.IDLE,
  error: null,
};

const widgetsSlice = createSlice({
  name: "widgets",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchWidgets.pending, (state, action) => {
        state.status = loadingStatus.LOADING;
      })
      .addCase(fetchWidgets.fulfilled, (state, action) => {
        state.status = loadingStatus.SUCCEEDED;
        state.objects = action.payload.objects;
        state.meta = action.payload.meta;
      })
      .addCase(fetchWidgets.rejected, (state, action) => {
        state.status = loadingStatus.FAILED;
        state.error = `${action.error.name}: ${action.error.message}`;
      })
  }
});

export default widgetsSlice.reducer;

export {
  fetchWidgets,
};
