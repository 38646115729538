import { Fragment } from "react";
import { useDispatch } from "react-redux";
import moment from "moment/moment";
import LoaderComponent from "components/LoaderComponent";
import ErrorComponent from "components/ErrorComponent";
import { loadingStatus } from "helpers/fetcher";
// import { playersHierarchyBuilder } from "helpers/player";
import { barChartColors } from "helpers/utils";
import { ruWeek, ruMonth, ruFullWeek } from "helpers/date";
import { fetchPlayerTimeline } from "redux/slices/playerSlice";

// TODO: работу с данными и рендер
const AnalyticsListComponent = ({
  items,
  filter,
  sort,
  status,
  error,
  onClick,
}) => {

  // console.log(filter, sort);

  // const [timeline, setTimeline] = useState({});
  const dispatch = useDispatch();
  // const { timeline } = useSelector(state => state.player);
  // const playersHierarchy = useMemo(() => {
  //   if (items.players) {
  //     return playersHierarchyBuilder(Object.values(items.players));
  //   }
  // }, [items.players]);

  const timelineIntervalsRepeater = (intervals) => {
    // если интервал всего 1, то это сутки
    if(!intervals || intervals.length === 1) return intervals
    const allIntervals = []
    intervals.map(interval => {
      const start = parseInt(interval.start || 0)
      const end = parseInt(interval.end || 23)
      // for(let i = start; i < end; i++) {
        allIntervals.push({
          ...interval,
          start: start < 10 ? `0${start}:00` : `${start}:00`,
          end: (end + 1) < 10 ? `0${end}:00` : `${end}:00`,
        })
      // }
    });
    return allIntervals
  };

  const handlePlayerTimeline = async (e, {player, address, player_id, data}, {name, value, week}) => {
    const intervalValues = data.find(d => d.name === name).intervalValues
    document.querySelectorAll(`div.week-td-filled`).forEach(el => {el.classList.remove('active')})
    e.currentTarget.classList.add('active')
    const s_date = name.split('.').reverse().join('-')
    // const result = await reqAPI.get(`players/${player_id}/timeline/?start=${s_date}&end=${s_date}`)
    const result = await dispatch(fetchPlayerTimeline({ id: player_id, q: `?start=${s_date}&end=${s_date}` }));

    onClick({
      // ...timeline,
      player: {
        player_id,
        player,
        address,
      },
      display: {
        date: name,
        value,
        week: ruFullWeek(week),
        intervalValues,
      },
      data: timelineIntervalsRepeater(result.payload[0].future)
    });
    // onClick();
  };
  

  ///////////////////////////// playersHierarchy = items.players
  const sortablePlayers = [];
  for (const playerId in items.players) {
    sortablePlayers.push(items.players[playerId])
  }

  // сортировка
  if (sort && sortablePlayers.length) {
    // сортируем
    switch (sort) {
      case "alphabet.ASC":
        sortablePlayers.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        });
        break;
      case "alphabet.DESC":
        sortablePlayers.sort((a, b) => {
          if (a.name > b.name) return -1;
          if (a.name < b.name) return 1;
          return 0;
        });
        break;
      case "date.ASC":
        sortablePlayers.sort((a, b) => {
          if ((new Date(a.created_at)).getTime() > (new Date(b.created_at)).getTime()) return 1;
          if ((new Date(a.created_at)).getTime() < (new Date(b.created_at)).getTime()) return -1;
          return 0;
        });
        break;
      case "date.DESC":
        sortablePlayers.sort((a, b) => {
          if ((new Date(a.created_at)).getTime() > (new Date(b.created_at)).getTime()) return -1;
          if ((new Date(a.created_at)).getTime() < (new Date(b.created_at)).getTime()) return 1;
          return 0;
        });
        break;
      default: break;
    }
  }

  const analytics_array = []
  if(sortablePlayers.length) {
    for(let p_index = 0; p_index < sortablePlayers.length; p_index++) {
      const day_per_player = items.days.map(item => {
        const date = item.day.split('-').reverse().join('.')
        const week = moment(item.day).isoWeekday()
        let totalSumm = 0
        const playerId = sortablePlayers[p_index].id;
        const intervalValues = item.intervals.filter(val => val.players[playerId])
        // intervalValues.map(interval => {
        //   const val = interval.players[p_index] || 0
        //   totalSumm += val
        //   return val
        // })
        intervalValues.map(interval => {
          const val = interval.players[playerId] || 0
          const start = parseInt(interval.start || 0)
          // если кампания на сутки end будет null
          const end = interval.end === null ? 24 : parseInt(interval.end || 23)
          for(let i = start; i < end; i++) {
            totalSumm += val
          }
          return val
        })

        // общая величина для столбика = сумма показателей всех часов / 24 часа
        const percent = Math.ceil(Math.ceil(totalSumm) / 24 * 100)

        return {
          name: date,
          week: week,
          ru_week: ruWeek(week),
          value: percent,
          intervalValues,
          color: barChartColors(percent)
        }
      })
      analytics_array.push({
        player: sortablePlayers[p_index].name,
        address: sortablePlayers[p_index].user_data.address,
        parent_id: sortablePlayers[p_index].devices[0].parent_id,
        player_id: sortablePlayers[p_index].id,
        data: day_per_player,
      })

    }
  }

  // фильтруем по плееру
  const player_index = analytics_array.findIndex(it => +it.player_id === +filter);
  console.log(player_index)
  const analytics_data = analytics_array.length && player_index !== -1
      ? analytics_array.filter((a, i) => i === player_index)
      : analytics_array

  if(!analytics_array.length) return "Нет данных для отчета";
  ////////////////////////////

  if (status !== loadingStatus.SUCCEEDED) {
    return <LoaderComponent />;
  }

  if (status === loadingStatus.FAILED) {
    return <ErrorComponent message={error} />;
  }

  return (
    analytics_data.map(player => {
      return (
        <div className="analytic-device-body" key={`player${player.player_id}`}>
          <div className="analytic-body-inner">
            <div className="analytic-player-name">
              {!player.parent_id ?
                player.player :
                `${analytics_array.find(a => a.player_id === player.parent_id)?.player} / Окно ${player.player.split("-")[2]}`}
            </div>
            {player.data.map((data, index) => {
              // const week_empty = index === 0 && data.week - 1 > 0
              const mon_begin = +data.name.split('.')[0] === 1
              const year = +data.name.split('.')[2]
              const mon_num = +data.name.split('.')[1]
              const day_num = data.name.split('.')[0]
              return (
                <Fragment key={`weektd${mon_num + day_num + index}`}>
                  {(mon_begin || index === 0) && (
                    <div className={`analytic-month-name day-${day_num} ${(index && index < 3) && +day_num === 1 && 'longer'}`}>
                      {ruMonth(mon_num)} {year}
                    </div>
                  )}
                  <div className={`week-td week-td-filled day-${day_num} ${(index && index < 3) && +day_num === 1 && 'longer'}`}
                    onClick={e => handlePlayerTimeline(e, player, data)}
                  >
                    <div className="week-td-week">{ruWeek(data.week)}</div>
                    <div className="week-td-day">{day_num}</div>
                    <div className="week-td-percent">
                      <span
                        style={{
                          backgroundColor: data.color,
                          height: data.value + 20,
                          color: data.value < 20 ? '#666' : '#fff'
                        }}
                      />
                    </div>
                    <div className="week-td-percent-val">{data.value}%</div>
                  </div>
                </Fragment>
              )
            })}
          </div>
        </div>
      );
    })
  );
  
  // return (
  //   <>
  //     {playersHierarchy.map(player => {
  //       return (
  //         <div key={player.id}>
  //           {player.name}
  //           {player.children ? (
  //             player.children.map(child => (
  //               <div key={child.id} style={{ marginLeft: 40 }}>
  //                 {child.name}
  //               </div>
  //           ))) : null}
  //         </div>
  //       );
  //     })}
  //   </>
  // );
};

export default AnalyticsListComponent;
