
const extractDeviceInfo = item => null;

const playersHierarchyBuilder = (players, children) => {
  const parents = players.filter(item => {
    if (item.player) return !item.player.devices[0].parent_id;
    return !item.devices[0].parent_id
  });
  const playersList = parents.map(parent => {
    const _children = (children || players).filter(item => {
      if (item.player) return item.player.devices[0].parent_id === parent.player.id;
      return item.devices[0].parent_id === parent.id;
    });
    return {
      ...parent,
      children: _children.length > 0 ? _children : null,
    };
  });
  return playersList;
};

const playerState = (device) => ({
  activated: device.activated,
  online: device.online,
  color: !device.activated ? "yellow" : (device.online ? "green" : "red"),
  pin: device.pin,
});

export {
  extractDeviceInfo,
  playersHierarchyBuilder,
  playerState,
};
