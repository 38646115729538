import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Form,
  Button,
  DateRangePicker,
  Steps,
  ButtonGroup,
  IconButton,
  SelectPicker,
  FlexboxGrid,
  CheckboxGroup,
  Checkbox,
  Whisper,
  Tooltip,
  Message,
  RadioGroup,
  Radio,
  InputNumber,
  CheckTreePicker,
} from "rsuite";
import PlayIcon from '@rsuite/icons/legacy/Play';
import QuestionIcon from '@rsuite/icons/legacy/Question2';
import { cloneDeep, uniq } from "lodash";

import HeaderComponent from "components/HeaderComponent";
import InputFieldComponent from "components/forms/InputFieldComponent";
import { FormErrorComponent } from "components/ErrorComponent";
import IntervalCarouselComponent from "./IntervalCarouselComponent";
import FilesComponent from "./FilesComponent";
import PreviewPlayersComponent from "./PreviewPlayersComponent";
import CampaignsTotalTableComponent from "./CampaignsTotalTableComponent";
import usePopup from "hooks/usePopup";
import { hoursRangeBuilder, itemsTotalDuration, calculateInterval, listToTree } from "helpers/utils";
import { formatDate } from "helpers/date";
import { campaignsFormInitialData } from "constants/form";
import {
  fetchCampaignPlayback,
  fetchCampaignPlayers,
  createCampaign,
  putCampaign,
} from "redux/slices/campaignSlice";
import { fetchFolders } from "redux/slices/filesSlice";
import { putBlockSets } from "redux/slices/playerSlice";
import { fetchCategories } from "redux/slices/categoriesSlice";

const CampaignsFormComponent = ({ reload, defaultValues = {} }) => {
  const formRef = useRef();
  const [formValue, setFormValue] = useState(campaignsFormInitialData);
  const [step, setStep] = useState(0);
  const [formErrors, setFormErrors] = useState({});
  const [categorySelected, setCategorySelected] = useState({});
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedPreviewPlayers, setSelectedPreviewPlayers] = useState([]);
  const dispatch = useDispatch();
  const { playbackItems, campaignPlayers } = useSelector(state => state.campaign);
  const { folders } = useSelector(state => state.files);
  const categories = useSelector(state => state.categories);
  const treeCategories = useMemo(() => listToTree(cloneDeep(categories.data)), [categories.data]);
  const { responseToaster } = usePopup();
  const totalDuration = useMemo(() => itemsTotalDuration(selectedFiles), [selectedFiles]);
  const typeHelpText = "В каждом блоке будет играть только один ролик из списка, по порядку " +
                      " - в первом блоке первый ролик, во втором второй и тд.";

  const validate = () => {
    const conditions = [
      {
        name: !formValue.name,
        advertiser: !formValue.advertiser,
        dateRange: !formValue.start || !formValue.end,
        interval: formValue.algorithm === "each_n" && !formValue.interval,
        ad_block_duration: formValue.algorithm === "ad_block" && !formValue.ad_block_duration,
      },
      { selectedFiles: selectedFiles.length === 0 },
      { selectedPreviewPlayers: selectedPreviewPlayers.length === 0 },
    ];

    const errors = {};
    for (const field of Object.keys(conditions[step])) {
      if (conditions[step][field]) errors[field] = true;
      else delete errors[field];
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const cleanForm = () => {
    setFormValue(campaignsFormInitialData);
    setStep(0);
    setFormErrors({});
    setSelectedFolder(null);
    setSelectedFiles([]);
    setSelectedPreviewPlayers([]);
  };

  const handleChangeStep = nextStep => {
    console.log(step, nextStep  )
    if (nextStep > step && !validate()) {
      return false;
    }
    setStep(nextStep < 0 ? 0 : (nextStep > 3 ? 3 : nextStep));
  };
  const onNext = () => handleChangeStep(step + 1);
  const onPrevious = () => handleChangeStep(step - 1);

  const handleChangeForm = (values) => {
    setFormValue(values);
  };

  const handleCheckbox = (_, checked, event) => {
    setFormValue({
      ...formValue,
      [event.target.name]: checked ? event.target.value : campaignsFormInitialData.type,
    });
  };

  const handleChangeDateRange = dates => {
    const [start, end] = [new Date(dates[0]), new Date(dates[1])];
    const values = {
      ...formValue,
      start: formatDate(start).full,
      end: formatDate(end).full,
    };
    setFormValue(values);
  };

  const handleChangeInterval = val => {
    setFormValue({ ...formValue, interval: val });
  };

  const handleSelectFolderVal = folder => {
    setFormValue({ ...formValue, advertiser: folder });
    setSelectedFolder(folder);
  };

  const handleSelectFiles = item => {
    setSelectedFiles([ ...selectedFiles, item ]);
  };

  const handleRemoveFile = index => {
    selectedFiles.splice(index, 1);
    setSelectedFiles([ ...selectedFiles ]);
  };

  const handleSelectedPreviewPlayers = items => {
    console.log(items)
    setSelectedPreviewPlayers(items);
  };
  
  const handleSubmit = async () => {
    formValue.category_id = categorySelected.value;

    const {
      id,
      isClone,
      interval,
      max_interval,
      ad_block_duration,
      ...rest
    } = formValue;

    const payload = {
      ...rest,
      playback_items: selectedFiles.map(({ id, data, type }) => ({
        content_items: [{
          content_item_data: type !== "video" ? { duration: data.default_duration} : {},
          id: id,
          playback_item_data: {}
        }],
        data: { orientation: data.orientation },
      })),
      players: selectedPreviewPlayers.map(id => ({
        id,
        skip_days: []
      })),
    };

    if (formValue.algorithm === "each_n") {
      payload.interval = calculateInterval(interval).interval;
      payload.max_interval = calculateInterval(interval).max_interval;
    } else {
      payload.ad_block_duration = ad_block_duration;

      // const _blockSetIds = selectedPreviewPlayers.map((playerId) => {
      //   const blockItem = previewPlayers.objects.find((item) => item.player.id === playerId);
      //   return blockItem.player.ad_block_set_id;
      // });
      // const blockSetIds = uniq(_blockSetIds);
      // console.log(blockSetIds)
      // return

      // blockSetIds.forEach((blockId) => {

      // });


      //   previewPlayers.objects.forEach(({ player }) => {
      //     await putBlockSets(blockSetId, {duration: ad_block_duration})
      //     player.ad_block_set_id
      // });

      // selectedPreviewPlayers.forEach(({ id }) => {
      // })
    }

    if (id && !isClone) {
      delete payload.advertiser;
      delete payload.algorithm;
      const res = await putCampaign(id, payload);
      responseToaster(res);
      if (res.ok) reload(prev => !prev);
      setStep(0);
    } else {
      const res = await createCampaign(payload);
      responseToaster(res);
      if (res.ok) {
        reload(prev => !prev);
        cleanForm();
      }
    }    
  };

  useEffect(() => {
    if (playbackItems.length > 0) {
      setSelectedFiles(playbackItems.map(it => it.content_items[0].content_item));
    }
  }, [playbackItems]);

  useEffect(() => {
    if (campaignPlayers.length > 0) {
      setSelectedPreviewPlayers(campaignPlayers.map(p => p.player.id));
    }
  }, [campaignPlayers]);

  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
      validate();
    }
  }, [formValue, selectedFiles, selectedPreviewPlayers]);

  useEffect(() => {
    dispatch(fetchCategories("?type=campaigns"));
  }, []);

  useEffect(() => {
    if (defaultValues.id) {
      setStep(0);
      setFormValue(defaultValues);
      dispatch(fetchCampaignPlayback({ id: defaultValues.id }));
      dispatch(fetchCampaignPlayers(defaultValues.id));
      setCategorySelected({ value: defaultValues.category_id });
      setSelectedFolder(defaultValues.advertiser);
    } else {
      cleanForm();
    }
    dispatch(fetchFolders("?limit=-1"));
  }, [defaultValues.id, defaultValues.isClone]);

  return (
    <div>
      <HeaderComponent
        title={`${formValue.id ? (formValue.isClone ? "Клонировать" : "Изменить") : "Создать"} кампанию`}
        tag="h4"
        gutter={0}
      />

      <Steps current={step} small={true}>
        <Steps.Item title="Данные" />
        <Steps.Item title="Файлы" />
        <Steps.Item title="Плеера" />
        <Steps.Item title="Ок" />
      </Steps>

      <SteppingButtons
        step={step}
        onPrevious={onPrevious}
        onNext={onNext}
        handleSubmit={handleSubmit}
      />
  
      <Form
        ref={formRef}
        onChange={handleChangeForm}
        formValue={formValue}
        fluid
      >
        {step === 0 && (
          <>
            <InputFieldComponent
              name="name"
              label="Название*"
              errorComponent={formErrors.name && <FormErrorComponent message="Напишите название кампании" />}
            />
            <InputFieldComponent name="description" label="Описание" />
            <InputFieldComponent
              label="Рекламодатель*"
              errorComponent={formErrors.advertiser && <FormErrorComponent message="Выберите рекламодателя" />}
            >
              {(folders.objects.length === 0) ? (
                <Message type="warning">Нет ни одной рекламодателя</Message>
              ) : (
                <SelectPicker
                  data={folders.objects.map(({ id, name }) => ({ label: name, value: id }))}
                  value={formValue.advertiser}
                  onChange={handleSelectFolderVal}
                  cleanable={false}
                  block
                />
              )}
            </InputFieldComponent>
            <InputFieldComponent
              label="Период трансляции кампании*"
              subComponent={
                <div>
                  <p className="mt-20">Временной диапазон</p>
                  <FlexboxGrid align="middle">
                    <FlexboxGrid.Item colspan={11}>
                      <SelectPicker
                        block
                        data={hoursRangeBuilder()}
                        searchable={false}
                        cleanable={false}
                        value={formValue.start_time}
                        onChange={val => handleChangeForm({ ...formValue, start_time: val })}
                      />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={2} className="text-center"> ― </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={11}>
                      <SelectPicker
                        block
                        data={hoursRangeBuilder(formValue.start_time)}
                        searchable={false}
                        cleanable={false}
                        value={formValue.end_time}
                        onChange={val => handleChangeForm({ ...formValue, end_time: val })}
                      />
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                </div>
              }
              errorComponent={formErrors.dateRange && <FormErrorComponent message="Выберите период трансляции" />}
            >
              <DateRangePicker
                block
                format="dd.MM.yyyy"
                placeholder="с даты - по дату"
                onChange={handleChangeDateRange}
                value={(formValue.start && formValue.end) && [new Date(formValue.start), new Date(formValue.end) ]}
                placement="bottomEnd"
              />
            </InputFieldComponent>
            {(treeCategories.length > 0) ? (
              <Form.Group>
                <Form.ControlLabel>Категория</Form.ControlLabel>
                <CheckTreePicker
                  cascade={false}
                  showIndentLine
                  defaultExpandAll
                  data={treeCategories}
                  value={[categorySelected.value]}
                  onSelect={(item) => setCategorySelected(item)}
                  onClean={() => setCategorySelected({ value: 0 })}
                  style={{ width: "100%" }}
                />
              </Form.Group>
            ) : (
              <Message type="warning">Не привязан к категории</Message>
            )}
            <InputFieldComponent label="Алгоритм рекламного блока">
              <RadioGroup
                style={{ width: "100%" }}
                name="algorithm"
                inline
                appearance="picker"
                value={formValue.algorithm}
                onChange={(v) => setFormValue({ ...formValue, algorithm: v })}
              >
                <Radio value="each_n">Автоматический</Radio>
                <Radio value="ad_block">Фиксированный</Radio>
              </RadioGroup>
            </InputFieldComponent>
            {formValue.algorithm === "each_n" ? (
              <InputFieldComponent
                label="Частота показов в час*"
                errorComponent={formErrors.interval && <FormErrorComponent message="Выберите частота показов" />}
              >
                <IntervalCarouselComponent interval={formValue.interval} onChange={handleChangeInterval} />
              </InputFieldComponent>
            ) : (
              <InputFieldComponent
                label="Продолжительность блока (сек)*"
                help="Допустимые значения: 30 - 86400 (сутки)"
                errorComponent={formErrors.ad_block_duration && <FormErrorComponent message="Укажите продолжительность блока" />}
              >
                <InputNumber
                  defaultValue={30}
                  min={30}
                  max={86400}
                  onChange={(v) => setFormValue({ ...formValue, ad_block_duration: v })}
                />
              </InputFieldComponent>
            )}
            {formValue.id ? (
              <Whisper
                placement="top"
                speaker={<Tooltip>{typeHelpText}</Tooltip>}
              >
                <span>
                  Режим конвейера <QuestionIcon color="#777" /><br />
                  <small>(невозможно изменить)</small>
                </span>
              </Whisper>
            ) : (
              <InputFieldComponent
                accepter={CheckboxGroup}
                tooltip={typeHelpText}
              >
                <Checkbox
                  name="type"
                  value="pipeline"
                  onChange={handleCheckbox}
                >
                  Режим конвейера
                </Checkbox>
              </InputFieldComponent>
            )}
          </>
        )}

        {step === 1 && (
          <FilesComponent
            selectedFiles={selectedFiles}
            onSelectFiles={handleSelectFiles}
            selectedFolder={selectedFolder}
            onRemoveFile={handleRemoveFile}
            error={formErrors.selectedFiles}
          />
        )}

        {step === 2 && (
          <PreviewPlayersComponent
            share={formValue.share}
            campaignPlayers={formValue.id ? campaignPlayers : []}
            selectedPreviewPlayers={selectedPreviewPlayers}
            onChangePreviewPlayers={handleSelectedPreviewPlayers}
            query={{
              algorithm: formValue.algorithm,
              start: formValue.start,
              end: formValue.end,
              duration: totalDuration,
              ...(formValue.algorithm === "each_n" && ({
                interval: calculateInterval(formValue.interval).interval,
                max_interval: calculateInterval(formValue.interval).max_interval,
              })),
            }}
            error={formErrors.selectedPreviewPlayers}
          />
        )}

        {step === 3 && (
          <CampaignsTotalTableComponent
            { ...formValue }
            filesLenght={selectedFiles.length}
            previewPlayersLenght={selectedPreviewPlayers.length}
            duration={totalDuration}
          />
        )}
      </Form>

      <SteppingButtons
        step={step}
        onPrevious={onPrevious}
        onNext={onNext}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

const SteppingButtons = ({
  step,
  onPrevious,
  onNext,
  handleSubmit,
}) => (
  <>
    <hr />
    <ButtonGroup>
      <Button
        appearance="subtle"
        onClick={onPrevious}
        size="sm"
        disabled={step === 0}
      >
        Назад
      </Button>
      {step === 3 ? (
        <Button
          appearance="primary"
          onClick={handleSubmit}
          size="sm"
        >
          Сохранить
        </Button>
      ) : (
        <IconButton
          appearance="link"
          onClick={onNext}
          icon={<PlayIcon />}
          placement="right"
           size="sm"
        >
          Далее
        </IconButton>
      )}
    </ButtonGroup>
    <hr />
  </>
);

export default CampaignsFormComponent;
