import { MdClose } from "react-icons/md";

const ButtonComponent = ({ children, onClick, size }) => {
  return (
    <div className={`item-card-button ${size}`}>
      <div className="item-card-button__create" onClick={onClick}>
        <span className="item-card-button__icon">
          <MdClose size="1.4em" className="rotate-45" />
        </span>
        {children}
      </div>
    </div>
  );
};

export default ButtonComponent;
