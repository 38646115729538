import { useState } from "react";

import FoldersListComponent from "components/files/FoldersListComponent";
import FilesListComponent from "components/files/FilesListComponent";
import ButtonCreateComponent from "components/buttons/ButtonCreateComponent";
import DrawerComponent from "components/DrawerComponent";

const Desktop = ({
  data: { folders, files },
  folderItem,
  sideBar,
  contentBar,
  foldersFilter,
  filesFilter,

  handleFolderDetailButton,
  handleFolderCreateButton,
  handleFolderEditButton,
  handleFolderDeleteButton,

  handleFileDetailButton,
  handleFilesCreateButton,
  handleFilesEditButton,
  handleFilesDeleteButton,
}) => {

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleOnFolderCreateDrawer = () => {
    setDrawerOpen(true);
    handleFolderCreateButton();
  };
  const handleOnFolderEditDrawer = item => {
    setDrawerOpen(true);
    handleFolderEditButton(item);
  };

  const handleOnFileDetailDrawer = (item) => {
    setDrawerOpen(true);
    handleFileDetailButton(item);
  };
  const handleOnFilesCreateDrawer = () => {
    setDrawerOpen(true);
    handleFilesCreateButton();
  };
  const handleOnFilesEditDrawer = item => {
    setDrawerOpen(true);
    handleFilesEditButton(item);
  };

  const handleOnCloseDrawer = () => setDrawerOpen(false);

  return (
    <>
      <div className="caps-list mb-20">
        <div className="item-list__item">
          <div className="item-list__item-content-icon mr-20">
            {foldersFilter}
          </div>
          <div className="item-list__item-content">
            <ButtonCreateComponent size="sm" onClick={handleOnFolderCreateDrawer}>
              Создать рекламодателя
            </ButtonCreateComponent>
          </div>
        </div>
        <FoldersListComponent
          items={folders.objects}
          status={folders.status}
          error={folders.error}
          onClick={handleFolderDetailButton}
          onEdit={handleOnFolderEditDrawer}
          onRemove={handleFolderDeleteButton}
        />
      </div>
      <div className="caps-list files-list">
        <div className="item-list__item">
          <div className="item-list__item-content-icon mr-20">
            {filesFilter}
          </div>
          <div className="item-list__item-content">
            <ButtonCreateComponent size="sm" onClick={handleOnFilesCreateDrawer}>
              Добавить файлы
            </ButtonCreateComponent>
          </div>
        </div>
        <FilesListComponent
          items={files.objects}
          folderItem={folderItem}
          status={files.status}
          error={files.error}
          onClick={handleOnFileDetailDrawer}
          onEdit={handleOnFilesEditDrawer}
          onRemove={handleFilesDeleteButton}
        />
      </div>
      {sideBar}
      <DrawerComponent
        open={drawerOpen}
        onClose={handleOnCloseDrawer}
        size="full"
        title="Детали записи"
      >
        {contentBar}
      </DrawerComponent>
    </>
  );
};

export default Desktop;
