import { useMemo } from "react";

import FilesListComponent from "components/files/FilesListComponent";
import ButtonCreateComponent from "components/buttons/ButtonCreateComponent";
import DesktopLayout from "components/layouts/DesktopLayout";

const Desktop = ({
  data: { folders, fillers },
  sideBar,
  contentBar,
  handleFileDetailButton,
  handleFilesCreateButton,
  handleFilesEditButton,
  handleFilesDeleteButton,
  onContentbarClose,
}) => {
  // Приводим к формату как в files
  const files = useMemo(() => fillers.objects.map(item => ({
    ...item.playback_item.content_items[0].content_item,
    id: item.id,
    tags: Object.values(item.policy_references.player_tag),
    fillerContentItemId: item.playback_item.content_items[0].content_item.id,
  })), [fillers.objects]);

  return (
    <DesktopLayout sideBar={sideBar} contentBar={contentBar} onContentbarClose={onContentbarClose}>
      <div className="caps-list files-list">
        <div className="item-list__item">
          <div className="item-list__item-content">
            <ButtonCreateComponent size="sm" onClick={handleFilesCreateButton}>
              Добавить файлы
            </ButtonCreateComponent>
          </div>
        </div>
        <FilesListComponent
          items={files}
          status={fillers.status}
          error={fillers.error}
          onClick={handleFileDetailButton}
          onEdit={handleFilesEditButton}
          onRemove={handleFilesDeleteButton}
        />
      </div>
    </DesktopLayout>

  );
};

export default Desktop;
