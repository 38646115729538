import SideNavComponent from "components/SideNavComponent";
import { sideNavPlaylists } from "helpers/navs";


const PlaylistsSideNavComponent = () => {
  return (
    <SideNavComponent
      sideNavItems={sideNavPlaylists}
    />
  );
};

export default PlaylistsSideNavComponent;
