import { Radio, RadioGroup } from "rsuite";

import { FILLERS_FILTERS, FILLERS_SORTS } from "constants/filters";

export const FilterFilesForm = ({ value, onChange }) => (
  <div className="caps-main">
    <h6 className="mb-20">Фильтры</h6>
    <RadioGroup
      name="filter-list"
      value={value}
      onChange={onChange}
    >
      {FILLERS_FILTERS.map(({ value, label }) => (
        <Radio key={`filter-${value}`} value={value}>
          {label}
        </Radio>
      ))}
    </RadioGroup>
  </div>
);

export const SortFilesForm = ({ value, onChange }) => (
  <div className="caps-main">
    <h6 className="mb-20">Сортировка</h6>
    <RadioGroup
      name="sort-list"
      value={value}
      onChange={onChange}
    >
      {Object.keys(FILLERS_SORTS).map(f => (
        <Radio key={`sort-${f}`} value={f}>
          {FILLERS_SORTS[f].label}
        </Radio>
      ))}
    </RadioGroup>
  </div>
);
