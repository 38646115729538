import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import HeaderComponent from "components/HeaderComponent";
import StatComponent from "components/StatComponent";
import { fetchPlaylists } from "redux/slices/statisticsSlice";
import { CAMPAIGNS_STATES } from "constants";

const PlaylistsOverviewComponent = ({ className }) => {
  const dispatch = useDispatch();
  const { playlists } = useSelector((state) => state.statistics);

  const statData = [
    {
      percent: playlists.length,
      strokeColor: "#7367F0",
      label: "Всего плейлистов",
    },
    {
      percent: playlists.filter(c => c.trigger.state === CAMPAIGNS_STATES.running.name).length,
      strokeColor: CAMPAIGNS_STATES.running.color,
      label: CAMPAIGNS_STATES.running.label,
    },
    {
      percent: playlists.filter(c => c.trigger.state === CAMPAIGNS_STATES.finished.name).length,
      strokeColor: CAMPAIGNS_STATES.finished.color,
      label: CAMPAIGNS_STATES.finished.label,
    },
    {
      percent: playlists.filter(c => c.trigger.state === CAMPAIGNS_STATES.pending.name).length,
      strokeColor: CAMPAIGNS_STATES.pending.color,
      label: CAMPAIGNS_STATES.pending.label,
    },
  ];

  useEffect(() => {
    dispatch(fetchPlaylists());
  }, []);

  return (
    <div className={`${className}`}>
      <HeaderComponent title="Статистика плейлистов" tag="h3" gutter={0} />
      <StatComponent data={statData} />
    </div>
  );
};

export default PlaylistsOverviewComponent;
