import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DetailHeaderComponent from "components/DetailHeaderComponent";
import PlaylistsInfoComponent from "./PlaylistsInfoComponent";
import PlaylistsSettingComponent from "./PlaylistsSettingComponent";
import PlaylistsFilesComponent from "./PlaylistsFilesComponent";
import PlaylistsPlayersComponent from "./PlaylistsPlayersComponent";
import { fetchPlaylist, fetchPlaylistPlayback, fetchPlaylistPlayers } from "redux/slices/playlistSlice";

const playlistsDetailTabs = {
  INFO: { title: "Инфо", key: "info" },
  SETTINGS: { title: "Настройки", key: "settings" },
  FILES: { title: "Файлы", key: "files" },
  PLAYERS: { title: "Устройства", key: "players" },
};

const PlaylistsDetailComponent = ({ reload, onEdit, onClone, onDelete, onClose }) => {
  const [active, setActive] = useState(playlistsDetailTabs.INFO.key);
  const { "*": path } = useParams();
  const dispatch = useDispatch();
  const { data: playlist } = useSelector(state => state.playlist);

  const switchActiveTabComponent = (key) => {
    const cases = {
      [playlistsDetailTabs.INFO.key]: <PlaylistsInfoComponent />,
      [playlistsDetailTabs.SETTINGS.key]: <PlaylistsSettingComponent reload={reload} onEdit={onEdit} onClone={onClone} onDelete={onDelete} />,
      [playlistsDetailTabs.FILES.key]: <PlaylistsFilesComponent />,
      [playlistsDetailTabs.PLAYERS.key]: <PlaylistsPlayersComponent />,
    };
    return cases[key];
  };

  const selectDetailTabHandler = (key) => {
    setActive(key);
  };

  useEffect(() => {
    const [location, id] = path.split("/");
    if (location === "detail") dispatch(fetchPlaylist(id));
  }, [dispatch, path, active]);

  useEffect(() => {
    if (playlist.id) {
      dispatch(fetchPlaylistPlayback({ id: playlist.id }));
      dispatch(fetchPlaylistPlayers(playlist.id));
    }
  }, [dispatch, playlist.id]);

  return (
    <DetailHeaderComponent
      tabs={playlistsDetailTabs}
      tabComponent={switchActiveTabComponent(active)}
      active={active}
      onSelect={selectDetailTabHandler}
      onClose={onClose}
    />
  );
};

export default PlaylistsDetailComponent;
