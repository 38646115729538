import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DetailHeaderComponent from "components/DetailHeaderComponent";
import CampaignsInfoComponent from "./CampaignsInfoComponent";
import CampaignsSettingComponent from "./CampaignsSettingComponent";
import CampaignsFilesComponent from "./CampaignsFilesComponent";
import CampaignsPlayersComponent from "./CampaignsPlayersComponent";
import { fetchCampaign, fetchCampaignPlayback, fetchCampaignPlayers } from "redux/slices/campaignSlice";

const campaignsDetailTabs = {
  INFO: { title: "Инфо", key: "info" },
  SETTINGS: { title: "Настройки", key: "settings" },
  FILES: { title: "Файлы", key: "files" },
  PLAYERS: { title: "Устройства", key: "players" },
};

const CampaignsDetailComponent = ({ reload, onEdit, onClone, onDelete, onClose }) => {
  const [active, setActive] = useState(campaignsDetailTabs.INFO.key);
  const { "*": path } = useParams();
  const dispatch = useDispatch();
  const { data: campaign } = useSelector(state => state.campaign);

  const switchActiveTabComponent = (key) => {
    const cases = {
      [campaignsDetailTabs.INFO.key]: <CampaignsInfoComponent />,
      [campaignsDetailTabs.SETTINGS.key]: <CampaignsSettingComponent reload={reload} onEdit={onEdit} onClone={onClone} onDelete={onDelete} />,
      [campaignsDetailTabs.FILES.key]: <CampaignsFilesComponent />,
      [campaignsDetailTabs.PLAYERS.key]: <CampaignsPlayersComponent />,
    };
    return cases[key];
  };

  const selectDetailTabHandler = (key) => {
    setActive(key);
  };

  useEffect(() => {
    const [location, id] = path.split("/");
    if (location === "detail") dispatch(fetchCampaign(id));
  }, [dispatch, path, active]);

  useEffect(() => {
    if (campaign.id) {
      dispatch(fetchCampaignPlayback({ id: campaign.id }));
      dispatch(fetchCampaignPlayers(campaign.id));
    }
  }, [dispatch, campaign.id]);

  return (
    <DetailHeaderComponent
      tabs={campaignsDetailTabs}
      tabComponent={switchActiveTabComponent(active)}
      active={active}
      onSelect={selectDetailTabHandler}
      onClose={onClose}
    />
  );
};

export default CampaignsDetailComponent;
