import { useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Nav,
  InputGroup,
  Input,
  FlexboxGrid,
  Message,
  Tag,
} from "rsuite";
import { MdAdd } from "react-icons/md";

import {
  createPlayersTag,
  deletePlayersTag,
  createContentTag,
  deleteContentTag,
} from "redux/slices/tagsSlice";

const TagsFormComponent = () => {
  const dispatch = useDispatch();
  const { playersTags, contentTags } = useSelector(state => state.tags);
  const tabs = useMemo(() => [
    {
      key: "players",
      title: "Теги устройств",
      create: createPlayersTag,
      delete: deletePlayersTag,
      content: playersTags,
    },
    {
      key: "content",
      title: "Теги контента",
      create: createContentTag,
      delete: deleteContentTag,
      content: contentTags,
    },
  ], [contentTags, playersTags]);
  const [inputValue, setInputValue] = useState("");
  const [activeKey, setActiveKey] = useState(tabs[0].key);

  const handleSubmit = () => {
    if (!inputValue) {
      return;
    }
    const createDispatch = tabs.find(tab => tab.key === activeKey).create;
    dispatch(createDispatch({ name: inputValue }));
    setInputValue("");
  };

  const handleDelete = (tagId) => {
    const deleteDispatch = tabs.find(tab => tab.key === activeKey).delete;
    dispatch(deleteDispatch(tagId));
  };

  const tags = useMemo(() => tabs.find(tab => tab.key === activeKey).content, [activeKey, tabs]);

  return (
    <div>
      <Nav
        className="mb-20"
        appearance="subtle"
        activeKey={activeKey}
        onSelect={setActiveKey}
      >
        {tabs.map(({ key, title }) => (
          <Nav.Item
            eventKey={key}
            key={key}
          >
            {title}
          </Nav.Item>
        ))}
      </Nav>

      <InputGroup>
        <Input
          placeholder="Создать новый тег"
          value={inputValue}
          onChange={setInputValue}
        />
        <InputGroup.Button onClick={handleSubmit}>
          <MdAdd size="1.2em" />
        </InputGroup.Button>
      </InputGroup>
      <hr/>

      <FlexboxGrid justify="start" className="mt-20">
        {(tags.length > 0) ? (
          tags.map(({ id, name }) => (
            <Tag
              className="mb-20"
              key={id}
              size="lg"
              color="blue"
              closable
              onClose={() => handleDelete(id)}
            >
              {name}
            </Tag>
          ))
        ) : (
          <Message type="warning">Ни один тег не создан</Message>
        )}
      </FlexboxGrid>
      
    </div>
  );
};

export default TagsFormComponent;
