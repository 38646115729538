import CampaignsGridComponent from "components/campaign/CampaignsGridComponent";
import ButtonCreateComponent from "components/buttons/ButtonCreateComponent";
import DesktopLayout from "components/layouts/DesktopLayout";

const Desktop = ({
  data: { objects, meta, status, error },
  sideBar,
  contentBar,
  handleCreateButton,
  handleDetailButton,
  handleEditButton,
  handleCloneButton,
  handleDeleteButton,
  onContentbarClose,
}) => {

  return (
    <DesktopLayout sideBar={sideBar} contentBar={contentBar} onContentbarClose={onContentbarClose}>
      <CampaignsGridComponent
        buttonCreate={
          <ButtonCreateComponent onClick={handleCreateButton}>
            Создать кампанию
          </ButtonCreateComponent>
        }
        items={objects}
        status={status}
        error={error}
        onClick={handleDetailButton}
        onEdit={handleEditButton}
        onClone={handleCloneButton}
        onDelete={handleDeleteButton}
      />
    </DesktopLayout>
  );
};

export default Desktop;
