import { useState } from "react";
import { Col, Tag, FlexboxGrid, Modal } from "rsuite";

import LoaderComponent from "components/LoaderComponent";
import ErrorComponent from "components/ErrorComponent";
import CardComponent from "components/CardComponent";
import ActivatePinComponent from "./ActivatePinComponent";
import useLayout from "hooks/useLayout";
import { loadingStatus } from "helpers/fetcher";
import { formatDate } from "helpers/date";
import { playerState } from "helpers/player";

const PlayersGridComponent = ({
  buttonCreate,
  items,
  status,
  error,
  onClick,
  onEdit,
  onClone,
  onDelete,
}) => {
  const [preview, setPreview] = useState({ open: false, path: "", type: "" });

  const { gridBreakPoints } = useLayout();

  const menuItems = [
    { key: "edit", label: "Редактировать", action: item => onEdit(item) },
    { key: "clone", label: "Клонировать", action: item => onClone(item) },
    { key: "delete", label: "Удалить", action: item => onDelete(item.id) },
  ];

  if (status !== loadingStatus.SUCCEEDED) {
    return <LoaderComponent />;
  }

  if (status === loadingStatus.FAILED) {
    return <ErrorComponent message={error} />;
  }

  return (
    <>
      <FlexboxGrid className="grid-items" align="top">
        {buttonCreate && (
          <FlexboxGrid.Item as={Col} {...gridBreakPoints}>
            {buttonCreate}
          </FlexboxGrid.Item>
        )}
        {items.map(item => {
          const state = playerState(item.devices[0]);
          const parentId = item.devices[0].parent_id;
          const lat = item.user_data ? (item.user_data.coords.lat || item.user_data.location?.[0]) : 0
          const lng = item.user_data ? (item.user_data.coords.lng || item.user_data.location?.[1]) : 0
          const ll = `${lng},${lat}`;

          const thumbFiles = [];
          thumbFiles.push({
            path: (lng && lat) ? (
              `https://static-maps.yandex.ru/1.x/?ll=${ll}&pt=${ll},pm2lbm&size=595,335&z=14&l=map`
            ) : (
              "https://storage.yandexcloud.net/content-bucket/location-not-found.png"
            ),
            preview: (lng && lat) ? (
              `https://static-maps.yandex.ru/1.x/?ll=${ll}&pt=${ll},pm2lbm&size=150,150&z=14&l=map`
            ) : (
              "https://storage.yandexcloud.net/content-bucket/location-not-found.png"
            ),
            type: 'image',
          });
          // thumbFiles.push({
          //   path: "https://img.freepik.com/free-vector/night-ocean-landscape-full-moon-and-stars-shine_107791-7397.jpg",
          //   preview: "https://img.freepik.com/free-vector/night-ocean-landscape-full-moon-and-stars-shine_107791-7397.jpg",
          //   type: 'image',
          // });

          return (
            <FlexboxGrid.Item as={Col} {...gridBreakPoints} key={`${item.id}`}>
              {!parentId ? (
                <CardComponent
                  title={item.name}
                  thumbFiles={thumbFiles}
                  onPreview={setPreview}
                  unit={item.user_data.address}
                  date={state.activated ?
                    <span className={state.color}>{formatDate(item.created_at, ".").full_datetime}</span> :
                    <ActivatePinComponent pin={state.pin} />
                  }
                  subTitle={item.timezone}
                  badgeColor={state.color}
                  onClick={() => onClick(item.id)}
                  menuItems={menuItems}
                  menuItemSelect={key => menuItems.find(it => it.key === key).action(item)}
                />
              ) : (
                <CardComponent
                  className="ghost-card"
                  
                  title={
                    <Tag>
                      {`${items.find(it => it.id === parentId)?.name} / Окно ${item.name.split("-")[2]}`}
                    </Tag>
                  }
                  unit="--"
                  date={formatDate(item.created_at, ".").full_datetime}
                  onClick={() => onClick(item.id)}
                />
              )}
            </FlexboxGrid.Item>
          );
        })}
      </FlexboxGrid>
      <Modal size="lg" open={preview.open} onClose={() => setPreview({ open: false, path: "", type: "" })}>
        <Modal.Body>
          {preview.type === "video"
            ? <video width="100%" controls><source src={preview.path} type="video/mp4"/></video>
            : <img className="flex-image" src={preview.path} alt="" />
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PlayersGridComponent;
