import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher from "helpers/fetcher";
import { campaigns, players, playlists } from "constants/endpoints";

const fetchCampaigns = createAsyncThunk("statistics/campaigns", async (query = "?limit=-1") => {
  const response = await fetcher(`${campaigns.ALL}${query}`);
  return response.json();
});

const fetchPlaylists = createAsyncThunk("statistics/playlists", async (query = "?limit=-1") => {
  const response = await fetcher(`${playlists.ALL}${query}`);
  return response.json();
});

const fetchPlayers = createAsyncThunk("statistics/players", async (query = "?hide_children=1&limit=-1") => {
  const response = await fetcher(`${players.ALL}${query}`);
  return response.json();
});

const initialState = {
  campaigns: [],
  playlists: [],
  players: [],
};

const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCampaigns.fulfilled, (state, action) => {
        state.campaigns = action.payload.objects;
      })
      .addCase(fetchPlaylists.fulfilled, (state, action) => {
        state.playlists = action.payload.objects;
      })
      .addCase(fetchPlayers.fulfilled, (state, action) => {
        state.players = action.payload.objects;
      })
  }
});

export default statisticsSlice.reducer;

export {
  fetchCampaigns,
  fetchPlaylists,
  fetchPlayers,
};
