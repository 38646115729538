import { Message, Tooltip } from "rsuite";

const ErrorComponent = ({ header, message, children }) => (
  <Message showIcon type="error" header={header}>
    {children || message}
  </Message>
);


const FormErrorComponent = ({ message, children }) => {
  return (
    <Message showIcon type="error" className="sm">
      {children || message}
    </Message>
  );
};

// const FormErrorComponent = ({ children }) => {
//   return (
//     <div style={{ marginTop: -24 }}>
//       <Tooltip placement="topStart" visible>
//         {children}
//       </Tooltip>
//     </div>
//   );
// };


export default ErrorComponent;

export {
  FormErrorComponent,
};
