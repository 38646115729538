import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Nav,
  Navbar,
} from "rsuite";
import { MdOutlineNotificationsNone } from "react-icons/md";

import useUser from "hooks/useUser";
import { settings } from "constants/urls";

const MenuItemsComponent = ({
  isDesktop,
  headerNav,
  activeKey,
  syncNotifications,
  robotNotifications,
  headerIconSize,
  onNavigation,
}) => {
  const navigate  = useNavigate();
  const { user, removeUser } = useUser();

  const handleLogout = () => {
    removeUser();
    window.location.reload(false);
  };
  const handleProfile = () => {
    navigate(settings.PROFILE);
  };
  const handlePassword = () => {
    navigate(settings.PASSWORD);
  };

  return (
    <Navbar>
      {isDesktop && (
      <Nav>
        {headerNav.left.map(({ title, action, icon, id }) => (
          <Nav.Item
            key={id}
            icon={icon}
            active={action === activeKey}
            onClick={() => onNavigation(action)}
          >
            {title}
          </Nav.Item>
        ))}
      </Nav>
      )}
      <Nav pullRight>
        {headerNav.right
          .filter(h => !(h.id === "header-sync-notifications" && syncNotifications.length === 0))
          .map(({ action, icon, id }) => (
            <Nav.Item
              key={id}
              icon={
                (id === "header-robot-notifications" && robotNotifications.length > 0) ? (
                  <span>
                    <div className="ring-animations-infinite">
                      <MdOutlineNotificationsNone size={headerIconSize} />
                    </div>
                  </span>
                ) : (
                  icon
                )
              }
              onClick={() => onNavigation(action)}
            />
          )
        )}
        <Nav.Menu
          noCaret={!isDesktop}
          placement="bottomEnd"
          title={<Avatar circle src={user.profile.profile.avatar} alt="me" />}
        >
          <Nav.Item
            className="navbar-profile"
            onClick={handleProfile}
          >
            <p>{`${user.profile.profile.first_name} ${user.profile.profile.last_name}`}</p>
            <small>{user.profile.email}</small>
          </Nav.Item>
          <Nav.Item divider />
          <Nav.Item onClick={handlePassword}>Безопасность</Nav.Item>
          <Nav.Item onClick={handleProfile}>Профиль</Nav.Item>
          <Nav.Item onClick={handleLogout}>
            <span className="red">Выйти</span>
          </Nav.Item>
        </Nav.Menu>
      </Nav>
    </Navbar>
  );
};

export default MenuItemsComponent;
