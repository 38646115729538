import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Content,
  Form,
  ButtonToolbar,
  Button,
  Panel,
  FlexboxGrid,
  Schema,
  Message,
} from "rsuite";

import ErrorComponent from "components/ErrorComponent";
import InputFieldComponent from "components/forms/InputFieldComponent";
import useUser from "hooks/useUser";
import { postForgot } from "redux/slices/loginSlice";
import { loadingStatus } from "helpers/fetcher";
import { auth } from "constants/urls";

const { StringType } = Schema.Types;
const model = Schema.Model({
  email: StringType()
    .isEmail("Введите валидный e-mail")
    .isRequired("Обязательное поле"),
});

const Forgot = () => {
  const formRef = useRef();
  const [formValue, setFormValue] = useState({ email: "" });
  const [success, setSuccess] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { forgot: { status, error } } = useSelector(state => state.login);
  const { accessToken } = useUser();

  const handleGoToLogin = useCallback(() => navigate(auth.LOGIN), [navigate]);

  const handleSubmit = () => {
    if (!formRef.current.check()) {
      return;
    }
    dispatch(postForgot(formValue));
  };

  useEffect(() => {
    if (status === loadingStatus.SUCCEEDED) {
      setSuccess("Инструкция по восстановлению отправлена на почту");
    }
  }, [status, handleGoToLogin]);

  useEffect(() => {
    if (accessToken) navigate("/");
  }, [navigate, accessToken]);
  
  return (
    <Container>
      <Content>
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item colspan={12}>
            <Panel header={<h3>Восстановить пароль</h3>} bordered>
              <Message type="info">Инструкция по восстановлению пароля будет отправлена на почту</Message>
              <br/>
              <Form
                model={model}
                ref={formRef}
                onChange={setFormValue}
                formValue={formValue}
                fluid
              >
                <InputFieldComponent name="email" label="Email" />
                <Form.Group>
                  <ButtonToolbar>
                    <Button appearance="link" onClick={handleGoToLogin}>Войти в систему</Button>
                    <Button appearance="primary" onClick={handleSubmit}>Отправить инструкцию</Button>
                  </ButtonToolbar>
                </Form.Group>
                {success && (
                  <Form.Group>
                    <Message showIcon type="success" closable={true}>{success}</Message>
                  </Form.Group>
                )}
                {error && <ErrorComponent message={error} />}
              </Form>
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Content>
    </Container>
  );
};

export default Forgot;
