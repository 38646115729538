import ButtonCreateComponent from "components/buttons/ButtonCreateComponent";
import RebootsGridComponent from "components/player/reboots/RebootsGridComponent";
import DesktopLayout from "components/layouts/DesktopLayout";

const Desktop = ({
  data: { objects, meta, status, error },
  sideBar,
  contentBar,
  handleFormButton,
  onContentbarClose,
}) => {

  return (
    <DesktopLayout sideBar={sideBar} contentBar={contentBar} onContentbarClose={onContentbarClose}>
      <RebootsGridComponent
        buttonCreate={
          <ButtonCreateComponent onClick={handleFormButton}>
            Создать расписание
          </ButtonCreateComponent>
        }
        items={objects}
        status={status}
        error={error}
        onForm={handleFormButton}
      />
    </DesktopLayout>
  );
};

export default Desktop;
