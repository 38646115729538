import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { IconButton, ButtonToolbar, RadioGroup, Radio, Tag } from "rsuite";
import { MdFilterAlt, MdIosShare, MdExpandMore, MdExpandLess } from "react-icons/md";

import DrawerComponent from "components/DrawerComponent";
import HeaderComponent from "components/HeaderComponent";
import PlayersSideNavComponent from "components/player/PlayersSideNavComponent";
import PlayerFormComponent from "components/player/PlayerFormComponent";
import PlayerDetailComponent from "components/player/PlayerDetailComponent";
import PlayerExportComponent from "components/player/PlayerExportComponent";
import CategoriesComponent from "components/CategoriesComponent";

import { fetchPlayers } from "redux/slices/playersSlice";
import { deletePlayer } from "redux/slices/playerSlice";
import { fetchCategories } from "redux/slices/categoriesSlice";
import { playersCheckTreeToggle } from "redux/slices/layoutSlice";
import useLayout from "hooks/useLayout";
import useFilter from "hooks/useFilter";
import usePopup from "hooks/usePopup";
import { buildFlatFromDeepObject } from "helpers/utils";
import Mobile from "./Mobile";
import Desktop from "./Desktop";
import { playersFormInitialData } from "constants/form";
import { DRAWERS, contentBarContents, PAGE_FILTERS, SORTS } from "./constants";

const Players = () => {
  const [currentContent, setCurrentContent] = useState(contentBarContents.PLAYERS_OVERVIEW);
  const [selectedContentItem, setSelectedContentItem] = useState();
  const [drawerType, setDrawerType] = useState();
  const [reload, setReload] = useState();
  const [extraQueryString, setExtraQueryString] = useState("hide_children=1");

  const navigate  = useNavigate();
  const { "*": relativePath } = useParams();
  const dispatch = useDispatch();
  const data = useSelector(state => state.players);
  const { searchState, playersCheckTreeState } = useSelector(state => state.layout);
  const {
    filterKey,
    sortKey,
    setFilterKey,
    setSortKey,
    setAdditionalParam,
    queryString,
  } = useFilter({
    filters: PAGE_FILTERS,
    sorts: SORTS,
    externalParams: ["name"],
  });
  const { isDesktop } = useLayout();
  const { responseToaster } = usePopup();

  const SideBar = useCallback(() => (
    <PlayersSideNavComponent
      handleExportAction={handleExportDrawerOpen}
    />
  ), []);

  const handleDetailButton = (id) => {
    setCurrentContent(contentBarContents.PLAYER_DETAIL);
    navigate(`/players/detail/${id}`);
  };

  const handleCreateButton = () => {
    setCurrentContent(contentBarContents.PLAYERS_FORM);
    setSelectedContentItem();
    navigate("/players/form");
  };

  const handleEditButton = item => {
    setCurrentContent(contentBarContents.PLAYERS_FORM);
    const {
      id, name, description, data, tags, user_data, devices,
    } = item;
    const payload = {
      id,
      name,
      description,
      data: { x: data.x, y: data.y, width: data.width, height: data.height },
      tags: tags.map(t => t.id),
      user_data,
      category_id: devices[0].category_id,
    };
    setSelectedContentItem(buildFlatFromDeepObject(payload));
    navigate(`/players/form/${id}`);

  };

  const handleCloneButton = item => {
    setCurrentContent(contentBarContents.PLAYERS_FORM);
    const {
      id, name, description, data, tags, user_data,
    } = item;
    const payload = {
      ...playersFormInitialData,
      isClone: true,
      id,
      name,
      description,
      data: { x: data.x, y: data.y, width: data.width, height: data.height },
      tags: tags.map(t => t.id),
      user_data,
    };
    setSelectedContentItem(buildFlatFromDeepObject(payload));
    navigate(`/players/clone/${id}`);
  };

  const handleDeleteButton = async id => {
    const res = await deletePlayer(id);
    setReload(prev => !prev);
    responseToaster(res);
  };

  const handleDrawerClose = () => setDrawerType();
  const handleExportDrawerOpen = () => setDrawerType(DRAWERS.export.key);

  const contentComponents = {
    [contentBarContents.PLAYERS_OVERVIEW]: null,
    [contentBarContents.PLAYERS_FORM]: (
      <PlayerFormComponent
        reload={setReload}
        defaultValues={selectedContentItem}
        // onClose={() => setCurrentContent(contentBarContents.PLAYERS_OVERVIEW)}
      />
    ),
    [contentBarContents.PLAYER_DETAIL]: (
      <PlayerDetailComponent
        reload={setReload}
        onEdit={handleEditButton}
        onClone={handleCloneButton}
        onDelete={handleDeleteButton}
        onClose={() => setCurrentContent(contentBarContents.PLAYERS_OVERVIEW)}
      />
    ),
  };

  useEffect(() => setAdditionalParam("name", searchState), [searchState]);

  useEffect(() => {
    const timer = setTimeout(() => {    
      navigate(queryString);
      // const search = new URLSearchParams(queryString);
      // search.set("hide_children", 1);
      // dispatch(fetchPlayers(`?${search.toString()}`));
      const qs = `${queryString}${queryString ? `&${extraQueryString}` : `?${extraQueryString}`}`;
      console.log(qs);
      dispatch(fetchPlayers(qs));
    }, 10);
    return () => clearTimeout(timer);
  }, [queryString, extraQueryString, reload]);

  useEffect(() => {
    if (!relativePath) {
      setCurrentContent(contentBarContents.PLAYERS_OVERVIEW);
    }
  }, [relativePath]);

  useEffect(() => {
    dispatch(fetchCategories("?type=players"));
  }, []);

  const actions = {
    handleCreateButton,
    handleDetailButton,
    handleEditButton,
    handleCloneButton,
    handleDeleteButton,
    onContentbarClose: () => setCurrentContent(null),
  };

  return (
    <>
      <HeaderComponent
        title={
          <>
            {isDesktop ? "Устройства" : <div>Устройства</div>}
            {" "}
            <Tag size="sm">{PAGE_FILTERS[filterKey].label}</Tag>
          </>
        }
        right={
          <ButtonToolbar>
            <IconButton
              icon={playersCheckTreeState ? <MdExpandLess size="1.7em" /> : <MdExpandMore size="1.7em" />}
              appearance="link"
              onClick={() => dispatch(playersCheckTreeToggle())}
            />
            <IconButton
              icon={<MdFilterAlt size="1.5em" />}
              appearance="link"
              onClick={() => setDrawerType(DRAWERS.filters.key)}
            />
            <IconButton
              icon={<MdIosShare size="1.5em" />}
              appearance="link"
              onClick={handleExportDrawerOpen}
            />
          </ButtonToolbar>
        }
      />
      {playersCheckTreeState ? (
        <CategoriesComponent
          onQueryString={setExtraQueryString}
          categoryType="players"
        />
      ) : (
        ""
      )}
      {isDesktop ? (
        <Desktop
          data={data}
          sideBar={<SideBar />}
          contentBar={contentComponents[currentContent]}
          {...actions}
        />
      ) : (
        <Mobile
          data={data}
          sideBar={<SideBar />}
          contentBar={contentComponents[currentContent]}
          {...actions}
        />
      )}
      <DrawerComponent
        open={!!(drawerType && DRAWERS[drawerType].key)}
        onClose={handleDrawerClose}
        placement={"right"}
        size={drawerType && DRAWERS[drawerType].width}
      >
        {drawerType === DRAWERS.filters.key && (
          <>
            <FilterForm value={filterKey} onChange={setFilterKey} />
            <SortForm value={sortKey} onChange={setSortKey} />
          </>
        )}
        {drawerType === DRAWERS.export.key && <PlayerExportComponent />}
      </DrawerComponent>
    </>
  );

};

const FilterForm = ({ value, onChange }) => (
  <div className="caps-main">
    <h6 className="mb-20">Фильтры</h6>
    <RadioGroup
      name="filter-list"
      value={value}
      onChange={onChange}
    >
      {Object.keys(PAGE_FILTERS).map(f => (
        <Radio key={`filter-${f}`} value={f}>
          {PAGE_FILTERS[f].label}
        </Radio>
      ))}
    </RadioGroup>
  </div>
);

const SortForm = ({ value, onChange }) => (
  <div className="caps-main">
    <h6 className="mb-20">Сортировка</h6>
    <RadioGroup
      name="sort-list"
      value={value}
      onChange={onChange}
    >
      {Object.keys(SORTS).map(f => (
        <Radio key={`sort-${f}`} value={f}>
          {SORTS[f].label}
        </Radio>
      ))}
    </RadioGroup>
  </div>
);

export default Players;
