import { Col, Tag, FlexboxGrid } from "rsuite";
import { useDispatch } from "react-redux";

import { fillForm, fetchReboots, deleteReboots } from "redux/slices/rebootsSlice";
import LoaderComponent from "components/LoaderComponent";
import ErrorComponent from "components/ErrorComponent";
import CardComponent from "components/CardComponent";
import useLayout from "hooks/useLayout";
import { loadingStatus } from "helpers/fetcher";
import { MdTimer } from "react-icons/md";

const RebootsGridComponent = ({
  buttonCreate,
  items,
  status,
  error,
  onForm,
}) => {
  const { gridBreakPoints } = useLayout();

  if (status !== loadingStatus.SUCCEEDED) {
    return <LoaderComponent />;
  }

  if (status === loadingStatus.FAILED) {
    return <ErrorComponent message={error} />;
  }

  return (
    <FlexboxGrid className="grid-items" align="top">
      {buttonCreate && (
        <FlexboxGrid.Item as={Col} {...gridBreakPoints}>
          {buttonCreate}
        </FlexboxGrid.Item>
      )}
      {items.map(item => (
        <FlexboxGrid.Item as={Col} {...gridBreakPoints} key={`${item.id}`}>
          <RebootItemComponent
            id={item.id}
            name={item.name}
            description={item.description}
            players={item.players}
            schedule={item.schedule}
            onForm={onForm}
          />
        </FlexboxGrid.Item>
      ))}
    </FlexboxGrid>
  );
};

const RebootItemComponent = ({
  id,
  name,
  description,
  players,
  schedule,
  onForm,
}) => {
  const dispatch = useDispatch();

  const handleEdit = () => {
    onForm(null, id);
    dispatch(fillForm({
      id,
      name,
      description,
      players,
      schedule,
    }));
  };

  const handleDelete = async () => {
    await deleteReboots(id);
    dispatch(fetchReboots());
  };
 
  const menuItems = [
    { key: "delete", label: "Удалить", action: handleDelete },
  ];

  const menuItemSelectHandler = eventKey => {
    menuItems.find(it => it.key === eventKey).action();
  };

  return (
    <CardComponent
      onClick={handleEdit}
      title={name}
      subTitle={`Устройств: ${players.length}`}
      bottom={
        <div className="carousel-container">
          <ul className="carousel-list">
            {schedule.map(({ hour, minute }, index) => (
              <li key={index} className="ghost">
                <Tag size="lg" color="blue">
                  <MdTimer />
                  {` ${hour.toString().padStart(2, 0)}:${minute.toString().padStart(2, 0)}`}
                </Tag>
              </li>
            ))}
          </ul>
        </div>
      }
      menuItems={menuItems}
      menuItemSelect={menuItemSelectHandler}
    />
  );
};

export default RebootsGridComponent;
