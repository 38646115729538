import { Nav } from "rsuite";

const DetailHeaderComponent = ({
  tabs,
  tabComponent,
  active,
  onSelect,
}) => {
  return (
    <>
      <Nav activeKey={active} onSelect={onSelect} justified appearance="subtle">
        {Object.keys(tabs).map((item) => (
          <Nav.Item eventKey={tabs[item].key} key={tabs[item].key} style={{ justifyContent: "center" }}>
            {tabs[item].title}
          </Nav.Item>
        ))}
      </Nav>
      {tabComponent}
    </>
  );
};

export default DetailHeaderComponent;
