import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";
import { user } from "constants/endpoints";

const getMe = async (access_token) => {
  const me = await fetcher(user.ME, {
    headers: { Authorization: `OAuth ${access_token}` }
  });
  const res = await me.json();
  return res;
};

const postLogin = createAsyncThunk("login", async (data) => {
  const response = await fetcher(user.LOGIN, {
    method: "POST",
    body: JSON.stringify(data),
  });

  if (!response.ok) { // response.status !== 200
    throw new Error(`${response.status}: ${response.statusText}`);
  }

  const result = await response.json();

  const meResult = await getMe(result.access_token);

  return {
    access_token: result.access_token,
    profile: meResult,
  };
});

const postForgot = createAsyncThunk("forgot", async (data) => {
  const response = await fetcher(user.FORGOT, {
    method: "POST",
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }

  return response;
});

const postReset = createAsyncThunk("reset", async (data) => {
  const response = await fetcher(user.RESET, {
    method: "POST",
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }

  return response;
});

const initialState = {
  user: {
    meta: {},
    token: null,
  },
  forgot: {
    status: loadingStatus.IDLE,
    error: null,
  },
  reset: {
    status: loadingStatus.IDLE,
    error: null,
  },
  status: loadingStatus.IDLE,
  error: null,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    // omit existing reducers here
  },
  extraReducers(builder) {
    builder
      .addCase(postLogin.pending, (state, _) => {
        state.status = loadingStatus.LOADING;
      })
      .addCase(postLogin.fulfilled, (state, action) => {
        state.status = loadingStatus.SUCCEEDED;
        state.user = action.payload;
      })
      .addCase(postLogin.rejected, (state, action) => {
        state.status = loadingStatus.FAILED;
        state.error = `${action.error.name}: ${action.error.message}`;
      })

      .addCase(postForgot.fulfilled, (state) => {
        state.forgot.status = loadingStatus.SUCCEEDED;
        state.forgot.error = null;
      })
      .addCase(postForgot.rejected, (state, action) => {
        state.forgot.status = loadingStatus.FAILED;
        state.forgot.error = `${action.error.name}: ${action.error.message}`;
      })

      .addCase(postReset.fulfilled, (state) => {
        state.reset.status = loadingStatus.SUCCEEDED;
        state.reset.error = null;
      })
      .addCase(postReset.rejected, (state, action) => {
        state.reset.status = loadingStatus.FAILED;
        state.reset.error = `${action.error.name}: ${action.error.message}`;
      })
  }
});

export default loginSlice.reducer;

export {
  getMe,
  postLogin,
  postForgot,
  postReset,
};
