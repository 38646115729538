import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";
import { players } from "constants/endpoints";

const getBrightness = async (query) => {
  const response = await fetcher(`${players.BRIGHTNESS}${query}`);
  return response.json();
};

const fetchBrightness = createAsyncThunk("brightness", async (query = "") => {
  return await getBrightness(query);
});

const fetchBrightnessPlayers = createAsyncThunk("brightness/players", async (query = "") => {
  const response = await fetcher(`${players.ALL}${query}`);
  return response.json();
});

const createBrightness = async (data) => {
  const response = await fetcher(players.BRIGHTNESS, {
    method: "POST",
    body: JSON.stringify(data),
  });
  return response.json();
};
const putBrightness = async (id, data) => {
  const response = await fetcher(`${players.BRIGHTNESS}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
  return response.json();
};

const deleteBrightness = async (id) => fetcher(`${players.BRIGHTNESS}/${id}`, { method: "DELETE" });

const initialState = {
  objects: [],
  meta: {},
  status: loadingStatus.IDLE,
  error: null,

  formData: {},

  players: [],
};

const brightnessSlice = createSlice({
  name: "brightness",
  initialState,
  reducers: {
    fillForm: (state, action) => {
      state.formData = action.payload;
    },
    addBrightness(state, { payload }) {
      state.objects = [...state.objects, ...payload];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchBrightness.pending, (state, action) => { state.status = loadingStatus.LOADING; })
      .addCase(fetchBrightness.fulfilled, (state, action) => {
        state.status = loadingStatus.SUCCEEDED;
        state.objects = action.payload.objects;
        state.meta = action.payload.meta;
      })

      .addCase(fetchBrightnessPlayers.fulfilled, (state, action) => { state.players = action.payload.objects; })
  }
});

export default brightnessSlice.reducer;

export const { fillForm, addBrightness } = brightnessSlice.actions;

export {
  getBrightness,
  fetchBrightness,
  fetchBrightnessPlayers,

  createBrightness,
  putBrightness,
  deleteBrightness,
};
