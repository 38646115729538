import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Message, Tooltip, Whisper } from "rsuite";
import { MdOutlineHelpOutline } from "react-icons/md";

import DraggableItemsComponent from "components/draggable/DraggableItemsComponent";
import { fetchConflicts } from "redux/slices/playlistSlice";
import { iconStyle } from "constants";

const ConflictsComponent = ({
  formValue,
  orderItems,
  setOrderItems,
  nextStep,
}) => {
  const dispatch = useDispatch();
  const { conflicts } = useSelector(state => state.playlist);

  useEffect(() => {
    const { id, isClone, ...payload } = formValue;
    dispatch(fetchConflicts({
      ...payload,
      data: {
        rrule: payload.data.rrule,
      },
    }));
  }, []);

  useEffect(() => {
    const { id, isClone, ...formRest } = formValue;
    const _formValue = (id && !isClone) ? { id, ...formRest } : formRest;
    const currentItemObj = { id: null, name: _formValue.name };
    if (conflicts.length > 0) {
      const _conflicts = [...conflicts];
      const currentIndex = _conflicts.findIndex(c => c.id === _formValue.id);
      if (currentIndex > -1) {
        // conflicts.splice(currentIndex, 1, currentItemObj);
        _conflicts[currentIndex] = currentItemObj;
      } else {
        _conflicts.unshift(currentItemObj);
      }
      setOrderItems(_conflicts);
    } else {
      setOrderItems([currentItemObj]);
    }
  }, [conflicts, formValue]);

  // console.log(formValue)
  // console.log(conflicts)

  return (
    <div className="campaigns-form_content-items">

      <div className="campaigns-form_content-items-header">
        <h5>Очередь плейлистов</h5>
      </div>
      <div className="mt-20 mb-20">
        {conflicts.message?.errors && Object.entries(conflicts.message.errors).map(([key, value], i) => (
          <Message type="error" className="mb-10" key={i}>{`${key}: ${value}`}</Message>
        ))}
        {orderItems.length > 1 ? (
          <>
            <Message type="info" className="mb-10">
              <strong>
                Расставьте плейлисты в очередь
                <Whisper
                  placement="bottomEnd"
                  speaker={
                    <Tooltip>
                      К устройству привязано несколько плейлистов пересекающихся по времени. Расставьте их согласно приоритетности.
                    </Tooltip>
                  }
                >
                  <span> <MdOutlineHelpOutline color="#1675e0" size={iconStyle.HEADER_ICON_SIZE_SM} /></span>
                </Whisper>
                </strong>
                <div>Элементы можно перетаскивать</div>
            </Message>
            <DraggableItemsComponent
              orderItems={orderItems}
              setOrderItems={setOrderItems}
              highlightId={!formValue.isClone && formValue.id}
            />
          </>
        ) : (
          <Message type="success" showIcon className="mb-10" header="Нет очереди плейлистов" />
        )}
      </div>
    </div>
  );
};

export default ConflictsComponent;
