import SideNavComponent from "components/SideNavComponent";
import { sideNavCampaigns } from "helpers/navs";


const CampaignsSideNavComponent = ({ handleExportAction }) => {
  const navActionHandlers = {
    "campaigns-export-action": handleExportAction,
  };

  return (
    <SideNavComponent
      navActionHandlers={navActionHandlers}
      sideNavItems={sideNavCampaigns}
    />
  );
};

export default CampaignsSideNavComponent;
