import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";
import { playlists } from "constants/endpoints";

const getPlaylists = async (query) => {
  const response = await fetcher(`${playlists.ALL}${query}`);
  return response.json();
};

const fetchPlaylists = createAsyncThunk("playlists", async (query = "") => {
  return await getPlaylists(query);
});

const initialState = {
  objects: [],
  meta: {},
  status: loadingStatus.IDLE,
  error: null,
};

const playlistsSlice = createSlice({
  name: "playlists",
  initialState,
  reducers: {
    addPlaylists(state, { payload }) {
      state.objects = [...state.objects, ...payload];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPlaylists.pending, (state, action) => {
        state.status = loadingStatus.LOADING;
      })
      .addCase(fetchPlaylists.fulfilled, (state, action) => {
        state.status = loadingStatus.SUCCEEDED;
        state.objects = action.payload.objects;
        state.meta = action.payload.meta;
      })
      .addCase(fetchPlaylists.rejected, (state, action) => {
        state.status = loadingStatus.FAILED;
        state.error = `${action.error.name}: ${action.error.message}`;
      })
  }
});

export const { addPlaylists } = playlistsSlice.actions;

export default playlistsSlice.reducer;

export {
  getPlaylists,
  fetchPlaylists,
};
