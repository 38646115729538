import { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Form,
  Button,
  FlexboxGrid,
  Schema,
  SelectPicker,
  TagPicker,
  Message,
  IconButton,
} from "rsuite";
import { MdClose } from "react-icons/md";

import InputFieldComponent from "components/forms/InputFieldComponent";
import HeaderComponent from "components/HeaderComponent";
import ErrorComponent from "components/ErrorComponent";
import usePopup from "hooks/usePopup";
import {
  fetchReports,
  fetchReportsPlayers,
  createReports,
  putReports,
} from "redux/slices/reportsSlice";
import { reportsFormInitialData } from "constants/form";
import { DAYS_LABEL } from "constants";

const { StringType } = Schema.Types;
const model = Schema.Model({
  name: StringType().isRequired("Обязательное поле"),
});

const ReportsSchedulesFormComponent = () => {
  const dispatch = useDispatch();
  const { playersTags } = useSelector(state => state.tags);
  const { players, formData } = useSelector(state => state.reports);

  const formRef = useRef();
  const [formValue, setFormValue] = useState(reportsFormInitialData);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [filteredTag, setFilteredTag] = useState();
  const [customErrors, setCustomErrors] = useState({ players: null, schedule: null });
  const { responseToaster } = usePopup();

  const handleSelectedPlayers = val => {
    setSelectedPlayers(val || []);
    setCustomErrors({ ...customErrors, players: null });
  };
  const handleChangeTime = (e, d, t) => {
    setFormValue({
      ...formValue,
      schedule: {
        ...formValue.schedule,
        [d]: {
          ...formValue.schedule[d],
          [t]: e.target.value,
        },
      },
    });
  };

  const handleRemoveScheduleItem = d => {
    setFormValue({
      ...formValue,
      schedule: {
        ...formValue.schedule,
        [d]: { on: null, off: null },
      },
    });  };

  const handleSubmit = async () => {
    const errors = [];
    if (selectedPlayers.length === 0) errors.players = "Выберите устройства";
    setCustomErrors(errors);

    const { id, ...restForm } = formValue;

    if (formRef.current.check() && Object.values(errors).filter(err => err).length === 0) {
      restForm.players = selectedPlayers;
      let res = null;
      if (id) res = await putReports(id, restForm);
      else res = await createReports(restForm);
      responseToaster(res);
      dispatch(fetchReports());
    }
  };

  useEffect(() => {
    const { players: formPlayers, ...rest } = formData;
    const existingPlayers = formPlayers.map(({ id }) => id);
    setFormValue(rest);
    setSelectedPlayers(existingPlayers);
  }, [formData]);

  useEffect(() => {
    const url = `?hide_children=1${filteredTag ? `&tag=${filteredTag}` : ""}`;
    dispatch(fetchReportsPlayers(url));
  }, [dispatch, filteredTag]);

  return (
    <Form
      model={model}
      ref={formRef}
      onChange={setFormValue}
      formValue={formValue}
      fluid
    >
      <HeaderComponent
        title={`${formValue.id ? "Изменить" : "Создать"} расписание`}
        tag="h4"
        gutter={0}
      />
      
      <HeaderComponent
        title="Введите данные"
        tag="h6"
        gutter={0}
      />
      <InputFieldComponent name="name" label="Название" />
      <InputFieldComponent name="description" label="Описание" />

      <HeaderComponent
        title="Выберите устройства"
        tag="h6"
        gutter={0}
      />
      {(playersTags.length > 0) ? (
        <Form.Group>
          <FlexboxGrid>
            <FlexboxGrid.Item colspan={5}>
              <Form.ControlLabel>Отобрать по тегу:</Form.ControlLabel>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={19}>
              <SelectPicker
                data={playersTags.map(({ id, name }) => ({ label: name, value: id }))}
                onChange={setFilteredTag}
                block
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Form.Group>
      ) : (
        <Message type="warning">Ни один тег устройства не создан</Message>
      )}

      {(players.length > 0) ? (
        <Form.Group>
          <Form.ControlLabel>Доступные устройства</Form.ControlLabel>
          <TagPicker
            data={players.map(({ id, name }) => ({ label: name, value: id }))}
            onChange={handleSelectedPlayers}
            value={selectedPlayers}
            block
          />
          {customErrors.players ? <ErrorComponent message={customErrors.players} /> : ""}
        </Form.Group>
      ) : (
        <Message showIcon type="warning">Устройств не найдено</Message>
      )}

      <HeaderComponent
        title="Создайте расписание"
        tag="h6"
        gutter={0}
      />
      {Object.keys(formValue.schedule).map(d => (
        <Form.Group key={`d-${d}`}>
          <FlexboxGrid justify="space-between" align="middle">
            <FlexboxGrid.Item>
              {DAYS_LABEL[d]}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={9}>
              <input
                type="time"
                className="rs-input"
                value={formValue.schedule[d].on || ""}
                onChange={e => handleChangeTime(e, d, "on")}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={9}>
              <input
                type="time"
                className="rs-input"
                value={formValue.schedule[d].off || ""}
                onChange={e => handleChangeTime(e, d, "off")}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
              <IconButton
                icon={<MdClose />}
                onClick={() => handleRemoveScheduleItem(d)}
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Form.Group>
      ))}
    
      <Form.Group>
        {customErrors.schedule ? <ErrorComponent message={customErrors.schedule} /> : ""}
      </Form.Group>

      <Form.Group>
        <Button appearance="primary" onClick={handleSubmit}>Сохранить</Button>
      </Form.Group>
    </Form>
  );
};

export default ReportsSchedulesFormComponent;
