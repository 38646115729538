
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import HeaderComponent from "components/HeaderComponent";
import WidgetInstallFormComponent from "components/widgets/WidgetInstallFormComponent";
import useLayout from "hooks/useLayout";
import { fetchWidgets } from "redux/slices/widgetsSlice";
import Desktop from "./Desktop";
import Mobile from "./Mobile";

const contentBarContents = {
    WIDGETS_OVERVIEW: "widgets_overview",
    WIDGET_INSTALL_FORM: "widget_install_form",
  };

const Widgets = () => {
  const [currentContent, setCurrentContent] = useState(contentBarContents.WIDGETS_OVERVIEW);
  const [selectedContentItem, setSelectedContentItem] = useState();
  const navigate  = useNavigate();
  const { "*": path } = useParams();
  const dispatch = useDispatch();
  const data = useSelector(state => state.widgets);
  const { isDesktop } = useLayout();

  // const handleCreateButton = () => console.log("Скоро!");

  const handleInstallButton = item => {
    setCurrentContent(contentBarContents.WIDGET_INSTALL_FORM);
    setSelectedContentItem(item);
    navigate(`/widgets/install/${item.id}`);
  };

  const contentComponents = {
    [contentBarContents.WIDGETS_OVERVIEW]: (<h3>Выберите виджет для установки</h3>),
    [contentBarContents.WIDGET_INSTALL_FORM]: (
      <WidgetInstallFormComponent
        data={selectedContentItem}
        onClose={() => setCurrentContent(contentBarContents.WIDGETS_OVERVIEW)}
      />
    ),
  };

  useEffect(() => {
    dispatch(fetchWidgets());
  }, [dispatch]);

  useEffect(() => {
    if (!path) setCurrentContent(contentBarContents.WIDGETS_OVERVIEW);
  }, [path]);

  const actions = { handleInstallButton };

  return (
    <>
      <HeaderComponent
        title="Виджеты"
      />
      {isDesktop ? (
        <Desktop
          data={data}
          contentBar={contentComponents[currentContent]}
          {...actions}
        />
      ) : (
        <Mobile
          data={data}
          contentBar={contentComponents[currentContent]}
          {...actions}
        />
      )}
    </>
  );

}

export default Widgets;
