import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Message } from "rsuite";

import HeaderComponent from "components/HeaderComponent";
import CardComponent from "components/CardComponent";
import { fetchPlayerPlaylists } from "redux/slices/playerSlice";
import { formatDate } from "helpers/date";
import { playlists as playlistsUrl } from "constants/urls";
import { CAMPAIGNS_STATES } from "constants";
import { MdMonitor, MdOutlineSyncAlt } from "react-icons/md";

const PlayerPlaylistsComponent = () => {
  const dispatch = useDispatch();
  const { data: player, playlists } = useSelector(state => state.player);
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams({
      // state: "deleted" "pending", "running" "finished"
      state: "running",
      player_id: player.id,
      limit: -1,
    });
    dispatch(fetchPlayerPlaylists(`?${query.toString()}`));
  }, [dispatch, player.id]);


  return (
    <div>
      <HeaderComponent
        title={player.name}
        tag="h3"
        gutter={0}
      />
  
  
      <HeaderComponent title="Список плейлистов" tag="h6" className="inner-header" />
      {(playlists.length > 0) ? (
        playlists.map(({ players_received_playback_items_count, players_count, trigger }, index) => {
          const playbackInterval = [
            formatDate(trigger.revision.start_datetime, ".").full_datetime,
            formatDate(trigger.revision.end_datetime, ".").full_datetime,
          ];
          return (
            <CardComponent
              className="hovered"
              key={`${trigger.id}`}
              title={trigger.name}
              unit={
                <>
                  <MdMonitor /> {players_count}
                  <span className="ml-10"><MdOutlineSyncAlt /> {players_received_playback_items_count}</span>
                </>
              }
              date={playbackInterval.join(" - ")}
              // subTitle={`Устройства получили контент: ${players_received_playback_items_count} из ${players_count}`}
              subTitle={trigger.type}
              badgeColor={CAMPAIGNS_STATES[trigger.state].color}
              // onClick={() => onClick(trigger.id)}
              onClick={() => navigate(`${playlistsUrl.ALL}/detail/${trigger.id}`)}
              // menuItems={menuItems}
              // menuItemSelect={key => menuItems.find(it => it.key === key).action(trigger)}
            />
          )}
        )
      ) : (
        <Message type="warning">Нет ни одной плейлиста</Message>
      )}

    </div>
  );
};

export default PlayerPlaylistsComponent;
