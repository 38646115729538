import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";
import { content } from "constants/endpoints";

const fetchFillers = createAsyncThunk("fillers", async (query = "") => {
  const response = await fetcher(`${content.FILLERS}${query}`);
  return response.json();
});

const saveFiller = async (files) => {
  const response = await fetcher(content.FILLERS, {
    method: "POST",
    body: JSON.stringify(files),
  });
  return response;
};

const putFiller = async (id, data) => {
  const response = await fetcher(`${content.FILLERS}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
  return response;
};

const patchFiller = async (id, data) => {
  const response = await fetcher(`${content.FILLERS}/${id}`, {
    method: "PATCH",
    body: JSON.stringify(data),
  });
  return response;
};

const deleteFiller = async id => fetcher(`${content.FILLERS}/${id}`, { method: "DELETE" });

const initialState = {
  objects: [],
  meta: {},
  status: loadingStatus.IDLE,
  error: null,
};

const fillersSlice = createSlice({
  name: "fillers",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchFillers.pending, (state, action) => {
        state.status = loadingStatus.LOADING;
      })
      .addCase(fetchFillers.fulfilled, (state, action) => {
        state.status = loadingStatus.SUCCEEDED;
        state.objects = action.payload.objects;
        state.meta = action.payload.meta;
      })
      .addCase(fetchFillers.rejected, (state, action) => {
        state.status = loadingStatus.FAILED;
        state.error = `${action.error.name}: ${action.error.message}`;
      })
  }
});

export default fillersSlice.reducer;

export {
  initialState,
  fetchFillers,

  saveFiller,
  putFiller,
  patchFiller,
  deleteFiller,
};
