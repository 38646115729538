import { useEffect, useState } from "react";
import { Panel, Loader, Message, Button, Tag } from "rsuite";
import { MdDownload } from "react-icons/md";

import HeaderComponent from "components/HeaderComponent";
import { fetchLatestRelease, downloadGithubAsset } from "redux/slices/settingsSlice";
import { formatDate } from "helpers/date";

const GITHUB_REPOS = [
  { owner: 'caps-look', repo: 'caps-free-player' },
  { owner: 'caps-look', repo: 'caps-player' },
];

const Downloads = () => {
  const [githubAssets, setGithubAssets] = useState([]);
  const [isDownload, setIsDownload] = useState(false);
  
  useEffect(() => {
    const githubRepos = async () => {
      const actionsQueue = [];
      for (const { owner, repo } of GITHUB_REPOS) {
        actionsQueue.push(fetchLatestRelease(`?owner=${owner}&repo=${repo}`));
      }
      const results = await Promise.all(actionsQueue);
      setGithubAssets(results);
    }
    githubRepos();
  }, []);

  return (
    <>
      <HeaderComponent title="Загрузки" />
      <div className="caps-content">
        <Panel header={<h3>Файлы</h3>} bordered>
          {githubAssets.map(({ tag_name, draft, assets }) => {
            // TODO: возможно стоит отфильтровать нужный, но работает
            const asset = assets[0];
            return (
              <Message
                style={{ margin: 5, display: "inline-block" }}
                key={asset.id}
                type="info"
                header={<strong>{asset.name}</strong>}
              >
                <div className="mb-10">
                  {formatDate(asset.created_at, ".").full_datetime} / {`Скачан: ${asset.download_count}`}
                </div>
                <div className="mb-10">
                  <Tag color="blue">{tag_name}</Tag>
                  <Tag color={draft ? "red" : "green"}>{draft ? "draft" : "publish"}</Tag>
                </div>
                <Button
                  appearance="primary"
                  block
                  onClick={() => downloadGithubAsset(asset.name, asset.url, setIsDownload)}
                >
                  Скачать {isDownload ? <Loader /> : <MdDownload className="rs-icon" />}
                </Button>
              </Message>
            );
          })}
        </Panel>
      </div>
    </>
  );
};

export default Downloads;
