import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher from "helpers/fetcher";
import { settings, user, downloads } from "constants/endpoints";

const fetchUsers = createAsyncThunk("settings/users", async (q = "") => {
  const response = await fetcher(`${settings.ACCOUNTS}${q}`);
  return response.json();
});

const fetchRoles = createAsyncThunk("settings/roles", async (q = "") => {
  const response = await fetcher(`${settings.ROLES}${q}`);
  return response.json();
});

const fetchOrganization = createAsyncThunk("settings/org", async (q = "") => {
  const response = await fetcher(`${settings.SYSTEM}${q}`);
  return response.json();
});

const fetchProfile = createAsyncThunk("settings/profile", async () => {
  const response = await fetcher(user.ME);
  return response.json();
});

const createUser = async (data) => {
  const response = await fetcher(settings.ACCOUNTS, {
    method: "POST",
    body: JSON.stringify(data),
  });
  return response;
};
const putUser = async (id, data) => {
  const response = await fetcher(`${settings.ACCOUNTS}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
  return response;
};
const patchUser = async (id, data) => {
  const response = await fetcher(`${settings.ACCOUNTS}/${id}`, {
    method: "PATCH",
    body: JSON.stringify(data),
  });
  return response;
};

const deleteUser = async id => fetcher(`${settings.ACCOUNTS}/${id}`, { method: "DELETE" });

const putProfile = async (data) => {
  const response = await fetcher(user.ME, {
    method: "PUT",
    body: JSON.stringify(data),
  });
  return response;
};

const patchOrganization = async (data) => {
  const response = await fetcher(settings.SYSTEM, {
    method: "PATCH",
    body: JSON.stringify(data),
  });
  return response;
};

const sendInvite = async (id) => {
  const response = await fetcher(`${settings.ACCOUNTS}/${id}/send_welcome_email`, {
    method: "POST",
    body: JSON.stringify({}),
  });
  return response;
};

const fetchLatestRelease = async (q = "") => {
  const response = await fetcher(`${downloads.RELEASES}/${q}`);
  return response.json();
};

const downloadGithubAsset = async (name, url, setIsDownload) => {
  setIsDownload(true);
  fetcher(`${downloads.ASSETS}?asset_url=${url}`)
    .then(res => res.blob())
    .then(blob => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", name);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setIsDownload(false);
    });
};

const initialState = {
  users: [],
  roles: [],
  organization: {},
  profile: {},
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchUsers.fulfilled, (state, action) => { state.users = action.payload; })
      .addCase(fetchRoles.fulfilled, (state, action) => { state.roles = action.payload; })
      .addCase(fetchOrganization.fulfilled, (state, action) => { state.organization = action.payload; })
      .addCase(fetchProfile.fulfilled, (state, action) => { state.profile = action.payload; })
  }
});

export default settingsSlice.reducer;

export {
  fetchUsers,
  fetchRoles,
  fetchOrganization,
  fetchProfile,

  createUser,
  putUser,
  patchUser,
  deleteUser,

  putProfile,
  patchOrganization,

  sendInvite,

  fetchLatestRelease,
  downloadGithubAsset,
};
