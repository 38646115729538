import { useToaster, Notification } from "rsuite";
import { MdOutlineFileCopy } from "react-icons/md";

const ActivatePinComponent = ({ pin }) => {
  const toaster = useToaster();

  const handleClick = e => {
    e.stopPropagation();
    console.log(`Copy pin: ${pin}`);
    window.navigator.clipboard.writeText(pin);
    toaster.push(
      <Notification closable type="success" header="Скопировано" />,
      { placement: "topCenter" }
    );
  };

  return (
    <div className="item-card__pin" onClick={handleClick}>
      <div className="item-card__pin-icon"><MdOutlineFileCopy /></div>
      <div className="item-card__pin-code">PIN-код</div>
    </div>
  );
};

export default ActivatePinComponent;
