import { useState } from "react";
import userData from "helpers/user";

const useUser = () => {
  const [user, setUser] = useState(userData);

  const saveUser = (user) => {
    setUser(user);
    localStorage.setItem("user-profile", JSON.stringify(user));
  };

  const removeUser = () => {
    setUser();
    localStorage.removeItem("user-profile");
  };

  return {
    user,
    accessToken: user?.access_token,
    setUser: saveUser,
    removeUser,
  }
};

export default useUser;
