import WidgetsGridComponent from "components/widgets/WidgetsGridComponent";
// import ButtonCreateComponent from "components/buttons/ButtonCreateComponent";
import DesktopLayout from "components/layouts/DesktopLayout";

const Desktop = ({
  data,
  contentBar,
  handleInstallButton,
}) => {
  return (
    <DesktopLayout contentBar={contentBar} template="ccc">
      <WidgetsGridComponent
        items={data.objects}
        status={data.status}
        error={data.error}
        onClick={handleInstallButton}
      />
    </DesktopLayout>
  );
};

export default Desktop;
