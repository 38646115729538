import { MAX_INTERVAL_RATIO, NO_IMAGE_URL } from "constants";

const getThumbnail = (contentItem, type = "preview_thumbnail") => (
  contentItem.type === "html" ?
    (contentItem.application_version?.[type] || NO_IMAGE_URL) :
    contentItem[type]
);

const downloadFromLink = (url, filename, target) => {
  const link = document.createElement("a");
  link.href = url;
  if (filename) link.setAttribute("download", filename);
  if (target) link.setAttribute("target", target)
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

const toInt = (num) => num ? parseInt(num) : 0;

const matchMax = (arrayOfNum) => {
  if (arrayOfNum.length === 0) {
    return 0;
  }
  return Math.max(...arrayOfNum);
};

const hoursRangeBuilder = (min) => {
  const range = []
  for(let i = 0; i <= 23; i++) {
    range.push({ value: `${`${i}`.padStart(2, '0')}:00`, label: `${`${i}`.padStart(2, '0')}:00` })
  }
  if (min) {
    const index = range.findIndex(r => r.value === min);
    if (index !== -1) range.splice(0, index);
  }
  return range
};

const itemsTotalDuration = items => {
  let duration = 0;
  items.forEach(item => {
    if (item.data.duration) {
      duration += item.data.duration;
    }
    if (item.content_files[0].content_file.data.duration) {
      duration += item.content_files[0].content_file.data.duration;
    }
    if (item.data.default_duration) {
      duration += item.data.default_duration;
    }
  });
  return duration;
};
const calculateInterval = (interval, decode = false) => {
  if (decode) {
    return {
      interval: Math.ceil(60 / (interval / 60)),
      max_interval: Math.ceil(60 / (interval / 60) * MAX_INTERVAL_RATIO),
    };
  }
  return {
    interval: Math.ceil(60 / interval * 60),
    max_interval: Math.ceil(60 / interval * 60 * MAX_INTERVAL_RATIO),
  };
};
const playbackPerTime = (interval, isShort = false) => ({
  minutes: `${(60 / interval).toFixed(2)}${isShort ? "" : " мин"}`,
  seconds: `${60 / interval * 60}${isShort ? "" : " сек"}`,
});

const convertBytes = (bytes = 0) => ({
  kb: `${(bytes / 1024).toFixed(2)}Kb`,
  mb: `${(bytes / Math.pow(1024, 2)).toFixed(2)}Mb`,
  gb: `${(bytes / Math.pow(1024, 3)).toFixed(2)}Gb`,
});

const buildDeepObjectFromFlat = (obj, model) => {
  const result = {};
  for (const objectPath in obj) {
    const parts = objectPath.split('.');
    let target = result;
    while (parts.length > 1) {
      const part = parts.shift();
      target = target[part] = target[part] || {};
    }
    if (model?.$spec?.[objectPath]?.$typeName === "number") {
      target[parts[0]] = toInt(obj[objectPath]);
    } else {
      target[parts[0]] = obj[objectPath];
    }
  }
  return result;
};
const buildFlatFromDeepObject = (obj) => {
  const result = {};
  (function recurse(obj, current) {
    for (let key in obj) {
      const value = obj[key];
      const newKey = (current ? current + "." + key : key); 
      if (value && typeof value === "object" && value.length === undefined) {
        recurse(value, newKey);
      } else {
        result[newKey] = value;
      }
    }
  })(obj);
  return result;
};

const listToTree = (list, defaultChild) => {
  let map = {}, node, roots = [], i;
  for (i = 0; i < list.length; i++) {
    map[list[i].id] = i;
    list[i].children = defaultChild ? [{ ...defaultChild, parent_id: list[i].id }] : [];
  }
  for (i = 0; i < list.length; i++) {
    node = list[i];
    if (node.parent_id !== 0) {
      list[map[node.parent_id]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}

const barChartColors = percent => {
  if(!percent){
    return 'rgba(0,160,81, 0.1)'
  }
  if(percent <= 10){
    return 'rgba(0,160,81, 0.2)'
  }
  if(percent <= 20){
    return 'rgba(0,160,81, 0.2)'
  }
  if(percent <= 30){
    return 'rgba(0,160,81, 0.3)'
  }
  if(percent <= 40){
    return 'rgba(0,160,81, 0.4)'
  }
  if(percent <= 50){
    return 'rgba(0,160,81, 0.5)'
  }
  if(percent <= 60){
    return 'rgba(0,160,81, 0.6)'
  }
  if(percent <= 70){
    return 'rgba(0,160,81, 0.7)'
  }
  if(percent <= 80){
    return 'rgba(0,160,81, 0.8)'
  }
  if(percent <= 90){
    return 'rgba(0,160,81, 0.9)'
  }
  if(percent <= 100){
    return 'rgba(0,160,81, 1)'
  }
};

export {
  downloadFromLink,

  toInt,
  matchMax,
  convertBytes,

  getThumbnail,
  hoursRangeBuilder,
  itemsTotalDuration,
  calculateInterval,
  playbackPerTime,

  buildDeepObjectFromFlat,
  buildFlatFromDeepObject,
  listToTree,

  barChartColors,
};
