import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Row, Col, Tag } from "rsuite";

import DesktopLayout from "components/layouts/DesktopLayout";
import HeaderComponent from "components/HeaderComponent";
import AccountsFormComponent from "components/settings/AccountsFormComponent";
import ButtonCreateComponent from "components/buttons/ButtonCreateComponent";
import CardComponent from "components/CardComponent";
import { fetchUsers, deleteUser, putUser, sendInvite, patchUser } from "redux/slices/settingsSlice";
import useLayout from "hooks/useLayout";
import usePopup from "hooks/usePopup";
import { settings } from "constants/urls";

const contentBarContents = {
  ACCOUNTS_OVERVIEW: "accounts_overview",
  ACCOUNTS_FORM: "accounts_form",
};

const Accounts = () => {
  const [currentComponent, setCurrentComponent] = useState(contentBarContents.ACCOUNTS_OVERVIEW)
  const [selectedContentItem, setSelectedContentItem] = useState();
  const [reload, setReload] = useState();
  const navigate  = useNavigate();
  const dispatch = useDispatch();
  const { users } = useSelector(state => state.settings);
  const { gridBreakPoints } = useLayout();

  const handleCreateButton = () => {
    setCurrentComponent(contentBarContents.ACCOUNTS_FORM);
    setSelectedContentItem();
    navigate(`${settings.ACCOUNTS}/form`);
  };

  const handleEditButton = (item) => {
    setCurrentComponent(contentBarContents.ACCOUNTS_FORM);
    setSelectedContentItem({
      id: item.id,
      email: item.email,
      "profile.first_name": item.profile.first_name,
      "profile.last_name": item.profile.last_name,
      "roles": item.roles.map(({ id }) => id),
    });
    navigate(`${settings.ACCOUNTS}/form/${item.id}`);

  };

  const contentComponents = {
    [contentBarContents.ACCOUNTS_OVERVIEW]: <h6>Выберите или создайте пользователя</h6>,
    [contentBarContents.ACCOUNTS_FORM]: (
      <AccountsFormComponent
        reload={setReload}
        defaultValues={selectedContentItem}
        onClose={() => setCurrentComponent(contentBarContents.ACCOUNTS_OVERVIEW)}
      />
    ),
  };

  useEffect(() => {
    dispatch(fetchUsers("?limit=-1"));
  }, [dispatch, reload]);

  return (
    <>
      <HeaderComponent title="Пользователи" />
      <DesktopLayout contentBar={contentComponents[currentComponent]}>
        <Grid fluid>
          <Row>
            <Col {...gridBreakPoints}>
              <ButtonCreateComponent onClick={handleCreateButton}>
                Создать расписание
              </ButtonCreateComponent>
            </Col>
            {users.objects?.map(item => (
              <Col key={`${item.id}`} {...gridBreakPoints}>
                <AccountsItemComponent
                  id={item.id}
                  active={item.active}
                  email={item.email}
                  first_name={item.profile.first_name}
                  last_name={item.profile.last_name}
                  roles={item.roles}
                  reload={setReload}
                  onForm={() => handleEditButton(item)}
                />
              </Col>
            ))}
          </Row>
        </Grid>
      </DesktopLayout>
    </>
  );
};

const AccountsItemComponent = ({
  id,
  active,
  email,
  first_name,
  last_name,
  roles,
  reload,
  onForm,
}) => {
  const { responseToaster } = usePopup();

  const handleSendInvite = async () => {
    const res = await sendInvite(id);
    responseToaster(res);
  };

  const handleDoAdmin = async () => {
    const res = await putUser(id, {
      email: email,
      profile: { avatar: null, first_name, last_name },
      roles: [2]
    });
    responseToaster(res);
    reload(prev => !prev);
  };

  const handleDelete = async () => {
    const res = await deleteUser(id);
    responseToaster(res);
    reload(prev => !prev);
  };

  const handleActivated = async () => {
    const res = await patchUser(id, { active: true });
    responseToaster(res);
    reload(prev => !prev);
  };
 
  let menuItems = [];
  if (active) {
    menuItems = [
      { key: 1, label: "Отправить приглашение", action: handleSendInvite },
      { key: 2, label: "Сделать администратором", action: handleDoAdmin },
      { key: 3, label: "Деактивировать", action: handleDelete },
    ];
  } else {
    menuItems = [
      { key: 1, label: "Активировать", action: handleActivated },
    ];
  }

  const menuItemSelectHandler = eventKey => {
    menuItems.find(it => it.key === eventKey).action();
  };

  return (
    <CardComponent
      badgeColor={active ? "green" : "red"}
      title={`${first_name} ${last_name}`}
      subTitle={
        <>
          <p className="inner-form">{email}</p>
          {roles.map(r => <Tag key={r.id} color="blue">{r.title}</Tag>)}
        </>
      }
      menuItems={menuItems}
      menuItemSelect={menuItemSelectHandler}
      onClick={onForm}
    />
  );
};

export default Accounts;
