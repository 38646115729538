import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Uploader, SelectPicker, Button, Message } from "rsuite";

import HeaderComponent from "components/HeaderComponent";
import FileInfoRenderComponent from "./FileInfoRenderComponent";
import usePopup from "hooks/usePopup";
import { SERVER_URL, getHeaders } from "helpers/fetcher";
import { MAX_UPLOAD_FILESIZE, MIN_DURATION } from "constants";
import { content } from "constants/endpoints";

const FilesFormComponent = ({
  tagsList = [],
  reload,
  folderId,
  handleSubmit,
  requiredFields = {},
  onClose,
}) => {
  const [selectedFolder, setSelectedFolder] = useState(+folderId || null);
  const [filesFormValue, setFilesFormValue] = useState([]);
  const [uploaderValue, setUploaderValue] = useState([]);
  const { folders } = useSelector(state => state.files);
  const { messageToaster } = usePopup();

  const handleUploadSuccess = ({ id, type, preview_thumbnail, }, { fileKey, name }) => {
    const file = {
      fileKey,
      name,
      thumb: preview_thumbnail,
      content_file_id: id,
      type: type,
      default_duration: MIN_DURATION,
    };
    setFilesFormValue(prev => [...prev, file]);
  };

  const handleFileRemove = ({ fileKey }) => {
    const index = filesFormValue.findIndex(f => f.fileKey === fileKey);
    filesFormValue.splice(index, 1);
    setFilesFormValue([...filesFormValue]);
  };

  const handleChangeForm = (value, name, fileKey) => {
    const index = filesFormValue.findIndex(f => f.fileKey === fileKey);
    filesFormValue[index][name] = value;
    setFilesFormValue([...filesFormValue]);
  };

  const submitFormCb = (res) => {
    setFilesFormValue([])
    setUploaderValue([]);
    setSelectedFolder(null);
    reload(prev => !prev);
    if (res.findIndex(r => r.ok === false) === -1) {
      messageToaster({ message: "Все файлы успешно загружены" });
    } else {
      messageToaster({ message: "С некоторыми файлами возникла ошибка", type: "error" });
    }
  };

  const handleSubmitFiles = () => {
    handleSubmit(filesFormValue, selectedFolder, submitFormCb);
  };

  useEffect(() => {
    if (uploaderValue.length === 0) return;
    const missedLimit = [];
    const notMissedLimit = [];
    uploaderValue.forEach(u => {
      if (u.blobFile.size > MAX_UPLOAD_FILESIZE) notMissedLimit.push(u.name);
      else missedLimit.push(u);
    });
    setUploaderValue(missedLimit);
    if (notMissedLimit.length > 0) {
      messageToaster({
        header: "Не все файлы прошли проверку",
        message: `Следующие файлы не будут загружены из-за превышения лимита в 500mb: ${notMissedLimit.join("; ")}`,
        duration: 10000,
        type: "error",
      });
    }
  }, [uploaderValue.length]);

  return (
    <>
      <HeaderComponent
        title="Добавить файлы"
        tag="h3"
        gutter={0}
      />
      <Button
        appearance="primary"
        disabled={!filesFormValue.length || (requiredFields["selected-folder"] && !selectedFolder)}
        onClick={handleSubmitFiles}
        className="inner-form"
        block
      >
        Сохранить
      </Button>

      <HeaderComponent title="Рекламодатель" tag="h6" gutter={0} className="mb-0" />
      {requiredFields["selected-folder"] ? (
        <div className="inner-form">
          <SelectPicker
            data={folders.objects.map(({ id, name }) => ({ label: name, value: id }))}
            value={selectedFolder}
            onChange={setSelectedFolder}
            block
          />
        </div>
      ) : null}

      <HeaderComponent title="Файлы рекламодателя" tag="h6" gutter={0} className="mb-0" />
      <Uploader
        className="caps-file-uploader"
        action={`${SERVER_URL}${content.UPLOAD_FILES}`}
        headers={getHeaders()}
        accept="image/jpeg, image/png, video/mp4, video/webm, .zip"
        draggable
        multiple={true}
        listType="text"
        fileList={uploaderValue}
        onChange={setUploaderValue}
        onSuccess={handleUploadSuccess}
        onRemove={handleFileRemove}
        renderFileInfo={file => (
          <FileInfoRenderComponent
            formValue={filesFormValue.find(f => f.fileKey === file.fileKey)}
            file={file}
            tags={tagsList}
            onChangeForm={handleChangeForm}
          />
        )}
      >
        <div style={{ borderWidth: 3, height: 176, display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div>
            <div style={{ padding: "8px 15px" }}>Кликните или перетащите файлы сюда</div>
            <Message type="warning">
              <ul style={{ lineHeight: 2 }}>
                <li>Размер файла не должен превышать 500мб</li>
                <li>Продолжительность видео должна быть кратна секунде, "хвост" в десятичные секунды будет обрезан</li>
              </ul>
            </Message>
          </div>
        </div>
      </Uploader>
    </>
  );
};

export default FilesFormComponent;
