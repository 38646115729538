import {  Col, FlexboxGrid } from "rsuite";

import LoaderComponent from "components/LoaderComponent";
import ErrorComponent from "components/ErrorComponent";
import useLayout from "hooks/useLayout";
import { loadingStatus } from "helpers/fetcher";

const WidgetsGridComponent = ({
  // buttonCreate,
  items,
  status,
  error,
  onClick,
}) => {
  const { gridBreakPoints } = useLayout();

  if (status !== loadingStatus.SUCCEEDED) {
    return <LoaderComponent />;
  }

  if (status === loadingStatus.FAILED) {
    return <ErrorComponent message={error} />;
  }

  return (
    <FlexboxGrid className="grid-items" align="top">
      {/* {buttonCreate && (
        <Col {...gridBreakPoints}>
          {buttonCreate}
        </Col>
      )} */}
      {items.map(item => (
        <FlexboxGrid.Item as={Col} {...gridBreakPoints} key={`${item.id}-${item.version.id}`}>
          <div
            className="item-card bg-card"
            onClick={() => onClick(item)}
          >
            <img
              className="bg-card__image"
              src={item.version.preview}
              alt={item.name}
            />
          </div>
        </FlexboxGrid.Item>
      )
      )}
    </FlexboxGrid>
  );
};

export default WidgetsGridComponent;
