import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import DetailHeaderComponent from "components/DetailHeaderComponent";
import PlayerInfoComponent from "./PlayerInfoComponent";
import PlayerSettingComponent from "./PlayerSettingComponent";
import PlayerWindowsComponent from "./PlayerWindowsComponent";
import PlayerCampaignsComponent from "./PlayerCampaignsComponent";
import PlayerPlaylistsComponent from "./PlayerPlaylistsComponent";
import { fetchPlayer } from "redux/slices/playerSlice";

const playerDetailTabs = {
  INFO: { title: "Инфо", key: "info" },
  SETTINGS: { title: "Настройки", key: "settings" },
  WINDOWS: { title: "Окна", key: "windows" },
  CAMPAIGNS: { title: "Кампании", key: "campaigns" },
  PLAYLISTS: { title: "Плейлисты", key: "playlists" },
};

const PlayerDetailComponent = ({
  reload,
  onEdit,
  onClone,
  onDelete,
  onClose,
}) => {
  const [active, setActive] = useState(playerDetailTabs.INFO.key);
  const { "*": path } = useParams();
  const dispatch = useDispatch();

  const switchActiveTabComponent = (key) => {
    const cases = {
      [playerDetailTabs.INFO.key]: <PlayerInfoComponent />,
      [playerDetailTabs.SETTINGS.key]: <PlayerSettingComponent reload={reload} onEdit={onEdit} onClone={onClone} onDelete={onDelete} />,
      [playerDetailTabs.WINDOWS.key]: <PlayerWindowsComponent />,
      [playerDetailTabs.CAMPAIGNS.key]: <PlayerCampaignsComponent />,
      [playerDetailTabs.PLAYLISTS.key]: <PlayerPlaylistsComponent />,
    };
    return cases[key];
  };

  const selectDetailTabHandler = (key) => {
    setActive(key);
  };

  useEffect(() => {
    const [location, id] = path.split("/");
    if (location === "detail") dispatch(fetchPlayer(id));
  }, [dispatch, path, active]);

  return (
    <DetailHeaderComponent
      tabs={playerDetailTabs}
      tabComponent={switchActiveTabComponent(active)}
      active={active}
      onSelect={selectDetailTabHandler}
      onClose={onClose}
    />
  );
};

export default PlayerDetailComponent;
