import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { IconButton, ButtonToolbar, Whisper, Popover, Loader } from "rsuite";
import { MdTune, MdSort, MdGridView, MdList } from "react-icons/md";
import InfiniteScroll from "react-infinite-scroller";

import HeaderComponent from "components/HeaderComponent";
import FoldersFormComponent from "components/files/FoldersFormComponent";
import FileDetailComponent from "components/files/FileDetailComponent";
import FilesFormComponent from "components/files/FilesFormComponent";
import { FilterFilesForm, SortFilesForm, SortFoldersForm } from "components/files/filters";
import FilesEditFormComponent from "components/files/FilesEditFormComponent";
import useFilter from "hooks/useFilter";
import useLayout from "hooks/useLayout";
import usePopup from "hooks/usePopup";
import {
  saveFile,
  fetchFolders,
  getFiles,
  fetchFiles,
  deleteFolder,
  deleteFile,
  addFiles,
} from "redux/slices/filesSlice";
import { contentListSwitch } from "redux/slices/layoutSlice";
import { loadingStatus } from "helpers/fetcher";
import nextItems from "helpers/next-items";
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import { FILES_FILTERS, FILES_SORTS, FOLDERS_SORTS } from "constants/filters";

const contentBarContents = {
  FOLDERS_OVERVIEW: "folders_overview",
  FOLDER_DETAIL: "folder_detail",
  FOLDERS_FORM: "folders_form",
  FILE_DETAIL: "file_detail",
  FILES_FORM: "files_form",
  FILE_EDIT_FORM: "file_edit_form",
};

const Files = () => {
  const [currentContent, setCurrentContent] = useState(contentBarContents.FOLDERS_OVERVIEW);
  const [selectedFolderItem, setSelectedFolderItem] = useState();
  const [selectedContentItem, setSelectedContentItem] = useState();
  const [reload, setReload] = useState();
  const [foldersSortKey, setFoldersSortKey] = useState(Object.keys(FOLDERS_SORTS)[0]);
  const [reloadFolder, setReloadFolder] = useState();
  const navigate  = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector(state => state.files);
  const { contentTags } = useSelector(state => state.tags);
  const { searchState } = useSelector(state => state.layout);
  const {
    filterKey: filesFilterKey,
    sortKey: filesSortKey,
    additionalParams: filesAdditionalParams,
    setFilterKey: setFilesFilterKey,
    setSortKey: setFilesSortKey,
    setAdditionalParam: setFilesAdditionalParam,
    queryString: filesQueryString,
  } = useFilter({
    filters: FILES_FILTERS,
    sorts: FILES_SORTS,
    externalParams: ["name", "odvertiser_id"],
  });

  const { isDesktop } = useLayout();
  const { responseToaster } = usePopup();

  const handleFolderDetailButton = (folder) => {
    setCurrentContent(contentBarContents.FOLDER_DETAIL);
    setFilesAdditionalParam("odvertiser_id", folder.id);
    setSelectedFolderItem(folder);
  };
  const handleFolderCreateButton = () => {
    setSelectedFolderItem();
    setCurrentContent(contentBarContents.FOLDERS_FORM);
  };
  const handleFolderEditButton = folder => {
    setSelectedFolderItem(folder);
    setCurrentContent(contentBarContents.FOLDERS_FORM);
  };
  const handleFolderDeleteButton = async id => {
    await deleteFolder(id);
    // navigate(`/files`);
    setReloadFolder(prev => !prev);
  };

  const handleFileDetailButton = (item) => {
    setSelectedContentItem(item);
    setCurrentContent(contentBarContents.FILE_DETAIL);
    navigate(`/files/${item.id}${window.location.search}`);
    // if (odvertiserId) {
    //   navigate(`/files/${folderPath}/${folderPathId}/file/${item.id}`);
    // } else {
    //   navigate(`/files/file/${item.id}`);
    // }
  };
  const handleFilesCreateButton = () => {
    setSelectedContentItem();
    setCurrentContent(contentBarContents.FILES_FORM);
  };
  const handleFilesEditButton = (item) => {
    setSelectedContentItem(item);
    setCurrentContent(contentBarContents.FILE_EDIT_FORM);
  };
  const handleFilesDeleteButton = async id => {
    const res = await deleteFile(id);
    responseToaster(res);
    if (res.ok) setReload(prev => !prev);
  };

  const handleFilesSubmit = (filesFormValue, selectedFolder, callback) => {
    const saveFilesPromise = filesFormValue.map(({
      name,
      content_file_id,
      tags = [],
      type,
      default_duration,
    }) => saveFile({
      advertiser_id: selectedFolder,
      content_file_id: content_file_id,
      data: {
        orientation: "landscape",
        ...(type === "html" && { context: "fullscreen" }),
        ...(type !== "video" ?
          { default_duration: +default_duration } :
          { default_play_audio: true }),
      },
      description: "",
      name,
      tags,
      type,
    }));

    Promise.all(saveFilesPromise).then(res => callback(res));
  };

  const onLoadMore = async () => {
    const _items = await nextItems(filesQueryString, data.files.meta.offset, data.files.meta.limit, getFiles);
    if (_items.objects.length > 0) dispatch(addFiles(_items.objects));
  }

  const contentComponents = {
    [contentBarContents.FOLDERS_OVERVIEW]: null,
    [contentBarContents.FOLDER_DETAIL]: null,
    [contentBarContents.FOLDERS_FORM]: (
      <FoldersFormComponent
        reload={setReloadFolder}
        defaultValues={selectedFolderItem}
        onClose={() => setCurrentContent(contentBarContents.FOLDERS_OVERVIEW)}
      />
    ),
    [contentBarContents.FILE_DETAIL]: (
      <FileDetailComponent
        contentItem={selectedContentItem}
        onClose={() => setCurrentContent(contentBarContents.FOLDER_DETAIL)}
        onEdit={handleFilesEditButton}
        onDelete={handleFilesDeleteButton}
      />
    ),
    [contentBarContents.FILES_FORM]: (
      <FilesFormComponent
        tagsList={contentTags}
        reload={setReload}
        folderId={filesAdditionalParams.odvertiser_id}
        requiredFields={{ "selected-folder": true }}
        handleSubmit={handleFilesSubmit}
        onClose={() => setCurrentContent(contentBarContents.FOLDERS_OVERVIEW)}
      />
    ),
    [contentBarContents.FILE_EDIT_FORM]: (
      <FilesEditFormComponent
        tagsList={contentTags}
        reload={setReload}
        contentItem={selectedContentItem}
        onClose={() => setCurrentContent(contentBarContents.FOLDERS_OVERVIEW)}
      />
    ),
  };

  useEffect(() => {
    dispatch(fetchFolders(`?${FOLDERS_SORTS[foldersSortKey].value}&limit=-1`));
  }, [foldersSortKey, reloadFolder]);

  useEffect(() => setFilesAdditionalParam("name", searchState), [searchState]);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(filesQueryString);
      dispatch(fetchFiles(filesQueryString));
    }, 10);
    return () => clearTimeout(timer);
  }, [filesQueryString, reload]);

  const actions = {
    handleFolderDetailButton,
    handleFolderCreateButton,
    handleFolderEditButton,
    handleFolderDeleteButton,

    handleFileDetailButton,
    handleFilesCreateButton,
    handleFilesEditButton,
    handleFilesDeleteButton,
    onContentbarClose: () => setCurrentContent(null),
  };

  const generalProps = {
    data: data,
    folderItem: selectedFolderItem,
    // sideBar: <SideBar />,
    contentBar: contentComponents[currentContent],
    foldersFilter: (
      <ButtonToolbar>
        <Whisper
          trigger="click"
          placement="bottomStart"
          speaker={
            <Popover arrow={false}>
              <SortFoldersForm value={foldersSortKey} onChange={setFoldersSortKey} />
            </Popover>
          }
        >
          <IconButton icon={<MdSort size="1.5em" />} appearance="ghost" />
        </Whisper>
      </ButtonToolbar>
    ),
    ...actions,
  };

  return (
    <>
      <HeaderComponent
        title="Контент"
        right={
          <ButtonToolbar>
            <IconButton
              icon={<MdGridView size="1.5em" />}
              appearance="ghost"
              onClick={() => dispatch(contentListSwitch("grid"))}
            />
            <IconButton
              icon={<MdList size="1.5em" />}
              appearance="ghost"
              onClick={() => dispatch(contentListSwitch("list"))}
            />
            <Whisper
              trigger="click"
              placement="bottomEnd"
              speaker={
                <Popover arrow={false}>
                  <FilterFilesForm value={filesFilterKey} onChange={setFilesFilterKey} />
                </Popover>
              }
            >
              <IconButton icon={<MdTune size="1.5em" />} appearance="ghost" />
            </Whisper>
            <Whisper
              trigger="click"
              placement="bottomEnd"
              speaker={
                <Popover arrow={false}>
                  <SortFilesForm value={filesSortKey} onChange={setFilesSortKey} />
                </Popover>
              }
            >
              <IconButton icon={<MdSort size="1.5em" />} appearance="ghost" />
            </Whisper>
          </ButtonToolbar>
        }
      />
      <InfiniteScroll
        initialLoad={false}
        threshold={0}
        hasMore={data.files.status !== loadingStatus.LOADING && data.files.objects.length < data.files.meta.total}
        loader={<p className="text-center"><Loader size="md" /></p>}
        loadMore={onLoadMore}
      >
        {isDesktop ? (
          <Desktop {...generalProps} />
         ) : (
          <Mobile {...generalProps} />
        )}
      </InfiniteScroll>
    </>
  );

};

export default Files;
