import React, { useCallback, useEffect, useRef, useState } from "react";
import { Avatar, IconButton, Tag } from "rsuite";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { MdHorizontalRule } from "react-icons/md";

import FileItemComponent from "components/files/FileItemComponent";
import useLayout from "hooks/useLayout";
import { convertBytes, getThumbnail } from "helpers/utils";

const ItemTypes = {
  CARD: 'card',
};

const DraggableFilesComponent = ({ items, setItems, removeItem }) => {
  const [itemsState, setItemsState] = useState([]);
  const { isMobile } = useLayout();

  const moveItem = useCallback((dragIndex, hoverIndex) => {
    setItemsState((prevCards) => {
      const copyCards = [...prevCards];
      copyCards.splice(dragIndex, 1);
      copyCards.splice(hoverIndex, 0, prevCards[dragIndex]);
      setItems(copyCards);
      return copyCards;
    });
  }, []);

  const renderItem = useCallback((item, index) => {
    return (
      <Item
        key={`selected-${item.id}-${index}`}
        index={index}
        id={item.id}
        item={item}
        onRemove={removeItem}
        moveItem={moveItem}
      />
    )
  }, [moveItem, removeItem]);

  useEffect(() => { setItemsState(items) }, [items])

  return (
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
      {itemsState.map((item, i) => renderItem(item, i))}
    </DndProvider>
  )
};

const Item = ({ index, id, item, onRemove, moveItem }) => {
  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  const file = item.content_files[0].content_file;
  const duration = `${item.data.default_duration || file.data.duration} сек`;

  return (
    <div
      ref={ref}
      style={{ opacity, cursor: "move" }}
      data-handler-id={handlerId}
    >
      <div className="item-list__item">
        <div className="item-list__item-actions">
          <IconButton
            icon={<MdHorizontalRule size="1.4em" color="#FC6461" />}
            appearance="ghost"
            onClick={() => onRemove(index)}
          />
        </div>
        <div className="item-list__item-content">
          <FileItemComponent
            draggable={true}
            icon={<Avatar src={getThumbnail(item)} size="lg" />}
            title={item.name}
            subTitle={<><Tag>{convertBytes(file.size).mb}</Tag> <Tag>{duration}</Tag></>}
            wrapClassName=""
          />
        </div>
      </div>
    </div>
  )
};

export default DraggableFilesComponent;