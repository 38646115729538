import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import HeaderComponent from "components/HeaderComponent";
import StatComponent from "components/StatComponent";
import { fetchCampaigns } from "redux/slices/statisticsSlice";
import { CAMPAIGNS_STATES } from "constants";

const CampaignsOverviewComponent = ({ className }) => {
  const dispatch = useDispatch();
  const { campaigns } = useSelector((state) => state.statistics);

  const statData = [
    {
      percent: campaigns.length,
      strokeColor: "#7367F0",
      label: "Всего кампаний",
    },
    {
      percent: campaigns.filter(c => c.ad_campaign.state === CAMPAIGNS_STATES.running.name).length,
      strokeColor: CAMPAIGNS_STATES.running.color,
      label: CAMPAIGNS_STATES.running.label,
    },
    {
      percent: campaigns.filter(c => c.ad_campaign.state === CAMPAIGNS_STATES.finished.name).length,
      strokeColor: CAMPAIGNS_STATES.finished.color,
      label: CAMPAIGNS_STATES.finished.label,
    },
    {
      percent: campaigns.filter(c => c.ad_campaign.state === CAMPAIGNS_STATES.pending.name).length,
      strokeColor: CAMPAIGNS_STATES.pending.color,
      label: CAMPAIGNS_STATES.pending.label,
    },
  ];

  useEffect(() => {
    dispatch(fetchCampaigns());
  }, []);

  return (
    <div className={`${className}`}>
      <HeaderComponent title="Статистика кампаний" tag="h3" gutter={0} />
      <StatComponent data={statData} />
    </div>
  );
};

export default CampaignsOverviewComponent;
