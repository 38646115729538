import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  IconButton,
  Input,
  InputGroup,
  Avatar,
  Tag,
  Loader,
} from "rsuite";
import { MdSearch, MdAdd, MdHorizontalRule } from "react-icons/md";
import InfiniteScroll from "react-infinite-scroller";

import ErrorComponent from "components/ErrorComponent";
import InputFieldComponent from "components/forms/InputFieldComponent";
import FileItemComponent from "components/files/FileItemComponent";
import HeaderComponent from "components/HeaderComponent";
import { fetchFiles, getFiles, addFiles } from "redux/slices/filesSlice";
import { loadingStatus } from "helpers/fetcher";
import nextItems from "helpers/next-items";
import { convertBytes, getThumbnail } from "helpers/utils";

const FilesComponent = ({
  selectedFiles,
  onSelectFiles,
  selectedFolder,
  onRemoveFile,
  error,
}) => {
  const [filteredFiles, setFilteredFiles] = useState([]);
  const dispatch = useDispatch();
  const { files } = useSelector(state => state.files);
  const queryString = useRef()

  const handleFilterFiles = (val) => {
    const filteredVal = files.objects.filter(item => item.name.toLowerCase().includes(val.toLowerCase()));
    setFilteredFiles(filteredVal);
  };

  const onLoadMore = async () => {
    const _items = await nextItems(queryString.current, files.meta.offset, files.meta.limit, getFiles);
    if (_items.objects.length > 0) dispatch(addFiles(_items.objects));
  }

  useEffect(() => {
    if (files.objects && selectedFolder) setFilteredFiles(files.objects);
    else setFilteredFiles([]);
  }, [files, selectedFolder]);

  useEffect(() => {
    if (selectedFolder) {
      const queryObject = {
        requirements: JSON.stringify({ orientation: "landscape" }),
        odvertiser_id: selectedFolder,
      };
      queryString.current = `?${(new URLSearchParams(queryObject)).toString()}`;
      dispatch(fetchFiles(queryString.current));
    }
  }, [selectedFolder]);
 
  // useEffect(() => {
  //   dispatch(fetchFiles(`?${(new URLSearchParams(filesQueryObject)).toString()}`));
  // }, []);

  return (
    <div className="campaigns-form_content-items">

      <div className="campaigns-form_content-items-header">
        <InputFieldComponent>
          <InputGroup>
            <Input onChange={handleFilterFiles} placeholder="Поиск по имени" />
            <InputGroup.Button>
              <MdSearch />
            </InputGroup.Button>
          </InputGroup>
        </InputFieldComponent>
      </div>

      <HeaderComponent title="Выберите файлы" tag="h6" gutter={0} className="mb-0" />

      {(files.status !== loadingStatus.LOADING && files.objects.length === 0) && (
        <ErrorComponent message="У рекламодателя нет файлов" />
      )}
      {error ? <ErrorComponent message="Выберите файлы" /> : ""}

      <div className="campaigns-form_content-items-available">
        <InfiniteScroll
          initialLoad={false}
          threshold={0}
          hasMore={files.status !== loadingStatus.LOADING && files.objects.length < files.meta.total}
          loader={<p className="text-center"><Loader size="sm" /></p>}
          loadMore={onLoadMore}
          useWindow={false}
        >
          {files.status === loadingStatus.LOADING && "Загрузка..."}
          <div className="item-list">
            {filteredFiles.map((contentItem, index) => {
              const sum = selectedFiles.filter(s => s.id === contentItem.id).length;
              const file = contentItem.content_files[0].content_file;
              const duration = `${contentItem.data.default_duration || file.data.duration} сек`;
              return (
                <div key={`${file.id}-${index}`} className="item-list__item">
                  <div className="item-list__item-actions">
                    <IconButton
                      icon={<MdAdd size="1.4em" />}
                      appearance="ghost"
                      onClick={() => onSelectFiles(contentItem)}
                    />
                  </div>
                  <div className="item-list__item-content">
                    <FileItemComponent
                      badge={sum}
                      icon={<Avatar src={getThumbnail(contentItem)} size="lg" />}
                      title={contentItem.name}
                      subTitle={<><Tag>{convertBytes(file.size).mb}</Tag> <Tag>{duration}</Tag></>}
                      wrapClassName={null}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </InfiniteScroll>
      </div>
      <hr />
      <div className="campaigns-form_content-items-selected">
        {selectedFiles.map((contentItem, index) => {
          const file = contentItem.content_files[0].content_file;
          const duration = `${contentItem.data.default_duration || file.data.duration} сек`;
          return (
            <div key={`selected-${file.id}-${index}`} className="item-list__item">
              <div className="item-list__item-actions">
                <IconButton
                  icon={<MdHorizontalRule size="1.4em" color="#FC6461" />}
                  appearance="ghost"
                  onClick={() => onRemoveFile(index)}
                />
              </div>
              <div className="item-list__item-content">
                <FileItemComponent
                  icon={<Avatar src={getThumbnail(contentItem)} size="lg" />}
                  title={contentItem.name}
                  subTitle={<><Tag>{convertBytes(file.size).mb}</Tag> <Tag>{duration}</Tag></>}
                  wrapClassName={null}
                />
              </div>
            </div>
          )}
        )}
      </div>
    </div>
  );
};

export default FilesComponent;
