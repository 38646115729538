import { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Form,
  Button,
  FlexboxGrid,
  Schema,
  SelectPicker,
  TagPicker,
  Message,
  IconButton,
} from "rsuite";
import { MdClose, MdAdd } from "react-icons/md";

import InputFieldComponent from "components/forms/InputFieldComponent";
import HeaderComponent from "components/HeaderComponent";
import ErrorComponent from "components/ErrorComponent";
import CloseButtonComponent from "components/CloseButtonComponent";
import {
  fetchReboots,
  fetchRebootsPlayers,
  createReboots,
  putReboots,
} from "redux/slices/rebootsSlice";
import { nativeInputTimeFormatted } from "helpers/date";
import { rebootsFormInitialData } from "constants/form";

const { StringType } = Schema.Types;
const model = Schema.Model({
  name: StringType().isRequired("Обязательное поле"),
});

const RebootsSchedulesFormComponent = () => {
  const dispatch = useDispatch();
  const { playersTags } = useSelector(state => state.tags);
  const { players, formData } = useSelector(state => state.reboots);

  const formRef = useRef();
  const [formValue, setFormValue] = useState(rebootsFormInitialData);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [filteredTag, setFilteredTag] = useState();
  const [customErrors, setCustomErrors] = useState({ players: null, schedule: null });

  const handleSelectedPlayers = val => {
    setSelectedPlayers(val || []);
    setCustomErrors({ ...customErrors, players: null });
  };
  const handleChangeTime = (index, e) => {
    schedule[index].time = e.target.value;
    setSchedule([...schedule]);
  };
  const handleAddScheduleItem = () => {
    setSchedule([...schedule, { time: "00:00" }]);
    setCustomErrors({ ...customErrors, schedule: null });
  };
  const handleRemoveScheduleItem = index => {
    schedule.splice(index, 1);
    setSchedule([...schedule]);
  };
  const handleSubmit = async () => {
    const errors = [];
    if (selectedPlayers.length === 0) errors.players = "Выберите устройства";
    if (schedule.length === 0) errors.schedule = "Добавьте расписание";
    setCustomErrors(errors);

    const { id, ...restForm } = formValue;

    if (formRef.current.check() && Object.values(errors).filter(err => err).length === 0) {
      restForm.players = selectedPlayers;
      restForm.schedule = schedule.map(s => ({ hour: +s.time.split(":")[0], minute: +s.time.split(":")[1] }));
      if (id) await putReboots(id, restForm);
      else await createReboots(restForm);
      dispatch(fetchReboots());
    }
  };

  useEffect(() => {
    const { players: formPlayers, schedule: formSchedule, ...rest } = formData;
    const existingPlayers = formPlayers.map(({ id }) => id);
    const existingSchedule = formSchedule.map(s => ({ time: nativeInputTimeFormatted(s) }));
    setFormValue(rest);
    setSchedule(existingSchedule);
    setSelectedPlayers(existingPlayers);
  }, [formData]);

  useEffect(() => {
    const url = `?hide_children=1${filteredTag ? `&tag=${filteredTag}` : ""}`;
    dispatch(fetchRebootsPlayers(url));
  }, [dispatch, filteredTag]);

  return (
    <Form
      model={model}
      ref={formRef}
      onChange={setFormValue}
      formValue={formValue}
      fluid
    >
      <HeaderComponent
        title={`${formValue.id ? "Изменить" : "Создать"} расписание`}
        tag="h4"
        gutter={0}
      />

      <HeaderComponent
        title="Введите данные"
        tag="h6"
        gutter={0}
      />
      <InputFieldComponent name="name" label="Название" />
      <InputFieldComponent name="description" label="Описание" />

      <HeaderComponent
        title="Выберите устройства"
        tag="h6"
        gutter={0}
      />
      {(playersTags.length > 0) ? (
        <Form.Group>
          <FlexboxGrid>
            <FlexboxGrid.Item colspan={5}>
              <Form.ControlLabel>по тегу:</Form.ControlLabel>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={19}>
              <SelectPicker
                data={playersTags.map(({ id, name }) => ({ label: name, value: id }))}
                onChange={setFilteredTag}
                block
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Form.Group>
      ) : (
        <Message type="warning">Ни один тег устройства не создан</Message>
      )}

      {(players.length > 0) ? (
        <Form.Group>
          <Form.ControlLabel>Доступные устройства</Form.ControlLabel>
          <TagPicker
            data={players.map(({ id, name }) => ({ label: name, value: id }))}
            onChange={handleSelectedPlayers}
            value={selectedPlayers}
            block
          />
          {customErrors.players ? <ErrorComponent message={customErrors.players} /> : ""}
        </Form.Group>
      ) : (
        <Message showIcon type="warning">Устройств не найдено</Message>
      )}

      <HeaderComponent
        title="Создайте расписание"
        tag="h6"
        gutter={0}
      />
      {schedule.map((s, index) => (
        <Form.Group key={`${s.time}${s.value}${index}`}>
          <FlexboxGrid>
            <FlexboxGrid.Item colspan={10}>
              <input
                type="time"
                className="rs-input"
                defaultValue={schedule[index].time}
                onBlur={e => handleChangeTime(index, e)}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={4}>
              <IconButton
                icon={<MdClose />}
                onClick={() => handleRemoveScheduleItem(index)}
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Form.Group>
      ))}
    
      <Form.Group>
        <IconButton
          icon={<MdAdd />}
          onClick={handleAddScheduleItem}
          appearance="link"
        >
          Добавить время
        </IconButton>
        {customErrors.schedule ? <ErrorComponent message={customErrors.schedule} /> : ""}
      </Form.Group>

      <Form.Group>
        <Button appearance="primary" onClick={handleSubmit}>Сохранить</Button>
      </Form.Group>
    </Form>
  );
};

export default RebootsSchedulesFormComponent;
