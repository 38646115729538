import {
  Grid,
  Row,
  Col,
  Progress,
} from "rsuite";

const StatComponent = ({ data }) => (
  <Grid fluid>
    <Row>
      {data.map(({ percent, strokeColor, label }) => (
        <Col xl={24} xs={6} key={`${percent}-${strokeColor}`}>
          <div className="stat-item">
            <div className="stat-item__circle">
              <Progress.Circle
                key={strokeColor}
                percent={percent}
                strokeColor={strokeColor}
                strokeWidth={12}
                trailWidth={12}
                showInfo={false}
              />
              <div className="stat-item__value">{percent}</div>
            </div>
            <div className="stat-item__label">{label}</div>
          </div>
        </Col>
      ))}
    </Row>
  </Grid>
);

export default StatComponent;
