import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Form,
  ButtonToolbar,
  Button,
  Panel,
  FlexboxGrid,
  Schema,
} from "rsuite";

import ErrorComponent from "components/ErrorComponent";
import InputFieldComponent from "components/forms/InputFieldComponent";
import useUser from "hooks/useUser";
import { postLogin } from "redux/slices/loginSlice";
import { loadingStatus } from "helpers/fetcher";
import { loginFormInitialData } from "constants/form";
import { auth } from "constants/urls";

const { StringType } = Schema.Types;
const model = Schema.Model({
  email: StringType()
    .isEmail("Введите валидный e-mail")
    .isRequired("Обязательное поле"),
  password: StringType()
    .minLength(6, "Длина пароля не менее 6 символов")
    .isRequired("Обязательное поле"),
});

const Login = () => {
  const formRef = useRef();
  const [formValue, setFormValue] = useState(loginFormInitialData);
  const dispatch = useDispatch();
  const { user, status, error } = useSelector(state => state.login);
  const navigate  = useNavigate();
  const { accessToken, setUser } = useUser();

  const handleSubmit = () => {
    if (!formRef.current.check()) {
      return;
    }
    dispatch(postLogin(formValue));
  };

  const handleForgot = () => navigate(auth.FORGOT);

  // сохраняем токен
  useEffect(() => {
    if (status === loadingStatus.SUCCEEDED && user.access_token) {
      setUser(user);
    }
  }, [navigate, setUser, status, user]);

  // переадресуем, если есть токен
  useEffect(() => {
    if (accessToken) {
      // navigate("/");
      window.location = "/";
      return;
    }
  }, [navigate, accessToken]);

  return (
    <FlexboxGrid justify="center">
      <FlexboxGrid.Item
        className="caps-content width-100"
        style={{ maxWidth: 500 }}
      >
        <Panel
          header="Вход в кабинет Capslook"
          bordered
        >
          <Form
            model={model}
            ref={formRef}
            onChange={setFormValue}
            formValue={formValue}
            fluid
          >
            <InputFieldComponent name="email" label="Email" />
            <InputFieldComponent name="password" type="password" label="Пароль" />
            <Form.Group>
              <ButtonToolbar>
                <Button appearance="primary" onClick={handleSubmit}>Войти</Button>
                <Button appearance="link" onClick={handleForgot}>Забыли пароль?</Button>
              </ButtonToolbar>
            </Form.Group>
            {error && <ErrorComponent message={error} />}
          </Form>
        </Panel>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default Login;
