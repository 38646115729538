import { useNavigate } from "react-router";
import { Grid, Row, Col } from "rsuite";
import { MdDownload, MdManageAccounts, MdOutlineSettings, MdSecurity, MdSupervisorAccount } from "react-icons/md";

import HeaderComponent from "components/HeaderComponent";
import useLayout from "hooks/useLayout";
import { settings } from "constants/urls";

const Settings = () => {
  const navigate  = useNavigate();
  const { gridBreakPoints } = useLayout();

  return (
    <>
      <HeaderComponent title="Настройки" />
      <div className="caps-content">
        <Grid fluid>
          <Row>
            <Col {...gridBreakPoints}>
              <div
                className="button-card mb-20"
                onClick={() => navigate(settings.PROFILE)}
              >
                <MdManageAccounts size="5em" />
                <p>Профиль</p>
              </div>
            </Col>
            <Col {...gridBreakPoints}>
              <div
                className="button-card mb-20"
                onClick={() => navigate(settings.PASSWORD)}
              >
                <MdSecurity size="5em" />
                <p>Изменить пароль</p>
              </div>
            </Col>
            <Col {...gridBreakPoints}>
              <div
                className="button-card mb-20"
                onClick={() => navigate(settings.ACCOUNTS)}
              >
                <MdSupervisorAccount size="5em" />
                <p>Учетные записи</p>
              </div>
            </Col>
            {/* <Col {...gridBreakPoints}>
              <div
                className="button-card mb-20"
                onClick={() => navigate(settings.ROLES)}
              >
                Права и роли
              </div>
            </Col> */}
            <Col {...gridBreakPoints}>
              <div
                className="button-card mb-20"
                onClick={() => navigate(settings.SYSTEM)}
              >
                <MdOutlineSettings size="5em" />
                <p>Системные настройки</p>
              </div>
            </Col>
            <Col {...gridBreakPoints}>
              <div
                className="button-card mb-20"
                onClick={() => navigate(settings.DOWNLOADS)}
              >
                <MdDownload size="5em" />
                <p>Загрузки</p>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    </>
  );
};

export default Settings;
