import { memo, useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "rsuite";

import HeaderComponent from "components/HeaderComponent";
import DrawerComponent from "components/DrawerComponent";
import PlayersSideNavComponent from "components/player/PlayersSideNavComponent";
// import RebootsSchedulesOverviewComponent from "components/player/reboots/RebootsSchedulesOverviewComponent";
import RebootsSchedulesFormComponent from "components/player/reboots/RebootsSchedulesFormComponent";
import PlayerExportComponent from "components/player/PlayerExportComponent";

import { fetchReboots, fillForm, addReboots, getReboots } from "redux/slices/rebootsSlice";
import useLayout from "hooks/useLayout";
import { rebootsFormInitialData } from "constants/form";
import Mobile from "./Mobile";
import Desktop from "./Desktop";
import nextItems from "helpers/next-items";
import InfiniteScroll from "react-infinite-scroller";
import { loadingStatus } from "helpers/fetcher";

const DRAWERS = { export: "export" };

const contentBarContents = {
  REBOOTS_OVERVIEW: "reboots_overview",
  REBOOTS_FORM: "reboots_form",
};

const Reboots = () => {
  const [currentContent, setCurrentContent] = useState(contentBarContents.REBOOTS_OVERVIEW);
  const [drawerType, setDrawerType] = useState();

  const navigate  = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector(state => state.reboots);
  const { isDesktop } = useLayout();

  const SideBar = memo(() => <PlayersSideNavComponent handleExportAction={handleExportDrawerOpen} />);

  const handleFormButton = (_, rebootsId) => {
    dispatch(fillForm(rebootsFormInitialData));
    setCurrentContent(contentBarContents.REBOOTS_FORM);
    navigate(`/players/reboots${rebootsId ? `/${rebootsId}` : ""}`);
  };

  const handleDrawerClose = () => setDrawerType();

  const handleExportDrawerOpen = () => setDrawerType(DRAWERS.export);

  const onLoadMore = async () => {
    const _items = await nextItems("", data.meta.offset, data.meta.limit, getReboots);
    if (_items.objects.length > 0) dispatch(addReboots(_items.objects));
  }

  const contentComponents = useMemo(() => ({
    [contentBarContents.REBOOTS_OVERVIEW]: null,
    [contentBarContents.REBOOTS_FORM]: <RebootsSchedulesFormComponent />,
  }), []);


  useEffect(() => {
    dispatch(fetchReboots());
  }, [dispatch]);

  const actions = { handleFormButton, onContentbarClose: () => setCurrentContent(null) };
  
  return (
    <>
      <HeaderComponent title="Расписания перезагрузок" />
      <InfiniteScroll
        initialLoad={false}
        threshold={0}
        hasMore={data.status !== loadingStatus.LOADING && data.objects.length < data.meta.total}
        loader={<p className="text-center"><Loader size="md" /></p>}
        loadMore={onLoadMore}
      >
        {isDesktop ? (
          <Desktop
            data={data}
            sideBar={<SideBar />}
            contentBar={contentComponents[currentContent]}
            {...actions}
          />
        ) : (
          <Mobile
            data={data}
            sideBar={<SideBar />}
            contentBar={contentComponents[currentContent]}
            {...actions}
          />
        )}
      </InfiniteScroll>
      <DrawerComponent
        open={!!drawerType}
        onClose={handleDrawerClose}
        placement="left"
      >
        {drawerType === DRAWERS.export && <PlayerExportComponent />}
      </DrawerComponent>
    </>
  );

}

export default Reboots;
