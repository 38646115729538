
const nativeInputTimeFormatted = s => (
  `${s.hour.toString().padStart(2, "0")}:${s.minute.toString().padStart(2, "0")}`
);

const stringToDate = s => {
  if (isNaN(new Date(s))) {
    const pieces = s.split(" ");
    pieces[0] = pieces[0].split(".").reverse();
    pieces[0][0] = pieces[0][0].padStart(4, "20");
    pieces[0] = pieces[0].join("-");
    return new Date(`${pieces[0]} ${pieces[1]}`);
  }
  return new Date(s);
};

// https://momentjs.com/docs/#/parsing/string-format/
const formatDate = (date, s = "-", operation) => {
  const isHuman = s !== "-";
  const _d = date ? stringToDate(date) : new Date();

  if (operation === "+timezone") {
    // _d.setTime(_d.getTime() + (3*60*60*1000));
    // UTC+3 -> -180
    _d.setTime(_d.getTime() - (_d.getTimezoneOffset() * 60 * 1000));
  }

  const month = `${`${_d.getMonth() + 1}`.padStart(2, 0)}`;
  let day = `${`${_d.getDate()}`.padStart(2, 0)}`;
  const time = `${`${_d.getHours()}`.padStart(2, 0)}:${`${_d.getMinutes()}`.padStart(2, 0)}`;

  if (operation === "+1days") day = `${`${_d.getDate() + 1}`.padStart(2, 0)}`;

  const obj = {
    //YYYY-M-D
    full: `${_d.getFullYear()}${s}${month}${s}${day}`,
    //YY-M-D
    short: `${_d.getFullYear().toString().substr(-2)}${s}${month}${s}${day}`,
    //YY-M-D H:m
    full_datetime: `${_d.getFullYear()}${s}${month}${s}${day} ${time}`,
    //H:m
    time,
  };
  if (isHuman) {
    obj.full = obj.full.split(s).reverse().join(s);
    obj.short = obj.short.split(s).reverse().join(s);
    obj.full_datetime = `${obj.full} ${time}`;
  }
  return obj;
}

const ruWeek = w => {
  const d = ['','Пн','Вт','Ср','Чт','Пт','Сб','Вс']
  return w ? d[w] : d.filter(d => d)
};

const ruFullWeek = w => {
  const d = ['','Понедельник','Вторник','Среда','Четверг','Пятница','Суббота','Воскресенье']
  return w ? d[w] : d.filter(d => d)
};

const ruMonth = m => {
  const d = ['','Январь','Февраль','Март','Апрель','Май','Июнь','Июля','Август','Сентябрь','Октябрь','Ноябрь','Декабрь']
  return m ? d[m] : d.filter(d => d)
};

export {
  nativeInputTimeFormatted,
  formatDate,
  ruWeek,
  ruMonth,
  ruFullWeek,
};
