import { useSelector } from "react-redux";
import { Message } from "rsuite";

import HeaderComponent from "components/HeaderComponent";
import LoaderComponent from "components/LoaderComponent";
import ErrorComponent from "components/ErrorComponent";
import CampaignsTotalTableComponent from "components/campaign/CampaignsFormComponent/CampaignsTotalTableComponent";
import { loadingStatus } from "helpers/fetcher";
import { formatDate } from "helpers/date";

const CampaignsInfoComponent = () => {
  const {
    data: campaign, status, error, playbackItems, campaignPlayers,
  } = useSelector(state => state.campaign);
  const { data: categories } = useSelector(state => state.categories);

  if (status !== loadingStatus.SUCCEEDED) {
    return <LoaderComponent />;
  }

  if (status === loadingStatus.FAILED) {
    return <ErrorComponent message={error} />;
  }

  const startDatetime = formatDate(campaign.revision.start_datetime, ".");
  const endDatetime = formatDate(campaign.revision.end_datetime, ".");

  return (
    <div>
      <HeaderComponent title={campaign.name} tag="h3" gutter={0} />

      <HeaderComponent title="Категория" tag="h6" className="inner-header" />
      {categories.find(cat => cat.id === campaign.category_id)?.name || (
        <Message showIcon type="warning">Кампания не добавлена в категорию</Message>
      )}
      <br />

      <HeaderComponent title="Данные кампании" tag="h6" className="inner-header" />
      <CampaignsTotalTableComponent
        name={campaign.name}
        start={startDatetime.short}
        start_time={startDatetime.time}
        end={endDatetime.short}
        end_time={endDatetime.time}
        selectedFolders={[{ label: campaign.advertiser.name, value: campaign.advertiser.id }]}
        interval={3600 / campaign.revision.interval}
        previewPlayersLenght={campaignPlayers?.length}
        filesLenght={playbackItems?.length}
        duration={campaign.revision.duration}
        description={campaign.description}
      />
    </div>
  );
};

export default CampaignsInfoComponent;
