import { FlexboxGrid } from "rsuite";

import FoldersListComponent from "components/files/FoldersListComponent";
import FilesListComponent from "components/files/FilesListComponent";
import ButtonCreateComponent from "components/buttons/ButtonCreateComponent";
import DesktopLayout from "components/layouts/DesktopLayout";
import useLayout from "hooks/useLayout";

const Desktop = ({
  data: { folders, files },
  folderItem,
  contentBar,
  foldersFilter,

  handleFolderDetailButton,
  handleFolderCreateButton,
  handleFolderEditButton,
  handleFolderDeleteButton,

  handleFileDetailButton,
  handleFilesCreateButton,
  handleFilesEditButton,
  handleFilesDeleteButton,

  onContentbarClose,
}) => {
  const { width } = useLayout();

  return (
    <DesktopLayout contentBar={contentBar} onContentbarClose={onContentbarClose}>
      <FlexboxGrid className="caps-list-wrap">
        <FlexboxGrid.Item colspan={width > 1800 ? 7 : (width < 1280 ? 24 : 9)}>
          <div className="caps-list">
            <div className="item-list__item">
              <div className="item-list__item-content-icon mr-20">
                {foldersFilter}
              </div>
              <div className="item-list__item-content">
                <ButtonCreateComponent size="sm" onClick={handleFolderCreateButton}>
                  Создать рекламодателя
                </ButtonCreateComponent>
              </div>
            </div>
            <FoldersListComponent
              items={folders.objects}
              status={folders.status}
              error={folders.error}
              onClick={handleFolderDetailButton}
              onEdit={handleFolderEditButton}
              onRemove={handleFolderDeleteButton}
            />
          </div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={width > 1800 ? 17 : (width < 1280 ? 24 : 15)}>
          <div className="caps-list files-list">
            <div className="item-list__item">
              <div className="item-list__item-content">
                <ButtonCreateComponent size="sm" onClick={handleFilesCreateButton}>
                  Добавить файлы
                </ButtonCreateComponent>
              </div>
            </div>
            <FilesListComponent
              items={files.objects}
              folderItem={folderItem}
              status={files.status}
              error={files.error}
              onClick={handleFileDetailButton}
              onEdit={handleFilesEditButton}
              onRemove={handleFilesDeleteButton}
            />
          </div>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </DesktopLayout>
  );
};

export default Desktop;
