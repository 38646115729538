import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  InputGroup,
  Input,
  Tag,
} from "rsuite";
import { MdClose } from "react-icons/md";

import MenuItemsComponent from "./MenuItemsComponent";
import { robotNotificationsDrawerToggle, searchAction, tagDrawerToggle } from "redux/slices/layoutSlice";
import { fetchPlayersTags, fetchContentTags } from "redux/slices/tagsSlice";
import {
  fetchRobotNotifications,
  fetchManualNotifications,
  fetchSyncNotifications,
  patchManualNotification,
  postSyncData,
} from "redux/slices/notificationsSlice";
import usePopup from "hooks/usePopup";
import useLayout from "hooks/useLayout";
import { headerNav } from "helpers/navs";
import { NOTIFICATIONS_INTERVAL, iconStyle } from "constants";

const NavbarComponent = () => {
  const searchRef = useRef();
  const toastersRef = useRef([]);
  const [activeKey, setActiveKey] = useState(headerNav.left[0].action);
  const [displaySearch, setDisplaySearch] = useState();

  const navigate  = useNavigate();
  const dispatch = useDispatch();
  const { robotNotifications, manualNotifications, syncNotifications } = useSelector(state => state.notifications);
  const { searchState } = useSelector(state => state.layout);

  const { messageToaster, responseToaster } = usePopup();
  const { isDesktop } = useLayout();

  const handleSyncNotificationsAction = async () => {
    const res = await postSyncData();
    if (res.ok) fetchSyncNotifications();
    responseToaster(res);
  };

  const openDisplaySearch = () => {
    setDisplaySearch(true);
  };

  const closeDisplaySearch = () => {
    setDisplaySearch(false);
    dispatch(searchAction(""));
  };

  const handleSearch = keyword => {
    dispatch(searchAction(keyword));
  };

  const handleManualNotificationsClose = id => {
    patchManualNotification(id, { "dismissed": true });
  };

  const handleNavigation = (action) => {
    const navActions = {
      "sync-notifications-action": handleSyncNotificationsAction,
      "robot-notifications-action": () => dispatch(robotNotificationsDrawerToggle()),
      "tag-drawer-action": () => dispatch(tagDrawerToggle()),
      "entity-search-action": openDisplaySearch,
    };

    if (navActions && navActions[action]) {
      navActions[action]();
    } else {
      navigate(action);
    }
    setActiveKey(action);
  };

  useEffect(() => {
    dispatch(fetchPlayersTags());
    dispatch(fetchContentTags());

    const intervalId = setInterval(() => {
      dispatch(fetchRobotNotifications());
      dispatch(fetchManualNotifications());
      dispatch(fetchSyncNotifications());
    }, NOTIFICATIONS_INTERVAL);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  useEffect(() => {
      manualNotifications.filter(item => item.active).forEach((item, index) => {
        if (toastersRef.current.findIndex(it => JSON.stringify(it) === JSON.stringify(item)) === -1) {
          setTimeout(() => {
            messageToaster({
              message: (
                <div>
                  <div dangerouslySetInnerHTML={{__html: item.body.text}} />
                  {item.body.tag.text ? (
                    <Tag
                      className={item.body.tag.color ? `white ${item.body.tag.color}` : ""}
                    >
                      {item.body.tag.text}
                    </Tag>
                  ) : null}
                </div>
              ),
              type: "info",
              placement: "topEnd",
              duration: 0,
              onClose: () => handleManualNotificationsClose(item.id),
            });
            toastersRef.current.push(item);
          }, 100 * index);
        }
      });
    // }
  }, [manualNotifications]);

  useEffect(() => {
    if (displaySearch) searchRef.current.focus();
  }, [displaySearch]);

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    if (search.get("name")) {
      dispatch(searchAction(search.get("name")));
      setDisplaySearch(true);
    }
  }, []);

  return (
    <>
      <div className="caps-navbar">
        {displaySearch ? (
          <InputGroup inside size="lg">
            <Input
              ref={searchRef}
              value={searchState}
              onChange={handleSearch}
              placeholder="Поиск"
            />
            <InputGroup.Button onClick={closeDisplaySearch}>
              <MdClose />
            </InputGroup.Button>
          </InputGroup>
        ) : (
          <MenuItemsComponent
            isDesktop={isDesktop}
            headerNav={headerNav}
            activeKey={activeKey}
            syncNotifications={syncNotifications}
            robotNotifications={robotNotifications}
            headerIconSize={iconStyle.HEADER_ICON_SIZE_SM}
            onNavigation={handleNavigation}
          />
        )}
      </div>
      {!isDesktop ? (
        <div className="caps-navbar-submenu mt-20 mb-20">
          <div className="carousel-container hide-scroll">
            <ul className="carousel-list">
              {headerNav.left.map(({ title, action, icon, id }) => (
                <li className="link" key={id} onClick={() => handleNavigation(action)}>
                  {icon}
                  <span className="ml-10">{title}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default NavbarComponent;
