import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Content,
  Form,
  ButtonToolbar,
  Button,
  Panel,
  FlexboxGrid,
  Schema,
  Message,
} from "rsuite";

import ErrorComponent from "components/ErrorComponent";
import InputFieldComponent from "components/forms/InputFieldComponent";
import { postReset } from "redux/slices/loginSlice";
import { loadingStatus } from "helpers/fetcher";
import { auth } from "constants/urls";

const { StringType } = Schema.Types;
const model = Schema.Model({
  password: StringType()
    .minLength(6, "Длина пароля не менее 6 символов")
    .isRequired("Обязательное поле"),
  confirmPassword: StringType()
    .addRule((value, data) => {
      if (value !== data.password) {
        return false;
      }
      return true;
    }, "Пароли не совпадают")
    .isRequired("Обязательное поле")
});

const Reset = () => {
  const formRef = useRef();
  const [formValue, setFormValue] = useState({ password: "", confirmPassword: "" });
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { reset: { status, error } } = useSelector(state => state.login);

  const handleGoToLogin = useCallback(() => navigate(auth.LOGIN), [navigate]);

  const handleSubmit = () => {
    const token = pathname.split("/")[2];

    if (!token || !formRef.current.check()) {
      return;
    }
    dispatch(postReset({
      password: formValue.password,
      token,
    }));
  };

  useEffect(() => {
    if (status === loadingStatus.SUCCEEDED) handleGoToLogin();
  }, [status, handleGoToLogin]);

  
  return (
    <Container>
      <Content>
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item colspan={12}>
            <Panel header={<h3>Восстановить пароль</h3>} bordered>
              <Message showIcon type="info">
                Пароль может содержать символы латинского алфавита, кроме пробела. Длина пароля от 6 символов
              </Message>
              <br/>
              <Form
                model={model}
                ref={formRef}
                onChange={setFormValue}
                formValue={formValue}
                fluid
              >
                <InputFieldComponent name="password" type="password" label="Новый пароль" />
                <InputFieldComponent name="confirmPassword" type="password" label="Повторите пароль" />
                <Form.Group>
                  <ButtonToolbar>
                    <Button appearance="link" onClick={handleGoToLogin}>Войти в систему</Button>
                    <Button appearance="primary" onClick={handleSubmit}>Сохранить</Button>
                  </ButtonToolbar>
                </Form.Group>
                {error && <ErrorComponent message={error} />}
              </Form>
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Content>
    </Container>
  );
};

export default Reset;
