import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tag, List } from "rsuite";

import { fetchRobotNotifications } from "redux/slices/notificationsSlice";

const RobotNotificationsComponent = () => {
  const dispatch = useDispatch();
  const { robotNotifications } = useSelector(state => state.notifications);

  useEffect(() => {
      dispatch(fetchRobotNotifications());
  }, [dispatch]);

  return (
    <List>
      {robotNotifications.map((item) => (
        <List.Item key={item.id}>
          <h4 className="mb-10">{item.title.ru}</h4>
          {item.players.map(({ id, name }) => (
            <Tag key={id} color="red">{name}</Tag>
          ))}
        </List.Item>
      ))}
    </List>
  );
};

export default RobotNotificationsComponent;
