import { Col, FlexboxGrid } from "rsuite";
import { useDispatch } from "react-redux";

import { fillForm, fetchReports, deleteReports } from "redux/slices/reportsSlice";
import LoaderComponent from "components/LoaderComponent";
import ErrorComponent from "components/ErrorComponent";
import CardComponent from "components/CardComponent";
import useLayout from "hooks/useLayout";
import usePopup from "hooks/usePopup";
import { loadingStatus } from "helpers/fetcher";
import { reportsFormInitialData } from "constants/form";
import { DAYS_LABEL } from "constants";


const ReportsGridComponent = ({
  items,
  status,
  error,
  buttonCreate,
  onForm,
}) => {
  const { gridBreakPoints } = useLayout();

  if (status !== loadingStatus.SUCCEEDED) {
    return <LoaderComponent />;
  }

  if (status === loadingStatus.FAILED) {
    return <ErrorComponent message={error} />;
  }

  return (
    <FlexboxGrid className="grid-items" align="top">
      {buttonCreate && (
        <FlexboxGrid.Item as={Col} {...gridBreakPoints}>
          {buttonCreate}
        </FlexboxGrid.Item>
      )}
      {items.map(item => (
        <FlexboxGrid.Item as={Col} {...gridBreakPoints} key={`${item.id}`}>
          <ReportItemComponent
            id={item.id}
            name={item.name}
            description={item.description}
            players={item.players}
            schedule={item.schedule}
            onForm={onForm}
          />
        </FlexboxGrid.Item>
      ))}
    </FlexboxGrid>
  );
};

const ReportItemComponent = ({
  id,
  name,
  description,
  players,
  schedule,
  onForm,
}) => {
  const dispatch = useDispatch();
  const { responseToaster } = usePopup();

  const handleEdit = () => {
    onForm(null, id);
    dispatch(fillForm({
      ...reportsFormInitialData,
      id,
      name,
      description,
      players,
      schedule,
    }));
  };

  const handleDelete = async () => {
    const res = await deleteReports(id);
    responseToaster(res);
    dispatch(fetchReports());
  };
 
  const menuItems = [
    { key: 2, label: "Удалить", action: handleDelete },
  ];

  const menuItemSelectHandler = eventKey => {
    menuItems.find(it => it.key === eventKey).action();
  };

  return (
    <CardComponent
      onClick={handleEdit}
      title={name}
      subTitle={`Устройств: ${players.length}`}
      bottom={
        <div className="carousel-container">
          <ul className="carousel-list">
            {Object.keys(schedule).map((d, index) => (
              <li key={index} className="bgm-blue white">
                {DAYS_LABEL[d]}
                <div className="text-center">
                  {`${schedule[d].on || ""} - ${schedule[d].off || ""}`}
                </div>
              </li>
            ))}
          </ul>
        </div>
      }
      menuItems={menuItems}
      menuItemSelect={menuItemSelectHandler}
    />
  );
};

export default ReportsGridComponent;
