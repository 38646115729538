const MAX_UPLOAD_FILESIZE = 500 * 1024 * 1024;
const MAX_INTERVAL_RATIO = 1.15;
const MIN_DURATION = 5;
const NOTIFICATIONS_INTERVAL = 5000;

const CAMPAIGNS_STATES = {
  running: { name: "running", label: "Запущенные", color: "green" },
  pending: { name: "pending", label: "В ожидании", color: "yellow" },
  finished: { name: "finished", label: "Завершенные", color: "red" },
  deleted: { name: "deleted", label: "Удаленные", color: "gray" },
};

const CARD_BP = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 8 };
const CARD_WITH_BAR_BP = { xl: 12, xxl: 8 };

const iconStyle = {
  HEADER_ICON_SIZE: "1.7em",
  HEADER_ICON_SIZE_SM: "1.3em",
};

const DAYS_LABEL = {
  MON: "Пн",
  TUE: "Вт",
  WED: "Ср",
  THU: "Чт",
  FRI: "Пт",
  SAT: "Сб",
  SUN: "Вс",
};

const NO_IMAGE_URL = process.env.REACT_APP_NO_IMAGE_URL;

// PLAYLIST_TYPE = {
// }

export {
  MAX_UPLOAD_FILESIZE,
  MAX_INTERVAL_RATIO,
  MIN_DURATION,
  CARD_BP,
  CARD_WITH_BAR_BP,
  NOTIFICATIONS_INTERVAL,
  CAMPAIGNS_STATES,
  iconStyle,
  DAYS_LABEL,
  NO_IMAGE_URL,
};
