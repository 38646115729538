import { useState } from "react";
import { Button, DateRangePicker } from "rsuite";

import { fetchCampaignsReport } from "redux/slices/campaignsSlice";
import { formatDate } from "helpers/date";
import { downloadFromLink } from "helpers/utils";

const CampaignExportComponent = () => {
  const [exportDateRange, setExportDateRange] = useState();

  const handleExportDownload = async () => {
    if (exportDateRange) {
      const [s, e] = [formatDate(exportDateRange[0], "-").full, formatDate(exportDateRange[1], "-").full];
      const xlsxBlob = await fetchCampaignsReport(`?start=${s}&end=${e}`);
      const url = window.URL.createObjectURL(new Blob([xlsxBlob]));
      downloadFromLink(url, `Campaigns-${s}-${e}.xlsx`);
    }
  };

  return (
    <>
      <h6>Экспорт кампаний</h6>
      <hr />
      <h6 className="mb-20">Выберите интервал</h6>
      <DateRangePicker value={exportDateRange} onChange={setExportDateRange} />
      <hr />
      <Button appearance="primary" onClick={handleExportDownload}>Скачать</Button>
    </>
  );
};

export default CampaignExportComponent;
