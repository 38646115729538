import { memo, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Form,
  ButtonToolbar,
  Button,
  Panel,
  Schema,
  SelectPicker,
  Radio,
  RadioGroup,
  Uploader,
  Avatar,
  FlexboxGrid,
} from "rsuite";

import InputFieldComponent from "components/forms/InputFieldComponent";
import HeaderComponent from "components/HeaderComponent";
import usePopup from "hooks/usePopup";
import useUser from "hooks/useUser";
import { SERVER_URL, getHeaders } from "helpers/fetcher";
import { buildFlatFromDeepObject, buildDeepObjectFromFlat } from "helpers/utils";
import { fetchProfile, putProfile } from "redux/slices/settingsSlice";
import { profileFormInitialData } from "constants/form";
import { content } from "constants/endpoints";

const { StringType } = Schema.Types;
const model = Schema.Model({
  email: StringType().isEmail("Введите валидный e-mail").isRequired("Обязательное поле"),
  "profile.first_name": StringType().isRequired("Обязательное поле"),
  "profile.last_name": StringType().isRequired("Обязательное поле"),
});

const Profile = () => {
  const formRef = useRef();
  const [formValue, setFormValue] = useState(profileFormInitialData);
  const dispatch = useDispatch();
  const { profile } = useSelector(state => state.settings);
  const { responseToaster } = usePopup();
  const { user, setUser } = useUser();

  const handleUploadFile = (href) => {
    setFormValue({
      ...formValue,
      "profile.avatar": href,
    });
  };

  const handleSettingsRadio = (name, val) => {
    setFormValue({
      ...formValue,
      [name]: val,
    });
  };

  const handleSubmit = async () => {
    if (!formRef.current.check()) {
      return;
    }
    const payload = buildDeepObjectFromFlat(formValue);
    const res = await putProfile(payload);
    setUser({
      ...user,
      profile: {
        ...user.profile,
        email: payload.email,
        profile: payload.profile,
      }
    });
    responseToaster(res);
  };

  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch]);

  useEffect(() => {
    if (profile.profile) {
      setFormValue({
        email: profile.email,
        "profile.avatar": profile.profile.avatar,
        "profile.first_name": profile.profile.first_name,
        "profile.last_name": profile.profile.last_name,
        language: profile.language,
        ...buildFlatFromDeepObject({ notifications_settings: profile.notifications_settings }),    
      });
    }
  }, [profile]);

  const SettingRadioGroup = memo(({ label, name }) => (
    <FlexboxGrid className="setting-radio-group">
      <FlexboxGrid.Item colspan={9}>{label}</FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={15}>
        <RadioGroup
          inline
          name={name}
          value={formValue[name]}
          onChange={val => handleSettingsRadio(name, val)}
          className="space-around"
        >
          <Radio value="immediately" />
          <Radio value="hourly" />
          <Radio value="never" />
        </RadioGroup>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  ));

  return (
    <>
      <HeaderComponent title="Настройки" />
      <div className="caps-content">
        <Panel header={<h3>Настройки пользователя</h3>} bordered>
          <br/>
          <Form
            model={model}
            ref={formRef}
            onChange={setFormValue}
            formValue={formValue}
            fluid
          >
            <FlexboxGrid align="middle" className="mb-20">
              <FlexboxGrid.Item>
                <Avatar
                  className="mr-20"
                  size="lg"
                  circle
                  src={formValue["profile.avatar"]}
                />
              </FlexboxGrid.Item>
              <FlexboxGrid.Item>
                <Uploader
                  action={`${SERVER_URL}${content.FOLDERS}/logo`}
                  headers={getHeaders()}
                  accept="image/jpeg, image/png"
                  fileListVisible={false}
                  onSuccess={handleUploadFile}
                >
                  <Button>Выбрать изображение профиля</Button>
                </Uploader>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <InputFieldComponent name="email" label="Email" />
            <InputFieldComponent name="profile.first_name" label="Имя" />
            <InputFieldComponent name="profile.last_name" label="Фамилия" />
            <InputFieldComponent
              accepter={SelectPicker}
              name="language"
              label="Язык системы"
              data={[{ label: "Русский", value: "ru" }, { label: "Английский", value: "en" }]}
              cleanable={false}
              searchable={false}
              block
            />

            <HeaderComponent tag="h3" title="Настройка уведомлений" gutter={0} />
            <FlexboxGrid className="setting-radio-header">
              <FlexboxGrid.Item colspan={9}>Уведомление</FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={5} className="text-center">Немедленно</FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={5} className="text-center">Раз в час</FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={5} className="text-center">Никогда</FlexboxGrid.Item>
            </FlexboxGrid>
            <SettingRadioGroup
              label="Устройства не играют"
              name="notifications_settings.players_not_playing"
            />
            <SettingRadioGroup
              label="У устройств заканчивается место"
              name="notifications_settings.players_insufficient_free_space"
            />
            <SettingRadioGroup
              label="Устройства офлайн"
              name="notifications_settings.players_offline"
            />
            <SettingRadioGroup
              label="Устройства не синхронизированы"
              name="notifications_settings.players_not_synchronized"
            />
            <SettingRadioGroup
              label="У устройств нет контента"
              name="notifications_settings.players_no_content_now"
            />
            <SettingRadioGroup
              label="У устройств нет контента 2 дня"
              name="notifications_settings.players_no_content_2days"
            />
            <SettingRadioGroup
              label="Все лицензии плееров использованы"
              name="notifications_settings.used_players_count_overflow"
            />
            <SettingRadioGroup
              label="Заканчиваются лицензии плееров"
              name="notifications_settings.used_players_count_reaching_overflow"
            />
            <SettingRadioGroup
              label="У плееров устаревшая версия"
              name="notifications_settings.players_outdated_version"
            />
            <SettingRadioGroup
              label="Устройства играют с пропусками"
              name="notifications_settings.players_playing_with_gaps"
            />
            <SettingRadioGroup
              label="У плееров высокая температура"
              name="notifications_settings.players_high_temperature"
            />
            <hr />
            <Form.Group>
              <ButtonToolbar>
                <Button appearance="primary" onClick={handleSubmit}>Сохранить</Button>
              </ButtonToolbar>
            </Form.Group>
          </Form>
        </Panel>
      </div>
    </>
  );
};

export default Profile;
