import { Col, FlexboxGrid, Modal } from "rsuite";
import { MdMonitor, MdOutlineSyncAlt } from "react-icons/md";

import LoaderComponent from "components/LoaderComponent";
import ErrorComponent from "components/ErrorComponent";
import CardComponent from "components/CardComponent";
import useLayout from "hooks/useLayout";
import { loadingStatus } from "helpers/fetcher";
import { formatDate } from "helpers/date";
import { CAMPAIGNS_STATES } from "constants";
import { useState } from "react";

const PlaylistsGridComponent = ({
  buttonCreate,
  items,
  status,
  error,
  onClick,
  onEdit,
  onClone,
  onDelete,
}) => {
  const [preview, setPreview] = useState({ open: false, path: "", type: "" });

  const { gridBreakPoints } = useLayout();

  const menuItems = [
    { key: "edit", label: "Редактировать", action: item => onEdit(item) },
    { key: "clone", label: "Клонировать", action: item => onClone(item) },
    { key: "delete", label: "Удалить", action: item => onDelete(item.id) },
  ];

  if (status !== loadingStatus.SUCCEEDED) {
    return <LoaderComponent />;
  }

  if (status === loadingStatus.FAILED) {
    return <ErrorComponent message={error} />;
  }

  return (
    <>
      <FlexboxGrid className="grid-items" align="top">
        {buttonCreate && (
          <FlexboxGrid.Item as={Col} {...gridBreakPoints}>
            {buttonCreate}
          </FlexboxGrid.Item>
        )}
        {items.map(({ players_received_playback_items_count, playback_items, players_count, trigger }, index) => {
          const playbackInterval = [
            formatDate(trigger.revision.data.rrule?.dtstart, ".").full_datetime,
            formatDate(trigger.revision.data.rrule?.until, ".").full_datetime,
            // formatDate(trigger.revision.start_datetime, ".", "+timezone").full_datetime,
            // formatDate(trigger.revision.end_datetime, ".", "+timezone").full_datetime,
          ];
          return (
            <FlexboxGrid.Item as={Col} {...gridBreakPoints} key={`${trigger.id}`}>
              <CardComponent
                title={trigger.name}
                thumbFiles={[playback_items[0].content_items[0].content_item.content_files[0].content_file]}
                onPreview={setPreview}
                unit={
                  <>
                    <MdMonitor /> {players_count}
                    <span className="ml-10"><MdOutlineSyncAlt /> {players_received_playback_items_count}</span>
                  </>
                }
                // date={playbackInterval.join(" - ")}
                // subTitle={`Устройства получили контент: ${players_received_playback_items_count} из ${players_count} | Показать`}
                subTitle={playbackInterval.join(" - ")}
                badgeColor={CAMPAIGNS_STATES[trigger.state].color}
                onClick={() => onClick(trigger.id)}
                menuItems={menuItems}
                menuItemSelect={key => menuItems.find(it => it.key === key).action(trigger)}
              />
            </FlexboxGrid.Item>
          )}
        )}
      </FlexboxGrid>
      <Modal size="lg" open={preview.open} onClose={() => setPreview({ open: false, path: "", type: "" })}>
        <Modal.Body>
          {preview.type === "video"
            ? <video width="100%" controls><source src={preview.path} type="video/mp4"/></video>
            : <img className="flex-image" src={preview.path} alt="" />
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PlaylistsGridComponent;
