import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FlexboxGrid, Form, IconButton, Button, Input, Message, Panel, Stack, TagPicker } from "rsuite";
import { MdDeleteOutline, MdAdd, MdOutlineSave } from "react-icons/md";
import {
  fetchPlayer,
  fetchWindows,
  createPlayer,
  patchPlayer,
  deletePlayer,
} from "redux/slices/playerSlice";
import { tagDrawerToggle } from "redux/slices/layoutSlice";

import HeaderComponent from "components/HeaderComponent";
import DrawWindowsComponent from "./DrawWindowsComponent";
import usePopup from "hooks/usePopup";
import { toInt } from "helpers/utils";
import { iconStyle } from "constants";

const PlayerWindowsComponent = () => {
  const initialFormData = { x: 0, y: 0, width: 0, height: 0, tags: [] };
  const [createForm, setCreateForm] = useState(initialFormData);
  const [editForm, setEditForm] = useState([]);
  const dispatch = useDispatch();
  const { data: player, windows: existingsWindows } = useSelector(state => state.player);
  const { responseToaster, messageToaster } = usePopup();

  const _paramBuilder = (params) => ({
    x: toInt(params.x),
    y: toInt(params.y),
    width: toInt(params.width),
    height: toInt(params.height),
    orientation: "landscape",
  });

  const dispatchExistingsWindows = useCallback(() => {
    dispatch(fetchWindows(player.id));
  }, [dispatch, player.id]);

  const handleCreateWindow = async () => {
    const { tags, ...data } = createForm;
    const winData = {
      name: `parentId-${player.id}_childNum-${editForm.length}`,
      parent_id: player.id,
      description: "",
      data: {
        ...data,
        orientation: player.data.orientation,
      },
      tags,
      user_data: player.user_data,
      timezone: player.timezone,
      type: player.type,
    };
    const createdPlayer = await createPlayer(winData);
    await patchPlayer(createdPlayer.id, { ad_campaign_algorithm: "each_n" });
    dispatchExistingsWindows();
    setCreateForm(initialFormData);
  };

  const removeWindow = async ({ is_parent: isParent, player_id: playerId }) => {
    if(isParent) {
      messageToaster({ message: "Нельзя удалить основное окно", type: "error" });
      return;
    }
    if(!window.confirm("Хотите удалить эту область?")) {
      return;
    }
    const res = await deletePlayer(playerId);
    responseToaster(res);
    dispatchExistingsWindows();
  };

  const handleCreateFormChange = (_, e) => {
    const value = typeof e.target.value === "string" ? parseInt(e.target.value) : e.target.value;
    createForm[e.target.name] = value;
    setCreateForm({ ...createForm });
  };

  const handleEditFormChange = (e, index) => {
    const value = typeof e.target.value === "string" ? parseInt(e.target.value) : e.target.value;
    editForm[index][e.target.name] = value;
    setEditForm([...editForm]);
  };

  const handleEditWindow = async index => {
    const { tags, ...data } = editForm[index];
    await patchPlayer(editForm[index].player_id, { tags: tags || [], data });
    if (data.is_parent) {
      dispatch(fetchPlayer(player.id));
    }
  };

  useEffect(() => {
    dispatchExistingsWindows()
  }, [dispatchExistingsWindows]);

  useEffect(() => {
  console.log(player.data);

    const parentWindow = {
      ..._paramBuilder(player.data),
      player_id: player.id,
      tags: player.tags.map(({ id }) => id),
      is_parent: true,
    };
    const childWindows = existingsWindows.map(win => ({
      ..._paramBuilder(win),
      player_id: win.player_id,
      tags: win.tags.map(({ id }) => id),
      is_parent: false,
    }));
    childWindows.sort(({ player_id: aId }, { player_id: bId }) => (aId > bId) ? 1 : ((bId > aId) ? -1 : 0));
    setEditForm([parentWindow, ...childWindows]);
  }, [player, existingsWindows]);

  return (
    <div> 
      <HeaderComponent
        title={player.name}
        tag="h3"
        gutter={0}
      />

      <HeaderComponent title="Управление окнами" tag="h6" className="inner-header" />
      <DrawWindowsComponent windows={editForm} removeWindow={removeWindow} />

      {editForm.map((window, index) => (
        <Panel
          key={`${window.player_id}`}
          className="mt-20"
          shaded
          header={
            <Stack justifyContent="space-between">
              <h6>{window.is_parent ? "Основное окно" : `Окно ${index}`}</h6>
              <>
                <IconButton
                  size="xs"
                  color="blue"
                  appearance="subtle"
                  icon={<MdOutlineSave size={iconStyle.HEADER_ICON_SIZE} />}
                  onClick={() => handleEditWindow(index)}
                />
                {" "}
                {!window.is_parent && (
                  <IconButton
                    size="xs"
                    color="red"
                    appearance="subtle"
                    icon={<MdDeleteOutline size={iconStyle.HEADER_ICON_SIZE} />}
                    onClick={() => removeWindow(window)}
                  />
                )}
              </>
            </Stack>
          }
        >
          <WindowFormComponent form={window} onChange={(_, e) => handleEditFormChange(e, index)} />
        </Panel>
      ))}

      <hr />
      <HeaderComponent
        title="Добавить окно"
        tag="h6"
        right={
          <IconButton
            size="xs"
            color="green"
            appearance="primary"
            icon={<MdAdd size={iconStyle.HEADER_ICON_SIZE} />}
            onClick={handleCreateWindow}
          />
        }
        className="inner-header"
      />
      <WindowFormComponent form={createForm} onChange={handleCreateFormChange} />
    </div>
  );
};

const WindowFormComponent = ({ form: { x, y, width, height, tags }, onChange }) => {
  const { playersTags } = useSelector(state => state.tags);
  const dispatch = useDispatch();

  const handleTagsChange = tags => {
    const mockEvent = { target: { name: "tags", value: tags } };
    onChange(null, mockEvent);
  };

  const handleTagDrawerToggle = () => dispatch(tagDrawerToggle());

  return (
    <>
      <FlexboxGrid justify="space-between">
        <FlexboxGrid.Item colspan={5}>
          <div>X</div>
          <Input
            name="x"
            value={x}
            onChange={onChange}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5}>
          <div>Y</div>
          <Input
            name="y"
            value={y}
            onChange={onChange}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5}>
          <div>Ширина</div>
          <Input
            name="width"
            value={width}
            onChange={onChange}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5}>
          <div>Высота</div>
          <Input
            name="height"
            value={height}
            onChange={onChange}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <hr/>
      {(playersTags.length > 0) ? (
        <Form.Group>
          <Form.ControlLabel>Теги</Form.ControlLabel>
          <TagPicker
            data={playersTags.map(({ id, name }) => ({ label: name, value: id }))}
            onChange={handleTagsChange}
            value={tags}
            block
          />
        </Form.Group>
      ) : (
        <Message type="warning">
          <p className="inner-form">Ни один тег устройства не создан</p>
          <Button
            appearance="primary"
            color="blue"
            onClick={handleTagDrawerToggle}
          >
            Создать
          </Button>
        </Message>
      )}
    </>
  );
};

export default PlayerWindowsComponent;
