import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonToolbar, CheckTree, Dropdown, IconButton, InlineEdit, Input, InputGroup, Message } from "rsuite";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowRight,
  MdAdd,
  MdOutlineClose,
  MdAccountTree,
  MdSave,
} from "react-icons/md";
import { cloneDeep } from "lodash";

import {
  createCategory,
  patchCategory,
  deleteCategory,
  fetchCategories,
} from "redux/slices/categoriesSlice";
import usePopup from "hooks/usePopup";
import { loadingStatus } from "helpers/fetcher";
import { listToTree } from "helpers/utils";

const CategoriesComponent = ({ onQueryString, categoryType }) => {
  const [treeData, setTreeData] = useState([]);
  const [filterCats, setFilterCats] = useState([]);
  const [current, setCurrent] = useState({});
  const categories = useSelector(state => state.categories);
  const toasterId = useRef();
  const dispatch = useDispatch();
  const { messageToaster, removeToaster, responseToaster } = usePopup();

  const builder = (data, hub = []) => {
    data.forEach(({ id, children = [], ...rest }) => {
      hub.push(id);
      if (children.length) builder(children, hub);
    })
    return hub;
  };

  const handleSelect = (item, values, event) => {
    const hub = [];
    let uniqueCats = [];
    builder([item], hub);
    if (event.target.checked) {
      uniqueCats = Array.from(new Set([...filterCats, ...hub]));
    } else {
      uniqueCats = filterCats.filter(c => hub.findIndex(p => p === c) === -1);
    }
    setFilterCats(uniqueCats);
    onQueryString(p => {
      const objParams = new URLSearchParams(p);
      objParams.delete("category");
      const _cat = uniqueCats.length ? `&category=${uniqueCats.join(",")}` : "";
      return `${objParams.toString()}${_cat}`;
    });
  };

  const handleSetNode = (node, e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setCurrent(node);
  };

  const handleCreate = async (e) => {
    if (!current.label) return;
    const res = await createCategory({
      name: current.label,
      parent_id: current.value || 0,
      type: categoryType,
    });
    responseToaster(res);
    dispatch(fetchCategories(`?type=${categoryType}`));
    setCurrent({});
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    const res = await patchCategory(current.value, { name: current.label });
    responseToaster(res);
    dispatch(fetchCategories(`?type=${categoryType}`));
  };

  const handleDelete = async (value) => {
    removeToaster(toasterId.current)
    const res = await deleteCategory(value);
    dispatch(fetchCategories(`?type=${categoryType}`));
    setTimeout(() => responseToaster(res), 500);
  };

  const handleDeleteConfirm = async (node, e) => {
    e.preventDefault();
    toasterId.current = messageToaster({
      header: "Удаление категории",
      type: "info",
      duration: 15000,
      message: (
        <>
          <p>{`Вы действитьно хотите удалить категорию "${node.label}"?`}</p>
          <hr />
          <ButtonToolbar>
            <Button appearance="primary" onClick={() => handleDelete(node.value)}>Да</Button>
            <Button appearance="default" onClick={() => removeToaster(toasterId.current)}>Нет</Button>
          </ButtonToolbar>
        </>
      ),
    });
  };

  useEffect(() => {
    if (categories.status === loadingStatus.SUCCEEDED) {
      const _clone = cloneDeep(categories.data);
      const _data = listToTree(_clone);
      setTreeData(_data);
    }
  }, [categories.status]);

  return (
    <div className="caps-sidenav mb-20">
      <div style={{ padding: "20px 20px 0 20px" }}>
        {treeData.length > 0 ? (
          <CheckTree
            className="caps-tree"
            cascade={false}
            value={filterCats}
            data={treeData}
            height={220}
            searchable
            // searchKeyword="ololo"
            showIndentLine
            scrollShadow
            onSelect={handleSelect}
            renderTreeNode={treeNode => {
              return (
                <div className="caps-tree-node">
                  <InlineEdit
                    size="sm"
                    defaultValue={treeNode.label}
                    stateOnBlur="cancel"
                    onEdit={e => handleSetNode(treeNode, e)}
                    onChange={(label) => handleSetNode({ ...treeNode, label })}
                    onSave={handleEdit}
                    onCancel={(e) => e.preventDefault()}
                  />
                  <div style={{ whiteSpace: "nowrap" }}>
                    <Dropdown
                      // title={<MdAdd color="green" />}
                      renderToggle={(props, ref) => (
                        <IconButton
                          title="Добавить подкатегорию"
                          ref={ref}
                          {...props}
                          appearance="subtle"
                          icon={<MdAdd color="green" />}
                        />
                      )}
                      trigger="click"
                      placement="bottomEnd"
                      noCaret
                      menuStyle={{ padding: 0 }}
                    >
                      <Dropdown.Item style={{ padding: 0 }}>
                        <InputGroup inside>
                          <Input
                            style={{ width: 210 }}
                            onChange={(label) => handleSetNode({ ...treeNode, label })}
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={(e) => e.stopPropagation()}
                          />
                          <InputGroup.Button title="Добавить категорию" onClick={handleCreate}>
                            <MdSave />
                          </InputGroup.Button>
                        </InputGroup>
                      </Dropdown.Item>
                    </Dropdown>
                    <IconButton
                      title="Удалить категорию"
                      appearance="subtle"
                      icon={<MdOutlineClose color="red" />}
                      onClick={e => handleDeleteConfirm(treeNode, e)}
                    />
                    {/* <MdAccountTree
                      color=""
                      title="Привязать категории"
                      onClick={onClickBatchAction}
                      style={{ marginLeft: 4 }}
                    /> */}
                  </div>
                </div>
              );
            }}
            renderTreeIcon={(treeNode, expanded) => {
              if (treeNode.children.length > 0) {
                return expanded ? (
                  <MdOutlineKeyboardArrowDown style={{ fontSize: 18 }} />
                ) : (
                  <MdOutlineKeyboardArrowRight style={{ fontSize: 18 }} />
                )
              }
              return <></>;
            }}
          />
        ) : (
          <Message type="warning" showIcon>Нет ни одной категории</Message>
        )}
      </div>

      <div className="p-20">
        <InputGroup inside style={{ width: 280 }}>
          <Input
            onChange={(label) => handleSetNode({ label, parent_id: 0 })}
            // value={current.label || ""}
            placeholder="Новая категория"
          />
          <InputGroup.Button onClick={handleCreate}>
            <MdSave />
          </InputGroup.Button>
        </InputGroup>
      </div>
    </div>
  );
};

export default CategoriesComponent;
