import { useState } from "react";

import ReportsGridComponent from "components/campaign/reports/ReportsGridComponent";
import ButtonCreateComponent from "components/buttons/ButtonCreateComponent";
import DrawerComponent from "components/DrawerComponent";

const Mobile = ({
  data: { objects, meta, status, error },
  sideBar,
  contentBar,
  handleFormButton,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const drawerCloseHandler = () => setDrawerOpen(false);

  const drawerCreateHandler = (_, id) => {
    setDrawerOpen(true);
    handleFormButton(_, id);
  };

  return (
    <div>
      <ButtonCreateComponent onClick={drawerCreateHandler}>
        Создать расписание
      </ButtonCreateComponent>
      <ReportsGridComponent
        items={objects}
        status={status}
        error={error}
        onForm={drawerCreateHandler}
      />
      {sideBar}
      <DrawerComponent
        open={drawerOpen}
        onClose={drawerCloseHandler}
        size="full"
        title="Детали записи"
      >
        {contentBar}
      </DrawerComponent>
    </div>
  );
}

export default Mobile;
