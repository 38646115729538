import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Form,
  ButtonToolbar,
  Button,
  Panel,
  Schema,
  Message,
} from "rsuite";

import ErrorComponent from "components/ErrorComponent";
import InputFieldComponent from "components/forms/InputFieldComponent";
import { postReset } from "redux/slices/loginSlice";
import { loadingStatus } from "helpers/fetcher";
import HeaderComponent from "components/HeaderComponent";

const { StringType } = Schema.Types;
const model = Schema.Model({
  password: StringType()
    .minLength(6, "Длина пароля не менее 6 символов")
    .isRequired("Обязательное поле"),
  confirmPassword: StringType()
    .addRule((value, data) => {
      if (value !== data.password) {
        return false;
      }
      return true;
    }, "Пароли не совпадают")
    .isRequired("Обязательное поле")
});

const Password = () => {
  const formRef = useRef();
  const [formValue, setFormValue] = useState({ password: "", confirmPassword: "" });
  const [success, setSuccess] = useState();
  const dispatch = useDispatch();
  const { reset: { status, error } } = useSelector(state => state.login);

  const handleSubmit = () => {
    if (!formRef.current.check()) {
      return;
    }
    dispatch(postReset({ password: formValue.password }));
  };

  useEffect(() => {
    setSuccess(status === loadingStatus.SUCCEEDED);
  }, [status]);

  
  return (
    <>
      <HeaderComponent title="Настройки" />
      <div className="caps-content">
        <Panel header={<h3>Восстановить пароль</h3>} bordered>
          <Message showIcon type="info">
            Пароль может содержать символы латинского алфавита, кроме пробела. Длина пароля от 6 символов
          </Message>
          {success && (<Message showIcon closable type="success">Пароль успешно изменен</Message>)}
          <br/>
          <Form
            model={model}
            ref={formRef}
            onChange={setFormValue}
            formValue={formValue}
            fluid
          >
            <InputFieldComponent name="password" type="password" label="Новый пароль" />
            <InputFieldComponent name="confirmPassword" type="password" label="Повторите пароль" />
            <Form.Group>
              <ButtonToolbar>
                <Button appearance="primary" onClick={handleSubmit}>Сохранить</Button>
              </ButtonToolbar>
            </Form.Group>
            {error && <ErrorComponent message={error} />}
          </Form>
        </Panel>
      </div>
    </>
  );
};

export default Password;
