import { Message } from "rsuite";
import { MdOutlineFolder } from "react-icons/md";

import LoaderComponent from "components/LoaderComponent";
import ErrorComponent from "components/ErrorComponent";
import FileItemComponent from "components/files/FileItemComponent";
import HeaderComponent from "components/HeaderComponent";
import { loadingStatus } from "helpers/fetcher";

const FoldersListComponent = ({
  items,
  status,
  error,
  onClick,
  onEdit,
  onRemove,
}) => {


  if (status === loadingStatus.FAILED) {
    return <ErrorComponent message={error} />;
  }

  if (status === loadingStatus.SUCCEEDED && items.length === 0) {
    return (<Message type="warning">Папок нет</Message>);
  }

  return (
    <div className="item-list">
      <hr/>
      <HeaderComponent title="Рекламодатели" tag="h6" className="mb-10 ml-10" />
      {status !== loadingStatus.SUCCEEDED && <LoaderComponent center={false} />}
      {items.map(item => {
        return (
          <FileItemComponent
            key={`folder-${item.id}`}
            id={item.id}
            icon={<MdOutlineFolder size="3em" />}
            title={item.name}
            subTitle={item.description}
            onClick={() => onClick(item)}
            onEdit={onEdit ? () => onEdit(item) : null}
            onRemove={onRemove ? () => onRemove(item.id) : null}
          />
        )}
      )}
    </div>
  );
};

export default FoldersListComponent;
