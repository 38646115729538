import { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Sidenav, Nav, Whisper, IconButton, Popover } from "rsuite";
import { MdMoreVert } from "react-icons/md";

import useLayout from "hooks/useLayout";
import { sideNavToggle } from "redux/slices/layoutSlice";

const SideNavComponent = ({ sideNavItems, navActionHandlers }) => {
  const [activeKey, setActiveKey] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sideNavState } = useSelector((state) => state.layout);
  const { isDesktop } = useLayout();

  const handleSideNavToggle = (val) => {
    dispatch(sideNavToggle(val));
  };

  const navigationHandler = (action) => {
    if (navActionHandlers && navActionHandlers[action]) {
      navActionHandlers[action]();
    } else {
      navigate(action);
    }
    setActiveKey(action);
  };

  const MenuComponent = ({ expanded, onToggle }) => (
    <Sidenav expanded={expanded} className="caps-sidenav">
      {onToggle ? <Sidenav.Toggle onToggle={onToggle} /> : null}
      <Sidenav.Body>
        <Nav activeKey={activeKey} onSelect={navigationHandler}>
          {sideNavItems.map(({ title, action, icon, id }) => (
            <Nav.Item eventKey={action} icon={icon} key={id}>
              {title}
            </Nav.Item>
          ))}
        </Nav>
      </Sidenav.Body>
    </Sidenav>
  );

  const mobileRenderMenu = ({ onClose, left, top, className }, ref) => (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <MenuComponent expanded={true} />
    </Popover>
  );

  return (
    <>
      {isDesktop ? (
        <MenuComponent expanded={sideNavState} onToggle={handleSideNavToggle} />
      ) : (
        <Whisper
          placement="topEnd"
          trigger="click"
          speaker={mobileRenderMenu}
        >
          <IconButton
            className="fab-button"
            appearance="primary"
            icon={<MdMoreVert size="1.4em" />}
            circle
          />
        </Whisper>
      )}
    </>
  );
};

export default SideNavComponent;
