import { useState, useEffect } from "react";
import {
  InputGroup,
  Button,
  TagPicker,
  Form,
} from "rsuite";

import HeaderComponent from "components/HeaderComponent";
// import CloseButtonComponent from "components/CloseButtonComponent";
import usePopup from "hooks/usePopup";
import { putFile } from "redux/slices/filesSlice";
import { putFiller, patchFiller } from "redux/slices/fillersSlice";
import { MIN_DURATION } from "constants";

const FilesEditFormComponent = ({
  tagsList = [],
  reload,
  contentItem: { id, name, description, data, tags, type, fillerContentItemId },
}) => {
  const [formValue, setFormValue] = useState({ name: "", tags: [], default_duration: MIN_DURATION });
  const [errors, setErrors] = useState({});
  const { responseToaster } = usePopup();

  const handleChangeForm = (value, { target }) => {
    setFormValue({ ...formValue, [target.name]: value });
  };

  const handleSubmit = async () => {
    const _errors = {};
    if (!formValue.name) _errors.name = "Это обязательное поле";
    if (Object.keys(_errors).length > 0) {
      setErrors(_errors);
      return;
    }

    const payload = {
      name: formValue.name,
      description,
      tags: formValue.tags || [],
      data: {
        ...(type !== "video" ?
          { default_duration: +formValue.default_duration } :
          { default_play_audio: true }),
      },
    }
    if (fillerContentItemId) {
      const fillerId = id;
      const actionQueue = [];
      actionQueue.push(
        putFiller(fillerId, {
          name: payload.name,
          category_id: null,
          policy: payload.tags.length > 0 ? 
            [{ rule: "allow", player_tag: payload.tags }, { rule: "deny" }] :
            [],
          end: null,
          start: null
        })
      );
      if (payload.data.default_duration) {
        actionQueue.push(patchFiller(fillerId, {
          playback_item__content_item_data__duration: payload.data.default_duration,
        }))
      };
      actionQueue.push(putFile(fillerContentItemId, payload));
      const results = await Promise.all(actionQueue);
      const errorIndex = results.findIndex(r => !r.ok);
      if (errorIndex === -1) {
        responseToaster(results[0]);
      } else {
        responseToaster(results[errorIndex]);
      }
    } else {
      const res = await putFile(id, payload);
      responseToaster(res);
    }
    reload(prev => !prev);
  };

  useEffect(() => {
    setFormValue({
      name,
      tags: tags.map(({ id }) => id ),
      default_duration: data.default_duration || MIN_DURATION,
    });
  }, [id, name, tags, data]);

  return (
    <Form fluid>
      <HeaderComponent
        title="Изменить файл"
        tag="h3"
        gutter={0}
      />
      <div className="inner-form">
        <small>Название</small>
        <Form.Control
          name="name"
          value={formValue.name}
          onChange={handleChangeForm}
          errorMessage={errors.name}
        />
      </div>
      <div className="inner-form">
        <small>Теги</small>
        <TagPicker
          data={tagsList.map(({ id, name }) => ({ label: name, value: id }))}
          value={formValue.tags}
          onChange={value => handleChangeForm(value, { target: { name: "tags" } } )}
          block
        />
      </div>
      {type !== "video" ? (
        <div className="inner-form">
          <small>Длительность</small>
          <InputGroup>
            <Form.Control
              type="number"
              name="default_duration"
              postfix="сек"
              min={MIN_DURATION}
              value={formValue.default_duration}
              onChange={handleChangeForm}
              errorMessage={errors.default_duration}
            />
            <InputGroup.Addon>сек</InputGroup.Addon>
          </InputGroup>
        </div>
      ) : null}

      <div className="inner-form">
        <Button
          appearance="primary"
          onClick={handleSubmit}
          className="inner-form"
          block
        >
          Сохранить
        </Button>
      </div>
    </Form>
  );
};

export default FilesEditFormComponent;
