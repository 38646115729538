import { useMemo } from "react";
import { Button, ButtonGroup, Message, Panel } from "rsuite";

import { FormErrorComponent } from "components/ErrorComponent";
import InputFieldComponent from "components/forms/InputFieldComponent";
import uuid4 from "helpers/uuid4";

const GPIO_PINS = [1, 67, 70, 71, 72, 73, 194, 195];
const GPIO_FRONT = { rising: "нарастающий", falling: "ниспадающий" };
const SERIAL_BAUDRATES = [9600, 14400, 19200, 115200];
const SERIAL_TYPES = { "serial.string": "Строка", "serial.geo": "Геокоординаты", "serial.json": "JSON" };

const ExternalFieldsComponent = ({ formErrors, currentData, onChangeEvent }) => {
  const uuid = useMemo(() => uuid4(), []);

  const handleChangeEvent = (type, obj = {}) => {
    let event = {};
    if (type === "url") {
      event = {
        type: "url",
        data: uuid,
        url: currentData.url || "",
        ...obj,
      };
    } else if (type === "gpio") {
      event = {
        type: "gpio",
        platform: "hummingboard",
        pin: currentData.pin || 1,
        edge: currentData.edge || "rising",
        ...obj,
      };
    } else if (type.includes("serial")) {
      event = {
        type: type || Object.keys(SERIAL_TYPES)[0],
        port: currentData.port || "",
        speed: currentData.speed || 9600,
        command: currentData.command || "",
        ...obj,
      };
    }
    onChangeEvent([event]);
  };

  console.log(currentData)

  return (
    <>
      <Panel header={<h4>Настройки события</h4>} bordered className="mb-20">
        <InputFieldComponent
          label="Тип события*"
          errorComponent={formErrors.events && <FormErrorComponent message="Выберите тип события" />}
        >
          <div className="carousel-container">
            <ul className="carousel-list">
              <li
                onClick={() => handleChangeEvent("url")}
                className={currentData.type === "url" ? "active" : null}
              >
                <h4>Ссылка</h4>
              </li>
              <li
                onClick={() => handleChangeEvent("gpio")}
                className={currentData.type === "gpio" ? "active" : null}
              >
                <h4>GPIO</h4>
              </li>
              <li
                onClick={() => handleChangeEvent("serial.string")}
                className={currentData.type?.includes("serial") ? "active" : null}
              >
                <h4>Serial</h4>
              </li>
            </ul>
          </div>
        </InputFieldComponent>
        {currentData.type === "url" && (
          <>
            <Message type="warning">
              Воспроизведение начнется при появлении на сервере ID события
            </Message>
            <InputFieldComponent
              label="Ссылка*"
              value={currentData.url}
              onChange={(url) => handleChangeEvent("url", { url })}
              help="Адрес сервера оповещений (push сервер)"
              errorComponent={formErrors.eventUrl && <FormErrorComponent message="Укажите корректный url адрес" />}
            />
          </>
        )}
        {currentData.type === "gpio" && (
          <>
            <Message type="warning">
              Воспроизведение начнется при появлении прерывания на пине
            </Message>
            <InputFieldComponent label="Пин*">
              <ButtonGroup>
                {GPIO_PINS.map((pin) => (
                  <Button
                    key={pin}
                    appearance={pin === currentData.pin ? "primary" : "default"}
                    onClick={() => handleChangeEvent("gpio", { pin })}
                  >
                    {pin}
                  </Button>
                ))}
              </ButtonGroup>
            </InputFieldComponent>
            <InputFieldComponent label="Фронт*">
              <ButtonGroup>
                {Object.keys(GPIO_FRONT).map((edge) => (
                  <Button
                    key={edge}
                    appearance={edge === currentData.edge ? "primary" : "default"}
                    onClick={() => handleChangeEvent("gpio", { edge })}
                  >
                    {GPIO_FRONT[edge]}
                  </Button>
                ))}
              </ButtonGroup>
            </InputFieldComponent>
          </>
        )}
        {currentData.type?.includes("serial") && (
          <>
            <Message type="warning">
              Воспроизведение начнется при получении команды в serial
            </Message>
            <InputFieldComponent
              label="Порт (COM)*"
              value={currentData.port}
              onChange={(port) => handleChangeEvent(currentData.type, { port })}
              help="например: COM3 или /dev/ttyS1"
              errorComponent={formErrors.eventPort && <FormErrorComponent message="Укажите path порта" />}
            />
            <InputFieldComponent label="Скорость (Бод)*">
              <ButtonGroup>
                {SERIAL_BAUDRATES.map((speed) => (
                  <Button
                    key={speed}
                    appearance={speed === currentData.speed ? "primary" : "default"}
                    onClick={() => handleChangeEvent(currentData.type, { speed })}
                  >
                    {speed}
                  </Button>
                ))}
              </ButtonGroup>
            </InputFieldComponent>
            <InputFieldComponent
              label="Команда*"
              value={currentData.command}
              onChange={(command) => handleChangeEvent(currentData.type, { command })}
              help="например: flash"
              errorComponent={formErrors.eventCommand && <FormErrorComponent message="Укажите команду" />}
            />
            <ButtonGroup>
              {Object.keys(SERIAL_TYPES).map(type => (
                <Button
                  key={type}
                  appearance={type === currentData.type ? "primary" : "default"}
                  onClick={() => handleChangeEvent(currentData.type, { type })}
                >
                  {SERIAL_TYPES[type]}
                </Button>
              ))}
            </ButtonGroup>
          </>
        )}
      </Panel>
    </>
  );
};

export default ExternalFieldsComponent;
