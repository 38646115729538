import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";
import { campaigns } from "constants/endpoints";

const fetchCampaign = createAsyncThunk("campaigns/id", async (id) => {
  const response = await fetcher(`${campaigns.ALL}/${id}`);
  return response.json();
});

const fetchCampaignPreviewPlayers = createAsyncThunk("campaigns/preview/players", async (q = "") => {
  const response = await fetcher(`${campaigns.CAMPAIGN_PREVIEW_PLAYERS}${q}`);
  return response.json();
});

const fetchCampaignPlayback = createAsyncThunk("campaigns/playback", async ({ id, q = "?limit=-1" }) => {
  const response = await fetcher(`${campaigns.ALL}/${id}/playback_items${q}`);
  return response.json();
});

const fetchCampaignPlayers = createAsyncThunk("campaigns/players", async (id) => {
  const response = await fetcher(`${campaigns.ALL}/${id}/players`);
  return response.json();
});

const fetchCampaignReport = createAsyncThunk("campaigns/report", async (id) => {
  const response = await fetcher(`${campaigns.ALL}/${id}/report?limit=-1`);
  return response.json();
});

const createCampaign = async (data) => {
  const response = await fetcher(campaigns.ALL, {
    method: "POST",
    body: JSON.stringify(data),
  });
  return response;
};

const putCampaign = async (id, data) => {
  const response = await fetcher(`${campaigns.ALL}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
  return response;
};

const patchCampaign = async (id, data) => {
  const response = await fetcher(`${campaigns.ALL}/${id}`, {
    method: "PATCH",
    body: JSON.stringify(data),
  });
  return response;
};

const deleteCampaign = async id => fetcher(`${campaigns.ALL}/${id}`, { method: "DELETE" });

const requestReports = async id => await fetcher(`${campaigns.ALL}/${id}/report`, { method: "POST" });

const initialState = {
  data: {},
  status: loadingStatus.IDLE,
  error: null,

  playbackItems: [],
  campaignPlayers: [],
  previewPlayers: {
    objects: [],
    meta: {},
    status: loadingStatus.IDLE,
    error: null,
  },
  campaignReport: [],
};

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCampaign.pending, (state, action) => { state.status = loadingStatus.LOADING; })
      .addCase(fetchCampaign.fulfilled, (state, action) => {
        state.status = loadingStatus.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(fetchCampaign.rejected, (state, action) => {
        state.status = loadingStatus.FAILED;
        state.error = `${action.error.name}: ${action.error.message}`;
      })

      .addCase(fetchCampaignPreviewPlayers.fulfilled, (state, action) => { state.previewPlayers = action.payload; })
      .addCase(fetchCampaignPlayback.fulfilled, (state, action) => { state.playbackItems = action.payload; })
      .addCase(fetchCampaignPlayers.fulfilled, (state, action) => { state.campaignPlayers = action.payload; })
      .addCase(fetchCampaignReport.fulfilled, (state, action) => { state.campaignReport = action.payload; })
  }
});

export default campaignSlice.reducer;

export {
  fetchCampaign,
  fetchCampaignPreviewPlayers,
  fetchCampaignPlayback,
  fetchCampaignPlayers,
  fetchCampaignReport,

  createCampaign,
  putCampaign,
  patchCampaign,
  deleteCampaign,

  requestReports,
};
