import { useState } from "react";

import PlayersGridComponent from "components/player/PlayersGridComponent";
import ButtonCreateComponent from "components/buttons/ButtonCreateComponent";
import DrawerComponent from "components/DrawerComponent";

const Mobile = ({
  data: { objects, meta, status, error },
  sideBar,
  contentBar,
  handleCreateButton,
  handleDetailButton,
  handleEditButton,
  handleCloneButton,
  handleDeleteButton,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleOnCreateDrawer = () => {
    setDrawerOpen(true);
    handleCreateButton();
  };
  const handleOnDetailDrawer = (playerId) => {
    setDrawerOpen(true);
    handleDetailButton(playerId);
  };
  const handleOnEditDrawer = item => {
    setDrawerOpen(true);
    handleEditButton(item);
  };
  const handleOnCloneDrawer = item => {
    setDrawerOpen(true);
    handleCloneButton(item);
  };
  const handleOnCloseDrawer = () => setDrawerOpen(false);

  return (
    <>
      {/* <DataTreeComponent className="caps-contentbar mb-20 mt-20" /> */}
      <ButtonCreateComponent onClick={handleOnCreateDrawer}>
        Создать устройство
      </ButtonCreateComponent>
      <PlayersGridComponent
        items={objects}
        status={status}
        error={error}
        onClick={handleOnDetailDrawer}
        onEdit={handleOnEditDrawer}
        onClone={handleOnCloneDrawer}
        onDelete={handleDeleteButton}
      />
      {sideBar}
      <DrawerComponent
        open={drawerOpen}
        onClose={handleOnCloseDrawer}
        size="full"
        title="Детали записи"
      >
        {contentBar}
      </DrawerComponent>
    </>
  );
}

export default Mobile;
