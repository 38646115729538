import { useState, useRef, useEffect } from "react";
import { Uploader, Form, Button, Schema, AvatarGroup, Avatar } from "rsuite";
import { MdAddCircle, MdEdit } from "react-icons/md";

import HeaderComponent from "components/HeaderComponent";
// import CloseButtonComponent from "components/CloseButtonComponent";
import InputFieldComponent from "components/forms/InputFieldComponent";
import { createFolder, putFolder } from "redux/slices/filesSlice";
import { SERVER_URL, getHeaders } from "helpers/fetcher";
import { content } from "constants/endpoints";
import { folderFormInitialData } from "constants/form";

const { StringType } = Schema.Types;
const model = Schema.Model({
  name: StringType().isRequired("Обязательное поле"),
});

const FoldersFormComponent = ({ reload, defaultValues = {} }) => {
  const { id, name, description, logo } = defaultValues;
  const formRef = useRef();
  const [formValue, setFormValue] = useState(folderFormInitialData);
  const [uploadFile, setUploadFile] = useState();

  // const handleFileRemove = () => setUploadFile(null);

  const clearForm = () => {
    setFormValue(folderFormInitialData)
    setUploadFile(null);
  };

  const handleSubmit = async () => {
    if (!formRef.current.check()) {
      return;
    }
    const payload = {
      ...formValue,
      logo: uploadFile || "",
    };
    if (id) {
      await putFolder(id, payload);
    }  else {
      await createFolder(payload);
      clearForm();
    }
    reload(prev => !prev);
  };

  useEffect(() => {
    if (id) {
      setFormValue({ name, description });
      setUploadFile(logo);
    } else {
      clearForm();
    }
  }, [id, name, description, logo]);

  return (
    <>
      <HeaderComponent
        title={id ? "Редактировать рекламодателя" : "Создать рекламодателя"}
        tag="h3"
        gutter={0}
      />
      <Form
        model={model}
        ref={formRef}
        onChange={setFormValue}
        formValue={formValue}
        fluid
      >
        <InputFieldComponent name="name" label="Название" />
        <InputFieldComponent name="description" label="Описание" />

        <AvatarGroup spacing={6} className="inner-form">
          <Avatar
            size="lg"
            circle
            src={uploadFile}
          />
          <Uploader
            action={`${SERVER_URL}${content.FOLDERS}/logo`}
            headers={getHeaders()}
            accept="image/jpeg, image/png"
            fileListVisible={false}
            onSuccess={setUploadFile}
          >
            {uploadFile ? (
              <Button><MdEdit size="1.4em" /> Изменить обложку</Button>
            ) : (
              <Button><MdAddCircle size="1.4em" /> Добавить обложку</Button>
            )}
          </Uploader>
        </AvatarGroup>

        <div className="inner-form">
          <Button
            appearance="primary"
            // disabled={!filesFormValue.length || !selectedFolder}
            onClick={handleSubmit}
            className="inner-form"
            block
          >
            Сохранить
          </Button>
        </div>
      </Form>
    </>
  );
};

export default FoldersFormComponent;
