const nextItems = async (queryString, offset, limit, action) => {
  const step = 20;
  const _offset = (offset || 0) + step;
  const _limit = (limit || 0) + step;
  const _qs = new URLSearchParams(queryString);
  _qs.set("offset", _offset);
  _qs.set("limit", _limit);
  const items = await action(`?${_qs.toString()}`);
  return items;
};

export default nextItems;
