const TotalTableComponent = ({
  name,
  start,
  end,
  previewPlayersLenght,
  filesLenght,
  description,
  ...rest
}) => (
  <table className="caps-table">
    <tbody>
      <tr><td>Название</td><td>{name}</td></tr>
      <tr><td>Дата начала</td><td>{start}</td></tr>
      <tr><td>Дата окончания</td><td>{end}</td></tr>
      <tr><td>Количество устройств</td><td>{previewPlayersLenght}</td></tr>
      <tr><td>Количество файлов</td><td>{filesLenght}</td></tr>
      <tr><td>Описание</td><td>{description}</td></tr>
    </tbody>
  </table>
);

export default TotalTableComponent;
