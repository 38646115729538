import { playbackPerTime } from "helpers/utils";
import { formatDate } from "helpers/date";

const CampaignsTotalTableComponent = ({
  name,
  start,
  start_time,
  end,
  end_time,
  interval,
  previewPlayersLenght,
  filesLenght,
  duration,
  description,
}) => {
  const perTime = playbackPerTime(interval);

  return (
    <table className="caps-table">
      <tbody>
        <tr><td>Название</td><td>{name}</td></tr>
        <tr>
          <td>Срок действия</td>
          <td>
            <p>с: {formatDate(`${start}${start_time ? ` ${start_time}` : ""}`, ".").full_datetime}</p>
            <p>по: {formatDate(`${end}${end_time ? ` ${end_time}` : ""}`, ".").full_datetime}</p>
          </td>
          {/* <td>{`${start} ${start_time} - ${end} ${end_time}`}</td> */}
        </tr>
        <tr><td>Частота показов в час</td><td>{interval}</td></tr>
        <tr><td>Блок</td><td>{`${perTime.minutes} или ${perTime.seconds}`}</td></tr>
        <tr><td>Количество устройств</td><td>{previewPlayersLenght}</td></tr>
        <tr><td>Количество файлов</td><td>{filesLenght}</td></tr>
        <tr><td>Общая длительность</td><td>{duration} сек</td></tr>
        <tr><td>Описание</td><td>{description}</td></tr>
      </tbody>
    </table>
  );
};

export default CampaignsTotalTableComponent;

// POST http://localhost/v3/od/block_sets/
// задаётся длина рекламного блока (цикла). Рекламная кампания воспроизводится раз в оборот цикла. 
// В этом минус данной концепции: например невозможно сделать чтобы один рекламодатель транслировался 
// раз в минуту, а второй раз в час.

// мин 30 сек
// макс 86400 
// {
// 	"ad_blocks": [
// 		{
// 			"duration": 30,
// 			"start": null
// 		}
// 	],
// 	"description": "",
// 	"name": "ббб",
// 	"players": [
// 		5
// 	]
// }



// {
// 	"advertiser": 1,
// 	"algorithm": "ad_block",
// 	"description": "",
// 	"end": "2024-08-02",
// 	"end_time": null,
// 	"layout": null,
// 	"name": "0000",
// 	"playback_items": [
// 		{
// 			"content_items": [
// 				{
// 					"content_item_data": {
// 						"play_audio": true
// 					},
// 					"id": 9,
// 					"playback_item_data": {}
// 				}
// 			],
// 			"data": {
// 				"orientation": "landscape"
// 			}
// 		}
// 	],
// 	"players": [
// 		{
// 			"id": 4,
// 			"skip_days": []
// 		}
// 	],
// 	"requirements": {
// 		"orientation": "landscape"
// 	},
// 	"start": "2024-08-01",
// 	"start_time": null,
// 	"type": "normal"
// }