import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";
import { players, playlists } from "constants/endpoints";

const fetchPlaylist = createAsyncThunk("playlists/id", async (id) => {
  const response = await fetcher(`${playlists.ALL}/${id}`);
  return response.json();
});

const fetchPreviewPlayers = createAsyncThunk("playlists/preview/players", async (q = "") => {
  const response = await fetcher(`${players.ALL}${q}`);
  return response.json();
});

const fetchPlaylistPlayback = createAsyncThunk("campaigns/playback", async ({ id, q = "?limit=-1" }) => {
  const response = await fetcher(`${playlists.ALL}/${id}/playback_items${q}`);
  return response.json();
});

const fetchPlaylistPlayers = createAsyncThunk("campaigns/players", async (id) => {
  const response = await fetcher(`${playlists.ALL}/${id}/players`);
  return response.json();
});

const fetchConflicts = createAsyncThunk("playlists/conflicts/id", async (data = {}) => {
  const response = await fetcher(`${playlists.CONFLICTS}`, {
    method: "POST",
    body: JSON.stringify(data),
  });
  return response.json();
});

const createPlaylist = async (data) => {
  const response = await fetcher(playlists.ALL, {
    method: "POST",
    body: JSON.stringify(data),
  });
  return response;
};
const putPlaylist = async (id, data) => {
  const response = await fetcher(`${playlists.ALL}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
  return response;
};
const patchPlaylist = async (id, data) => {
  const response = await fetcher(`${playlists.ALL}/${id}`, {
    method: "PATCH",
    body: JSON.stringify(data),
  });
  return response;
};
const deletePlaylist = async (id) => await fetcher(`${playlists.ALL}/${id}`, { method: "DELETE" });

// const requestLogs = async id => await fetcher(`${device.DEVICE}/${id}/logs/request`, { method: "POST" });
// const requestUnbindCampaigns = async id => await fetcher(`${players.ALL}/${id}/campaigns`, { method: "DELETE" });

const initialState = {
  data: {},
  status: loadingStatus.IDLE,
  error: null,

  previewPlayers: {
    objects: [],
    meta: {},
    status: loadingStatus.IDLE,
    error: null,
  },
  conflicts: [],
  playlistPlayback: [],
  playlistPlayers: [],
};

const playlistSlice = createSlice({
  name: "playlist",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPlaylist.pending, (state, action) => { state.status = loadingStatus.LOADING; })
      .addCase(fetchPlaylist.fulfilled, (state, action) => {
        state.status = loadingStatus.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(fetchPlaylist.rejected, (state, action) => {
        state.status = loadingStatus.FAILED;
        state.error = `${action.error.name}: ${action.error.message}`;
      })
      .addCase(fetchPreviewPlayers.fulfilled, (state, action) => { state.previewPlayers = action.payload; })
      .addCase(fetchConflicts.fulfilled, (state, action) => { state.conflicts = action.payload; })
      .addCase(fetchPlaylistPlayback.fulfilled, (state, action) => { state.playlistPlayback = action.payload; })
      .addCase(fetchPlaylistPlayers.fulfilled, (state, action) => { state.playlistPlayers = action.payload; })
  }
});

export default playlistSlice.reducer;

export {
  fetchPlaylist,
  fetchPreviewPlayers,
  fetchConflicts,
  fetchPlaylistPlayback,
  fetchPlaylistPlayers,

  createPlaylist,
  patchPlaylist,
  putPlaylist,
  deletePlaylist,
};
