import { FlexboxGrid } from "rsuite";

const HeaderComponent = ({
  title,
  tag = "h2",
  right,
  gutter,
  className = "",
}) => {

  const SwitchTag = tag;

  return (
    <div className={`caps-header ${className}`} style={
      gutter !== undefined ? { marginLeft: gutter, marginRight: gutter } : {}
    }>
      <FlexboxGrid justify="space-between">
        <FlexboxGrid.Item className="caps-header__text">
          <SwitchTag>{title}</SwitchTag>
        </FlexboxGrid.Item>
        {right && (
          <FlexboxGrid.Item>
            {right}
          </FlexboxGrid.Item>
        )}
      </FlexboxGrid>
    </div>
  );
};

export default HeaderComponent;
