import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";
import { players } from "constants/endpoints";

const getReboots = async (query) => {
  const response = await fetcher(`${players.REBOOTS}${query}`);
  return response.json();
};

const fetchReboots = createAsyncThunk("reboots", async (query = "") => {
  return await getReboots(query);
});

const fetchRebootsPlayers = createAsyncThunk("reboots/players", async (query = "") => {
  const response = await fetcher(`${players.ALL}${query}`);
  return response.json();
});

const createReboots = async (data) => {
  const response = await fetcher(players.REBOOTS, {
    method: "POST",
    body: JSON.stringify(data),
  });
  return response.json();
};
const putReboots = async (id, data) => {
  const response = await fetcher(`${players.REBOOTS}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
  return response.json();
};

const deleteReboots = async (id) => fetcher(`${players.REBOOTS}/${id}`, { method: "DELETE" });

const initialState = {
  objects: [],
  meta: {},
  status: loadingStatus.IDLE,
  error: null,

  formData: {},

  players: [],
};

const rebootsSlice = createSlice({
  name: "reboots",
  initialState,
  reducers: {
    fillForm: (state, action) => {
      state.formData = action.payload;
    },
    addReboots(state, { payload }) {
      state.objects = [...state.objects, ...payload];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchReboots.pending, (state, action) => { state.status = loadingStatus.LOADING; })
      .addCase(fetchReboots.fulfilled, (state, action) => {
        state.status = loadingStatus.SUCCEEDED;
        state.objects = action.payload.objects;
        state.meta = action.payload.meta;
      })

      .addCase(fetchRebootsPlayers.fulfilled, (state, action) => { state.players = action.payload.objects; })
  }
});

export default rebootsSlice.reducer;

export const { fillForm, addReboots } = rebootsSlice.actions;

export {
  getReboots,
  fetchReboots,
  fetchRebootsPlayers,

  createReboots,
  putReboots,
  deleteReboots,
};
