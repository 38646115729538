import { useState, useEffect } from "react";

import { CARD_BP, CARD_WITH_BAR_BP } from "constants";
import { mobile, tablet, desktop } from "constants/devices";

const getLayout = () => {
  const { innerWidth: width, innerHeight: height } = window;

  const isMobile = width < mobile.SCREEN_WIDTH;
  const isTablet = width >= mobile.SCREEN_WIDTH && width < tablet.SCREEN_WIDTH;
  const isDesktop = width >= tablet.SCREEN_WIDTH;
  const gridBreakPoints = isDesktop ? CARD_WITH_BAR_BP : CARD_BP;

  // console.log(document.getElementsByClassName("caps-main-flex-content")[0].offsetWidth)

  return {
    isMobile,
    isTablet,
    isDesktop,
    type: isMobile ? mobile.LABEL : (isTablet ? tablet.LABEL : desktop.LABEL),
    width,
    height,
    gridBreakPoints,
  };
}

const useLayout = () => {
  const [layout, setLayout] = useState(getLayout());

  useEffect(() => {
    const handleResize = () =>  {
      setLayout(getLayout());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return layout;
};

export default useLayout;
