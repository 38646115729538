import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";
import { analytics } from "constants/endpoints";

const fetchAnalytics = createAsyncThunk("analytics", async (query = "") => {
  const response = await fetcher(`${analytics.ALL}${query}`);
  return response.json();
});

const initialState = {
  data: {},
  status: loadingStatus.IDLE,
  error: null,
};

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAnalytics.pending, (state, action) => {
        state.status = loadingStatus.LOADING;
      })
      .addCase(fetchAnalytics.fulfilled, (state, action) => {
        state.status = loadingStatus.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(fetchAnalytics.rejected, (state, action) => {
        state.status = loadingStatus.FAILED;
        state.error = `${action.error.name}: ${action.error.message}`;
      })
  }
});

export default analyticsSlice.reducer;

export {
  fetchAnalytics,
};
