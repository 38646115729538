import { useSelector } from "react-redux";
import { IconButton, ButtonGroup } from "rsuite";
import {
  MdEdit,
  MdContentCopy,
  MdDeleteOutline,
  MdOutlineStopCircle,
  MdOutlinePlayCircle,
} from "react-icons/md";

import { patchPlaylist } from "redux/slices/playlistSlice";
import usePopup from "hooks/usePopup";
import HeaderComponent from "components/HeaderComponent";
import { CAMPAIGNS_STATES } from "constants";

const PlaylistsSettingComponent = ({ reload, onEdit, onClone, onDelete }) => {
  const { data: playlist } = useSelector((state) => state.playlist);
  const { responseToaster } = usePopup();

  const handleForceFinishPlaylist = async () => {
    const res = await patchPlaylist(playlist.id, { terminated: true });
    responseToaster(res);
    reload((prev) => !prev);
  };

  const handleForceNewDatetime = async () => {
    const res = await patchPlaylist(playlist.id, {
      new_end_datetime: playlist.revision.data.resume.until,
    });
    responseToaster(res);
    reload((prev) => !prev);
  };

  return (
    <div>
      <HeaderComponent title={playlist.name} tag="h3" gutter={0} />

      <HeaderComponent title="Управление" tag="h6" className="inner-header" />
      <ButtonGroup justified>
        <IconButton
          title="Редактировать"
          appearance="ghost"
          icon={<MdEdit size="1.4em" />}
          onClick={() => onEdit(playlist)}
        />
        <IconButton
          title="Клонировать"
          appearance="ghost"
          icon={<MdContentCopy size="1.4em" />}
          onClick={() => onClone(playlist)}
        />
        {playlist.state === CAMPAIGNS_STATES.finished.name && playlist.revision.data.resume && (
          <IconButton
            title="Продолжить"
            appearance="ghost"
            icon={<MdOutlinePlayCircle size="1.4em" />}
            onClick={handleForceNewDatetime}
          />
        )}
        {playlist.state === CAMPAIGNS_STATES.running.name && (
          <IconButton
            title="Приостановить"
            appearance="ghost"
            icon={<MdOutlineStopCircle size="1.4em" />}
            onClick={handleForceFinishPlaylist}
          />
        )}
        <IconButton
          title="Удалить"
          appearance="ghost"
          icon={<MdDeleteOutline size="1.4em" />}
          onClick={() => onDelete(playlist.id)}
        />
      </ButtonGroup>
    </div>
  );
};

export default PlaylistsSettingComponent;
