import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";
import { players } from "constants/endpoints";

const fetchPlayers = createAsyncThunk("players", async (query = "") => {
  const response = await fetcher(`${players.ALL}${query}`);
  return response.json();
});

const fetchPlayersReport = createAsyncThunk("campaigns/report", async (query = "") => {
  const response = await fetcher(`${players.ALL}${query}`);
  return response.blob();
});

const initialState = {
  objects: [],
  meta: {},
  status: loadingStatus.IDLE,
  error: null,
};

const playersSlice = createSlice({
  name: "players",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPlayers.pending, (state, action) => {
        state.status = loadingStatus.LOADING;
      })
      .addCase(fetchPlayers.fulfilled, (state, action) => {
        state.status = loadingStatus.SUCCEEDED;
        // Add any fetched posts to the array
        state.objects = action.payload.objects;
        state.meta = action.payload.meta;
      })
      .addCase(fetchPlayers.rejected, (state, action) => {
        state.status = loadingStatus.FAILED;
        state.error = `${action.error.name}: ${action.error.message}`;
      })
  }
});

export default playersSlice.reducer;

// export const { postAdded, postUpdated, reactionAdded } = playerSlice.actions;
export {
  fetchPlayers,
  fetchPlayersReport,
};
