import SideNavComponent from "components/SideNavComponent";
import { sideNavPlayers } from "helpers/navs";

const PlayersSideNavComponent = ({ handleExportAction }) => {
  const navActionHandlers = {
    "players-export-action": handleExportAction,
  };

  return (
    <SideNavComponent
      navActionHandlers={navActionHandlers}
      sideNavItems={sideNavPlayers}
    />
  );
};

export default PlayersSideNavComponent;
