import { useEffect, useRef, useState } from "react";
import { matchMax } from "helpers/utils";
import { Tag } from "rsuite";

const DrawWindowsComponent = ({ windows, removeWindow }) => {
  const [ratio, setRatio] = useState(1);
  const canvasRef = useRef();

  const canvasWidth = matchMax(windows.map(w => (w.x + w.width)));
  const canvasHeight = matchMax(windows.map(w => (w.y + w.height)));

  useEffect(() => {
    if (canvasWidth > canvasRef.current.offsetWidth) {
      setRatio((canvasWidth / canvasRef.current.offsetWidth));
    }
  }, [canvasWidth, canvasHeight]);

  // console.log(windows)

  return (
    <>
      <div
        className="windows-canvas"
        style={{ width: canvasWidth / ratio, height: canvasHeight / ratio }}
      >
        {windows.map((window, index) => (
          <div
            key={window.player_id}
            style={{
              border: "1px dashed #FFF",
              left: window.x / ratio,
              top: window.y / ratio,
              height: window.height / ratio,
              width: window.width / ratio,
            }}
          >
            <Tag
              style={{ margin: 2, whiteSpace: "nowrap" }}
              closable={!window.is_parent}
              onClick={() => removeWindow(window)}
            >
              {window.is_parent ? "Основное окно" : `Окно ${index}`}
            </Tag>
          </div>
        ))}
      </div>
      <div ref={canvasRef}></div>
    </>
  )
};

export default DrawWindowsComponent;
