const DRAWERS = {
  export: { key: "export" },
  filters: { key: "filters" },
  // batchPlayers: { key: "batchPlayers", width: "90%" },
};

const contentBarContents = {
  PLAYERS_OVERVIEW: "players_overview",
  PLAYERS_FORM: "players_form",
  PLAYER_DETAIL: "player_detail",
};

const PAGE_FILTERS = {
  all: { label: "Все устройства", value: "" },
  online: { label: "Онлайн устройства", value: "type=single&online=1" },
  offline: { label: "Офлайн устройства", value: "type=single&online=0" },
  not_activated: { label: "Не активированные", value: "type=single&activated=0" },
};

const SORTS = {
  "created_at.asc": { label: "По дате ▲", value: "" },
  "created_at.desc": { label: "По дате ▼", value: "order_by=created_at.desc" },
  "name.asc": { label: "По имени ▲", value: "order_by=name.asc" },
  "name.desc": { label: "По имени ▼", value: "order_by=name.desc" },
};

export {
  DRAWERS, contentBarContents, PAGE_FILTERS, SORTS
};
