import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Form,
  ButtonToolbar,
  Button,
  Panel,
  Schema,
  SelectPicker,
  Checkbox,
} from "rsuite";

import InputFieldComponent from "components/forms/InputFieldComponent";
import HeaderComponent from "components/HeaderComponent";
import usePopup from "hooks/usePopup";
import { buildDeepObjectFromFlat } from "helpers/utils";
import { fetchOrganization, patchOrganization } from "redux/slices/settingsSlice";
import { organizationFormInitialData } from "constants/form";

const { NumberType } = Schema.Types;
const model = Schema.Model({
  ad_campaign_content_prefetch_days: NumberType().isRequired("Обязательное поле"),
});

const System = () => {
  const formRef = useRef();
  const [formValue, setFormValue] = useState(organizationFormInitialData);
  const dispatch = useDispatch();
  const { organization } = useSelector((state) => state.settings);
  const { responseToaster } = usePopup();

  const handleChecked = (_, checked, event) => {
    setFormValue({
      ...formValue,
      [event.target.name]: checked,
    });
  };

  const handleSubmit = async () => {
    if (!formRef.current.check()) {
      return;
    }
    const res = await patchOrganization(buildDeepObjectFromFlat({
      ...formValue,
      sssp_pin: formValue.sssp_pin || null,
    }));
    responseToaster(res);
  };

  useEffect(() => {
    dispatch(fetchOrganization());
  }, [dispatch]);

  useEffect(() => {
    if (organization.requirements) {
      setFormValue({
        ...formValue,
        "requirements.orientation": organization.requirements.orientation,
        ad_campaign_content_prefetch_days: organization.ad_campaign_content_prefetch_days,
        sssp_pin: organization.sssp_pin || "",
        audio_filler_behaviour: organization.audio_filler_behaviour,

        filler_shuffle: organization.filler_shuffle,
        audio_filler_shuffle: organization.audio_filler_shuffle,
        filler_programmatic: organization.filler_programmatic,
        ad_campaign_perfect_reports: organization.ad_campaign_perfect_reports,
        ad_campaign_auto_synchronize: organization.ad_campaign_auto_synchronize,
        ad_campaign_each_n_cluster: organization.ad_campaign_each_n_cluster,
        transcode: organization.transcode,
      });
    }
  }, [organization]);

  return (
    <>
      <HeaderComponent title="Настройки" />
      <div className="caps-content">
        <Panel header={<h3>Системные настройки</h3>} bordered>
          <br />
          <Form
            model={model}
            ref={formRef}
            onChange={setFormValue}
            formValue={formValue}
            fluid
          >
            <InputFieldComponent
              accepter={SelectPicker}
              name="requirements.orientation"
              label="Ориентация устройств и контента"
              data={[
                { label: "Горизонтальная", value: "landscape" },
                { label: "Вертикальная", value: "portrait" },
              ]}
              cleanable={false}
              searchable={false}
              block
            />
            <InputFieldComponent
              name="ad_campaign_content_prefetch_days"
              label="Загружать контент на N суток"
            />
            <InputFieldComponent
              name="sssp_pin"
              label="Пин-код для SSSP устройств (4 цифры)"
            />
            <InputFieldComponent
              accepter={SelectPicker}
              name="audio_filler_behaviour"
              label="Режим аудиофиллера"
              data={[
                {
                  label: "Ставить на паузу и продолжать с того же места",
                  value: "continue_previous",
                },
                {
                  label: "Играть следующий Аудио после видео",
                  value: "start_next",
                },
              ]}
              cleanable={false}
              searchable={false}
              block
            />
            <hr />
            <Checkbox
              name="filler_shuffle"
              checked={formValue.filler_shuffle}
              onChange={handleChecked}
            >
              Филлеры в случайном порядке
            </Checkbox>
            <br />
            <Checkbox
              name="audio_filler_shuffle"
              checked={formValue.audio_filler_shuffle}
              onChange={handleChecked}
            >
              Shuffle audio filler
            </Checkbox>
            <br />
            <Checkbox
              name="filler_programmatic"
              checked={formValue.filler_programmatic}
              onChange={handleChecked}
            >
              Использовать филлеры для продажи через программатик
            </Checkbox>
            <br />
            <Checkbox
              name="ad_campaign_perfect_reports"
              checked={formValue.ad_campaign_perfect_reports}
              onChange={handleChecked}
            >
              Идеальная статистика
            </Checkbox>
            <br />
            <Checkbox
              name="ad_campaign_auto_synchronize"
              checked={formValue.ad_campaign_auto_synchronize}
              onChange={handleChecked}
            >
              Autosynchronize players
            </Checkbox>
            <br />
            <Checkbox
              name="ad_campaign_each_n_cluster"
              checked={formValue.ad_campaign_each_n_cluster}
              onChange={handleChecked}
            >
              Собирать в кластеры контент кампаний
            </Checkbox>
            <br />
            <Checkbox
              name="transcode"
              checked={formValue.transcode}
              onChange={handleChecked}
            >
              Транскодировать все видеофайлы
            </Checkbox>
            <hr />
            <Form.Group>
              <ButtonToolbar>
                <Button appearance="primary" onClick={handleSubmit}>
                  Сохранить
                </Button>
              </ButtonToolbar>
            </Form.Group>
          </Form>
        </Panel>
      </div>
    </>
  );
};

export default System;
