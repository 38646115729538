import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher from "helpers/fetcher";
import { notifications, players } from "constants/endpoints";

const fetchRobotNotifications = createAsyncThunk("notifications/robot", async () => {
  const response = await fetcher(notifications.ROBOT_NOTIFICATIONS);
  return response.json();
});

const fetchManualNotifications = createAsyncThunk("notifications/manual", async () => {
  const response = await fetcher(notifications.MANUAL_NOTIFICATIONS);
  return response.json();
});

const fetchSyncNotifications = createAsyncThunk("notifications/sync", async () => {
  const response = await fetcher(`${players.ALL}?od_campaign_dirty=1`);
  return response.json();
});

const patchManualNotification = async (id, payload) => {
  fetcher(`${notifications.MANUAL_NOTIFICATIONS}/${id}`, {
    method: "PATCH",
    body: JSON.stringify(payload),
  });
};

const postSyncData = async () => {
  const response = await fetcher(`${notifications.SYNC_DATA}?dirty=1`, {
    method: "POST",
    body: JSON.stringify({}),
  });
  return response;
};

const initialState = {
  robotNotifications: [],
  manualNotifications: [],
  syncNotifications: [],
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchRobotNotifications.fulfilled, (state, action) => { state.robotNotifications = action.payload; })
      .addCase(fetchManualNotifications.fulfilled, (state, action) => { state.manualNotifications = action.payload; })
      .addCase(fetchSyncNotifications.fulfilled, (state, action) => { state.syncNotifications = action.payload.objects; })
  }
});

export default notificationsSlice.reducer;

export {
  fetchRobotNotifications,
  fetchManualNotifications,
  fetchSyncNotifications,
  patchManualNotification,
  postSyncData,
};
