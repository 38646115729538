import { useSelector } from "react-redux";
import { Message, Avatar, Tag, Whisper, Tooltip } from "rsuite";
import { MdInfoOutline } from "react-icons/md";

import LoaderComponent from "components/LoaderComponent";
import ErrorComponent from "components/ErrorComponent";
import FileItemComponent from "components/files/FileItemComponent";
import HeaderComponent from "components/HeaderComponent";
import { loadingStatus } from "helpers/fetcher";
import { convertBytes, getThumbnail } from "helpers/utils";
import { MIN_DURATION } from "constants";

const FilesListComponent = ({
  items,
  folderItem,
  status,
  error,
  onClick,
  onEdit,
  onRemove,
}) => {
  const { contentListView } = useSelector(state => state.layout);

  if (status === loadingStatus.FAILED) {
    return <ErrorComponent message={error} />;
  }

  return (
    <>
      {folderItem && <HeaderComponent title={`Файлы рекламодателя "${folderItem.name}"`} tag="h6" className="mb-10 ml-10" />}
      {status === loadingStatus.SUCCEEDED && items.length === 0 ? (
        <Message type="warning">Файлов нет</Message>
      ) : (
        <div className={`item-list ${contentListView}`}>
          {status !== loadingStatus.SUCCEEDED && <LoaderComponent size="md" />}
          {items.map(contentItem => {
            const file = contentItem.content_files[0].content_file;
            const duration = contentItem.data.default_duration || file.data.duration;
            return (
              <FileItemComponent
                key={`file-${contentItem.id}`}
                id={contentItem.id}
                icon={<Avatar src={getThumbnail(contentItem)} size="lg" />}
                title={contentItem.name}
                subTitle={
                  <>
                    <Tag>
                      {convertBytes(file.size).mb}
                    </Tag>
                    <Tag>
                      {Number.isInteger(duration) ? (
                        `${duration} сек`
                      ) : (
                        duration > MIN_DURATION ? (
                          <Whisper
                            placement="top"
                            trigger="hover"
                            speaker={<Tooltip>{`Обрезано с ${duration} сек`}</Tooltip>}
                          >
                            <MdInfoOutline color="#3498FF" /> {`${Math.floor(duration)} сек`}
                          </Whisper>
                        ) : (
                          <Whisper
                            placement="top"
                            trigger="hover"
                            speaker={<Tooltip>{`Дорощено с ${duration} сек`}</Tooltip>}
                          >
                            <MdInfoOutline color="#3498FF" /> {`${MIN_DURATION} сек`}
                          </Whisper>
                        )
                      )}
                    </Tag>
                  </>
                }
                onClick={() => onClick(contentItem)}
                onEdit={() => onEdit(contentItem)}
                onRemove={() => onRemove(contentItem.id)}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default FilesListComponent;
