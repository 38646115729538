import AnalyticsListComponent from "components/analytics/AnalyticsListComponent";
import DesktopLayout from "components/layouts/DesktopLayout";

const Desktop = ({
  data,
  filter,
  sort,
  contentBar,
  handleAnalyticsDetailButton,
  onContentbarClose,
}) => (
  <DesktopLayout contentBar={contentBar} onContentbarClose={onContentbarClose}>
    <div className="analytic-page">
      <AnalyticsListComponent
        items={data.data}
        filter={filter}
        sort={sort}
        status={data.status}
        error={data.error}
        onClick={handleAnalyticsDetailButton}
      />
    </div>
  </DesktopLayout>
);

export default Desktop;
