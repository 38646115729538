import { useState, useEffect, useMemo } from "react";

const useFilter = ({ filters, sorts, externalParams = [] }) => {
  const [filterKey, setFilterKey] = useState(Object.keys(filters)[0]);
  const [sortKey, setSortKey] = useState(Object.keys(sorts)[0]);
  const [additionalParams, setAdditionalParams] = useState({});
  // const [queryString, setQueryString] = useState();

  const setAdditionalParam = (key, value) => {
    setAdditionalParams(p => ({ ...p, [key]: value }));
  };

  const getQueryString = () => {
    const q = [];
    if (filterKey) q.push(filters[filterKey].value);
    if (Object.keys(additionalParams).length > 0) {
      for(const [k, v] of Object.entries(additionalParams)) {
        if (v) q.push(`${k}=${v}`);
      }
    }
    if (sortKey) q.push(sorts[sortKey].value);

    const qParams = q.filter(Boolean);
    const qString = qParams.length > 0 ? `?${qParams.join("&")}` : "";

    return qString;
  };

  // при перезагрузке восстановить фильтры и сортировку из адреса
  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
  
    if (externalParams.length > 0) {
      const _additionalParams = {};
      for(const name of externalParams) {
        if (search.has(name)) {
          _additionalParams[name] = search.get(name);
          search.delete(name);
        }
      };
      setAdditionalParams(_additionalParams);
    }
  
    // сортировка в отдельном стейте
    if (search.has("order_by")) {
      setSortKey(search.get("order_by"));
      search.delete("order_by");
    }

    // остались только фильтры
    if (search.size > 0) {
      const f = Object.entries(filters).filter(p => (p[1].value === search.toString()));
      setFilterKey(f[0][0]);
    }
  }, []);

  const queryString = useMemo(() => getQueryString(), [filterKey, sortKey, additionalParams]);
  // useEffect(() => {
  //   setQueryString(getQueryString());
  // }, [filterKey, sortKey, additionalParams]);
  
  return {
    filterKey,
    sortKey,
    additionalParams,
    queryString,
    setFilterKey,
    setSortKey,
    setAdditionalParam,
  };
};

export default useFilter;
