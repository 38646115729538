import { Drawer } from "rsuite";

const DrawerComponent = ({ title, children, open, onClose, size, placement }) => {
  return (
    <Drawer open={open} onClose={onClose} size={size} placement={placement}>
      {title && (
        <Drawer.Header>
          <Drawer.Title>{title}</Drawer.Title>
          {/* <Drawer.Actions>
            <Button onClick={onClose}>Закрыть</Button>
          </Drawer.Actions> */}
        </Drawer.Header>
      )}
      <Drawer.Body>
        {children}
      </Drawer.Body>
    </Drawer>
  );
};

export default DrawerComponent;
