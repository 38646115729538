import { useState } from "react";

import DrawerComponent from "components/DrawerComponent";
import AnalyticsListComponent from "components/analytics/AnalyticsListComponent";

const Mobile = ({
  data,
  filter,
  sort,
  contentBar,
  handleAnalyticsDetailButton,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleOnAnalyticsDetailButton = (item) => {
    setDrawerOpen(true);
    handleAnalyticsDetailButton(item);
  };

  const handleOnCloseDrawer = () => setDrawerOpen(false);

  return (
    <>
      <div className="analytic-page">
        <AnalyticsListComponent
          items={data.data}
          filter={filter}
          sort={sort}
          status={data.status}
          error={data.error}
          onClick={handleOnAnalyticsDetailButton}
        />
      </div>
      <DrawerComponent
        open={drawerOpen}
        onClose={handleOnCloseDrawer}
        size="full"
        title="Детали записи"
      >
        {contentBar}
      </DrawerComponent>
    </>
  );
};

export default Mobile;
