import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";
import { content } from "constants/endpoints";

const fetchFolders = createAsyncThunk("folders", async (query = "") => {
  const response = await fetcher(`${content.FOLDERS}${query}`);
  return response.json();
});

const getFiles = async (q) => {
  const response = await fetcher(`${content.FILES}${q}`);
  return response.json();
};

const fetchFiles = createAsyncThunk("folders/files", async (q = "") => {
  return await getFiles(q);
});

const createFolder = async (files) => {
  const response = await fetcher(content.FOLDERS, {
    method: "POST",
    body: JSON.stringify(files),
  });
  return response.json();
};

const putFolder = async (id, data) => {
  const response = await fetcher(`${content.FOLDERS}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
  return response.json();
};

const deleteFolder = async id => fetcher(`${content.FOLDERS}/${id}`, { method: "DELETE" });

const saveFile = async (files) => {
  const response = await fetcher(content.FILES, {
    method: "POST",
    body: JSON.stringify(files),
  });
  return response;
};

const putFile = async (id, data) => {
  const response = await fetcher(`${content.FILES}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
  return response;
};

const deleteFile = async id => fetcher(`${content.FILES}/${id}`, { method: "DELETE" });

// const patchPlayer = async (playerId, data) => {
//   const response = await fetcher(`${players.ALL}/${playerId}`, {
//     method: "PATCH",
//     body: JSON.stringify(data),
//   });
//   return response.json();
// };

const initialState = {
  folders: {
    objects: [],
    meta: {},
    status: loadingStatus.IDLE,
    error: null,
  },
  files: {
    objects: [],
    meta: {},
    status: loadingStatus.IDLE,
    error: null,
  },
};

const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    addFiles(state, { payload }) {
      state.files.objects = [...state.files.objects, ...payload];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFolders.pending, (state, action) => {
        state.folders.status = loadingStatus.LOADING;
      })
      .addCase(fetchFolders.fulfilled, (state, action) => {
        state.folders.status = loadingStatus.SUCCEEDED;
        state.folders.objects = action.payload.objects;
        state.folders.meta = action.payload.meta;
      })
      .addCase(fetchFolders.rejected, (state, action) => {
        state.folders.status = loadingStatus.FAILED;
        state.folders.error = `${action.error.name}: ${action.error.message}`;
      })

      .addCase(fetchFiles.pending, (state, action) => {
        state.files.status = loadingStatus.LOADING;
      })
      .addCase(fetchFiles.fulfilled, (state, action) => {
        state.files.status = loadingStatus.SUCCEEDED;
        state.files.objects = action.payload.objects;
        state.files.meta = action.payload.meta;
      })
      .addCase(fetchFiles.rejected, (state, action) => {
        state.files.status = loadingStatus.FAILED;
        state.files.error = `${action.error.name}: ${action.error.message}`;
      })
  }
});

export const { addFiles } = filesSlice.actions;

export default filesSlice.reducer;

export {
  fetchFolders,
  getFiles,
  fetchFiles,

  createFolder,
  putFolder,
  deleteFolder,

  saveFile,
  putFile,
  deleteFile,
};
