const players = {
  ALL: "/players",
  MAP: "/players/map",
  REBOOTS: "/players/reboots",
  BRIGHTNESS: "/players/brightness",
  TAGS: "/players_tags",
  CAMPAIGNS: "/campaigns",
};

const contents = {
  FILES: "/files",
  WIDGETS: "/widgets", // ?order_by=id.asc&limit=-1
  TAGS: "/tags",
};

const campaigns = {
  ALL: "/campaigns",
  FILLERS: "/campaigns/fillers",
  REPORT_SCHEDULES: "/campaigns/report-schedules",
};

const playlists = {
  ALL: "/playlists",
};

const analytics = {
  ALL: "/analytics",
};

const settings = {
  ALL: "/settings",
  ACCOUNTS: "/settings/accounts",
  DOWNLOADS: "/settings/downloads",
  PASSWORD: "/settings/password",
  PROFILE: "/settings/profile",
  ROLES: "/settings/roles",
  SYSTEM: "/settings/system",
};

const auth = {
  LOGIN: "/login",
  FORGOT: "/forgot",
  RESET: "/reset",
};

export {
  players,
  contents,
  campaigns,
  playlists,
  analytics,
  settings,
  auth,
};
