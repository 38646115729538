import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Input,
  InputGroup,
  CheckTree,
  Tag,
} from "rsuite";
import { MdSearch } from "react-icons/md";

import ErrorComponent from "components/ErrorComponent";
import InputFieldComponent from "components/forms/InputFieldComponent";
import { fetchCampaignPreviewPlayers } from "redux/slices/campaignSlice";
import { loadingStatus } from "helpers/fetcher";
import { playersHierarchyBuilder } from "helpers/player";
import { formatDate } from "helpers/date";

const PreviewPlayersComponent = ({
  share,
  campaignPlayers,
  selectedPreviewPlayers,
  onChangePreviewPlayers,
  query,
  error,
}) => {
  const [filteredPreviewPlayers, setFilteredPreviewPlayers] = useState([]);
  const dispatch = useDispatch();
  const { previewPlayers } = useSelector(state => state.campaign);

  const handleFilterPreviewPlayers = (val) => {
    const filteredVal = previewPlayers.objects.filter(item => item.player.name.toLowerCase().includes(val.toLowerCase()));
    const previewObjects = playersHierarchyBuilder(filteredVal, previewPlayers.objects);    
    setFilteredPreviewPlayers(previewObjects);
  };

  useEffect(() => {
    const previewObjects = playersHierarchyBuilder(previewPlayers.objects);    
    setFilteredPreviewPlayers(previewObjects);
  // eslint-disable-next-line
  }, [previewPlayers]);

  useEffect(() => {
    const queryObject = {
      ...query,
      requirements: JSON.stringify({ orientation: "landscape" }),
      type: "normal",
      player_type: "single",
      allow_unavailable: 1,
      tags_op: "or",
      limit: -1,
    };
    const searchParams = `?${(new URLSearchParams(queryObject)).toString()}`;
    dispatch(fetchCampaignPreviewPlayers(searchParams));
  }, [dispatch]);

  return (
    <div className="campaigns-form_preview-players">
      <div className="campaigns-form_preview-players-header">
        <h5>Выберите устройства</h5>
      </div>

      <InputFieldComponent label="">
        <InputGroup>
          <InputGroup.Addon>
            <MdSearch />
          </InputGroup.Addon>
          <Input onChange={handleFilterPreviewPlayers} />
          <InputGroup.Button>
            <MdSearch />
          </InputGroup.Button>
        </InputGroup>
      </InputFieldComponent>

      {error ? <ErrorComponent message="Выберите устройства" /> : ""}

      <div className="campaigns-form_content-items-available">
        {previewPlayers.status === loadingStatus.LOADING && "Загрузка..."}
        {filteredPreviewPlayers.length === 0 && "Пусто"}
        <CheckTreeComponent
          share={share}
          campaignPlayers={campaignPlayers}
          items={filteredPreviewPlayers}
          onChange={onChangePreviewPlayers}
          defaultValue={selectedPreviewPlayers}
        />
      </div>

    </div>
  );
};

const CheckTreeComponent = ({ share, campaignPlayers, items, onChange, defaultValue = [] }) => {
  const data = [];
  const unavailables = [];
  items.forEach(item => {
    data.push({
      value: item.player.id,
      data: item,
      children: null, // item.children
    });
    const unavailable = item.calendar.filter(day => {
      return campaignPlayers.findIndex((c) => c.player.id === item.player.id) !== -1
        ? day.share_left + share < 0
        : day.available === false;
    });
    if (unavailable.length > 0) unavailables.push(item.player.id);
  });

  return (
    <CheckTree
      height={320}
      cascade={false}
      defaultValue={defaultValue}
      defaultExpandAll={true}
      data={data}
      uncheckableItemValues={unavailables}
      renderTreeNode={({ data: { calendar, player, children } }) => {
        return (
          <Tag
            key={`${player.id}`}
            className="campaigns-form_content-items-item"
            size="lg"
            color="blue"
          >
            {player.name}
            {unavailables.includes(player.id) ? (
              <div className="campaigns-content-item-disable">
                <h6 className="red">Недоступно в даты</h6>
                {calendar.map(({ date, share_left: shareLeft }, index) => (
                  <Tag color="red" key={index} className="mb-5 ml-0 mr-10">
                    {formatDate(date, ".").full_datetime}{" "}
                  </Tag>
                ))}
              </div>
            ) : (
              ""
            )}
          </Tag>
        );
      }}
      onChange={values => onChange(values)}
    />
  );
};

export default PreviewPlayersComponent;
