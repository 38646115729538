import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  IconButton,
  Input,
  SelectPicker,
  Message,
  InputGroup,
  Avatar,
  Tag,
  Loader,
} from "rsuite";
import { MdSearch, MdAdd, MdExpandMore } from "react-icons/md";
import InfiniteScroll from "react-infinite-scroller";

import ErrorComponent from "components/ErrorComponent";
import InputFieldComponent from "components/forms/InputFieldComponent";
import FileItemComponent from "components/files/FileItemComponent";
import DraggableFilesComponent from "components/draggable/DraggableFilesComponent";
import HeaderComponent from "components/HeaderComponent";
import { fetchFolders, fetchFiles, getFiles, addFiles } from "redux/slices/filesSlice";
import { loadingStatus } from "helpers/fetcher";
import nextItems from "helpers/next-items";
import { convertBytes, getThumbnail } from "helpers/utils";

const filesQueryObject = {
  requirements: JSON.stringify({ orientation: "landscape" }),
};

const FilesComponent = ({
  selectedFiles,
  setSelectedFiles,
  onSelectFiles,
  onRemoveFile,
  error,
}) => {
  const [selectedFolderVal, setSelectFolderVal] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [showAdvFields, setShowAdvFields] = useState(false);
  const dispatch = useDispatch();
  const { folders, files } = useSelector(state => state.files);
  const queryString = useRef()

  const handleFoldersFilter = (value = null) => {
    if (value > 0) {
      const queryObject = {
        ...filesQueryObject,
        odvertiser_id: value,
      };
      queryString.current = `?${(new URLSearchParams(queryObject)).toString()}`
      dispatch(fetchFiles(queryString.current));
    }
    setSelectFolderVal(value);
  };

  const handleFilterFiles = (val) => {
    const filteredVal = files.objects.filter(item => item.name.toLowerCase().includes(val.toLowerCase()));
    setFilteredFiles(filteredVal);
  };

  const onLoadMore = async () => {
    const _items = await nextItems(queryString.current, files.meta.offset, files.meta.limit, getFiles);
    if (_items.objects.length > 0) dispatch(addFiles(_items.objects));
  };

  const handleToggleAdvFields = () => {
    setShowAdvFields(!showAdvFields);
  };

  useEffect(() => {
    if (files.objects) setFilteredFiles(files.objects);
    else setFilteredFiles([]);
  }, [files, selectedFolderVal]);

  useEffect(() => {
    dispatch(fetchFolders("?limit=-1"));
    handleFoldersFilter(selectedFolderVal);
  }, [selectedFolderVal]);
 
  useEffect(() => {
    queryString.current = `?${(new URLSearchParams(filesQueryObject)).toString()}`
    dispatch(fetchFiles(queryString.current));
  }, []);

  return (
    <div className="campaigns-form_content-items">

      <div className="campaigns-form_content-items-header">
        <HeaderComponent
          title={
            <>
              Фильтр файлов {" "}
              <IconButton
                appearance="subtle"
                icon={<MdExpandMore size="1.4em" />}
                onClick={handleToggleAdvFields}
              />
            </>
          }
          tag="h6"
          gutter={0}
          className="mb-0"
        />
        {showAdvFields && (
          <>
            <InputFieldComponent label="Рекламодатели">
              {(folders.objects.length === 0) && (<Message type="warning">Нет ни одной папки</Message>)}
              <SelectPicker
                data={folders.objects.map(({ id, name }) => ({ label: name, value: id }))}
                value={selectedFolderVal}
                onChange={handleFoldersFilter}
                cleanable={false}
                block
              />
            </InputFieldComponent>
            <InputFieldComponent>
              <InputGroup>
                <Input onChange={handleFilterFiles} placeholder="Поиск по имени" />
                <InputGroup.Button>
                  <MdSearch />
                </InputGroup.Button>
              </InputGroup>
            </InputFieldComponent>
          </>
        )}
      </div>

      {error ? <ErrorComponent message="Выберите файлы" /> : ""}

      <HeaderComponent title="Выберите файлы" tag="h6" gutter={0} className="mb-0" />
      <div className="campaigns-form_content-items-available">
        <InfiniteScroll
          initialLoad={false}
          threshold={0}
          hasMore={files.status !== loadingStatus.LOADING && files.objects.length < files.meta.total}
          loader={<p className="text-center"><Loader size="sm" /></p>}
          loadMore={onLoadMore}
          useWindow={false}
        >
          {files.status === loadingStatus.LOADING && "Загрузка..."}
          <div className="item-list">
            {filteredFiles.map((contentItem, index) => {
              const sum = selectedFiles.filter(s => s.id === contentItem.id).length;
              const file = contentItem.content_files[0].content_file;
              const duration = `${contentItem.data.default_duration || file.data.duration} сек`;
              return (
                <div key={`${file.id}-${index}`} className="item-list__item">
                  <div className="item-list__item-actions">
                    <IconButton
                      icon={<MdAdd size="1.4em" />}
                      appearance="ghost"
                      onClick={() => onSelectFiles(contentItem)}
                    />
                  </div>
                  <div className="item-list__item-content">
                    <FileItemComponent
                      badge={sum}
                      icon={<Avatar src={getThumbnail(contentItem)} size="lg" />}
                      title={contentItem.name}
                      subTitle={<><Tag>{convertBytes(file.size).mb}</Tag> <Tag>{duration}</Tag></>}
                      wrapClassName=""
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </InfiniteScroll>
      </div>

      <hr />
      <div className="campaigns-form_content-items-selected">
        <DraggableFilesComponent
          items={selectedFiles}
          setItems={setSelectedFiles}
          removeItem={onRemoveFile}
        />
      </div>
    </div>
  );
};

export default FilesComponent;
