const USER_SETTINGS = {
  "tag-drawer-state": false,
  "side-nav-state": true,
  "content-list-view": "grid",
  "player-list-view": "grid",
  "campaign-list-view": "grid",
  "playlist-list-view": "grid",
};

export {
  USER_SETTINGS,
};
