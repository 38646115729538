const SelectComponent = ({
  className,
  items,
  value,
  onChange,
}) => (
  <div className={`rs-form-control rs-form-control-wrapper caps-select ${className}`}>
    <select
      className="rs-input"
      value={value}
      onChange={e => onChange(e.target.value)}
    >
      {items.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  </div>
);

export default SelectComponent;
