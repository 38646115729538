import { USER_SETTINGS } from "constants/settings";

const getUserSettings = () => JSON.parse(localStorage.getItem("user-settings") || "{}");

const setUserSettings = settings => localStorage.setItem("user-settings", settings);

const getUserSetting = (key) => {
  const settings = getUserSettings();
  return settings[key] ?? USER_SETTINGS[key];
};

const setUserSetting = (key, val) => {
  const settings = getUserSettings();
  settings[key] = val;
  setUserSettings(JSON.stringify(settings));
};

export {
  getUserSetting,
  setUserSetting,
};
