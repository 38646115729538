import React from "react";
import { Provider } from "react-redux";
import { CustomProvider } from "rsuite";
import ruRU from 'rsuite/locales/ru_RU';

import { store } from "redux/store";
import useLayout from "hooks/useLayout";
import Router from "Router";

const App = () => {
  const { isDesktop } = useLayout();

  return (
    <CustomProvider locale={ruRU}>
      <Provider store={store}>
        <div className={`caps-main ${isDesktop ? "caps-pc" : "caps-mobile"}`}>
          <Router />
        </div>
      </Provider>
    </CustomProvider>
  );
};

export default App;
