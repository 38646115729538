import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";
import { players, campaigns } from "constants/endpoints";

const fetchReports = createAsyncThunk("reports", async (query = "") => {
  const response = await fetcher(`${campaigns.REPORT_SCHEDULES}${query}`);
  return response.json();
});

const fetchReportsPlayers = createAsyncThunk("reports/players", async (query = "") => {
  const response = await fetcher(`${players.ALL}${query}`);
  return response.json();
});

const createReports = async (data) => {
  const response = await fetcher(campaigns.REPORT_SCHEDULES, {
    method: "POST",
    body: JSON.stringify(data),
  });
  return response;
};
const putReports = async (id, data) => {
  const response = await fetcher(`${campaigns.REPORT_SCHEDULES}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
  return response;
};

const deleteReports = (id) => fetcher(`${campaigns.REPORT_SCHEDULES}/${id}`, { method: "DELETE" });

const initialState = {
  objects: [],
  meta: {},
  status: loadingStatus.IDLE,
  error: null,
  formData: {},
  players: [],
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    fillForm: (state, action) => {
      state.formData = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchReports.pending, (state, action) => { state.status = loadingStatus.LOADING; })
      .addCase(fetchReports.fulfilled, (state, action) => {
        state.status = loadingStatus.SUCCEEDED;
        state.objects = action.payload.objects;
        state.meta = action.payload.meta;
      })

      .addCase(fetchReportsPlayers.fulfilled, (state, action) => { state.players = action.payload.objects; })
  }
});

export default reportsSlice.reducer;

export const { fillForm } = reportsSlice.actions;

export {
  fetchReports,
  fetchReportsPlayers,

  createReports,
  putReports,
  deleteReports,
};
