import { useSelector } from "react-redux";
import DrawerComponent from "components/DrawerComponent";

const DesktopLayout = ({
  sideBar,
  children,
  contentBar,
  onContentbarClose,
  template = "ccd",
}) => {
  const { sideNavState } = useSelector(state => state.layout);

  return (
    <>
      <div className="caps-main-flex">
        {sideBar ? (
          <div className={`caps-main-flex-left ${sideNavState === false ? "close" : ""}`}>
            <div className="caps-sidebar">
              {sideBar}
            </div>
          </div>
        ) : null}
        <div className={`caps-main-flex-content ${(sideBar || contentBar) && "sm"}`}>
          {children}
        </div>
        {(template === "ccc" && contentBar) && (
          <div className="caps-main-flex-right">
            <div className="caps-contentbar">
              {contentBar}
            </div>
          </div>
        )}
      </div>
      {template === "ccd" && (
        <DrawerComponent
          open={!!contentBar}
          onClose={onContentbarClose}
          size={650}
        >
          {contentBar}
        </DrawerComponent>
      )}
    </>
  );
};

export default DesktopLayout;
