import { memo, useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "rsuite";

import HeaderComponent from "components/HeaderComponent";
import DrawerComponent from "components/DrawerComponent";
import PlayersSideNavComponent from "components/player/PlayersSideNavComponent";
// import BrightnessSchedulesOverviewComponent from "components/player/brightness/BrightnessSchedulesOverviewComponent";
import BrightnessSchedulesFormComponent from "components/player/brightness/BrightnessSchedulesFormComponent";
import PlayerExportComponent from "components/player/PlayerExportComponent";

import { fetchBrightness, fillForm, addBrightness, getBrightness } from "redux/slices/brightnessSlice";
import useLayout from "hooks/useLayout";
import { brightnessFormInitialData } from "constants/form";
import Mobile from "./Mobile";
import Desktop from "./Desktop";
import nextItems from "helpers/next-items";
import InfiniteScroll from "react-infinite-scroller";
import { loadingStatus } from "helpers/fetcher";

const DRAWERS = { export: "export" };

const contentBarContents = {
  BRIGHTNESS_OVERVIEW: "brightness_overview",
  BRIGHTNESS_FORM: "brightness_form",
};

const Brightness = () => {
  const [currentContent, setCurrentContent] = useState(contentBarContents.BRIGHTNESS_OVERVIEW);
  const [drawerType, setDrawerType] = useState();

  const navigate  = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector(state => state.brightness);
  const { isDesktop } = useLayout();

  const SideBar = memo(() => <PlayersSideNavComponent handleExportAction={handleExportDrawerOpen} />);

  const handleFormButton = (_, brightnessId) => {
    dispatch(fillForm(brightnessFormInitialData));
    setCurrentContent(contentBarContents.BRIGHTNESS_FORM);
    navigate(`/players/brightness${brightnessId ? `/${brightnessId}` : ""}`);
  };

  const handleDrawerClose = () => setDrawerType();

  const handleExportDrawerOpen = () => setDrawerType(DRAWERS.export);

  const onLoadMore = async () => {
    const _items = await nextItems("", data.meta.offset, data.meta.limit, getBrightness);
    if (_items.objects.length > 0) dispatch(addBrightness(_items.objects));
  }

  const contentComponents = useMemo(() => ({
    [contentBarContents.BRIGHTNESS_OVERVIEW]: null,
    [contentBarContents.BRIGHTNESS_FORM]: <BrightnessSchedulesFormComponent />,
  }), []);

  useEffect(() => {
    dispatch(fetchBrightness());
  }, [dispatch]);

  const actions = { handleFormButton, onContentbarClose: () => setCurrentContent(null) };
  
  return (
    <>
      <HeaderComponent title="Расписания яркости" />
      <InfiniteScroll
        initialLoad={false}
        threshold={0}
        hasMore={data.status !== loadingStatus.LOADING && data.objects.length < data.meta.total}
        loader={<p className="text-center"><Loader size="md" /></p>}
        loadMore={onLoadMore}
      >
        {isDesktop ? (
          <Desktop
            data={data}
            sideBar={<SideBar />}
            contentBar={contentComponents[currentContent]}
            {...actions}
          />
        ) : (
          <Mobile
            data={data}
            sideBar={<SideBar />}
            contentBar={contentComponents[currentContent]}
            {...actions}
          />
        )}
      </InfiniteScroll>
      <DrawerComponent
        open={!!drawerType}
        onClose={handleDrawerClose}
        placement="left"
      >
        {drawerType === DRAWERS.export && <PlayerExportComponent />}
      </DrawerComponent>
    </>
  );

}

export default Brightness;
