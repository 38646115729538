import { memo, useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import HeaderComponent from "components/HeaderComponent";
import CampaignsSideNavComponent from "components/campaign/CampaignsSideNavComponent";
import FileDetailComponent from "components/files/FileDetailComponent";
import FilesFormComponent from "components/files/FilesFormComponent";
import { FilterFilesForm } from "components/campaign/fillers/filters";
import FilesEditFormComponent from "components/files/FilesEditFormComponent";
import { saveFile } from "redux/slices/filesSlice";
import { saveFiller } from "redux/slices/fillersSlice";
import useLayout from "hooks/useLayout";
import usePopup from "hooks/usePopup";
import { fetchFillers, deleteFiller, initialState } from "redux/slices/fillersSlice";
import Mobile from "./Mobile";
import Desktop from "./Desktop";
import { ButtonToolbar, IconButton, Popover, Whisper } from "rsuite";
import { MdTune } from "react-icons/md";

const getFillerType = ({ playback_item }) => {
  const t = ["video", "image"].includes(playback_item.content_items[0].content_item.type);
  return { isFile: t, isWidget: !t };
};

const SideBar = memo(() => <CampaignsSideNavComponent />);

const contentBarContents = {
  FOLDERS_OVERVIEW: "folders_overview",
  FOLDER_DETAIL: "folder_detail",

  FILE_DETAIL: "file_detail",
  FILES_FORM: "files_form",
  FILE_EDIT_FORM: "file_edit_form",
};

const Fillers = () => {
  const [currentContent, setCurrentContent] = useState(contentBarContents.FOLDERS_OVERVIEW);
  const [selectedContentItem, setSelectedContentItem] = useState();
  const [folders,] = useState([
    { id: "widgets", name: "Филлеры-виджеты" },
    { id: "files", name: "Филлеры из файлов" },
  ]);
  const [fillers, setFillers] = useState(initialState);
  const [reload, setReload] = useState();
  const navigate  = useNavigate();
  const dispatch = useDispatch();
  const { playersTags } = useSelector(state => state.tags);
  const data = useSelector(state => state.fillers);
  const { searchState } = useSelector(state => state.layout);
  const { isDesktop } = useLayout();
  const { responseToaster } = usePopup();
  const { "*": path } = useParams();
  const [filePathType] = useMemo(() => path.split("/"), [path]);

  const handleFolderDetailButton = (type) => {
    setCurrentContent(contentBarContents.FOLDER_DETAIL);
    navigate(`/campaigns/fillers/${type}`);
  };

  const handleFileDetailButton = (item) => {
    setSelectedContentItem(item);
    setCurrentContent(contentBarContents.FILE_DETAIL);
    if (filePathType) {
      navigate(`/campaigns/fillers/${filePathType}/${item.id}`);
    } else {
      navigate(`/campaigns/fillers/${item.type === "html" ? "widgets" : "files"}/${item.id}`);
    }
  };
  const handleFilesCreateButton = () => {
    setSelectedContentItem();
    setCurrentContent(contentBarContents.FILES_FORM);
  };
  const handleFilesEditButton = (item) => {
    setSelectedContentItem(item);
    setCurrentContent(contentBarContents.FILE_EDIT_FORM);
  };
  const handleFilesDeleteButton = async id => {
    const res = await deleteFiller(id);
    responseToaster(res);
    if (res.ok) setReload(prev => !prev);
  };

  const handleFilesSubmit = async (filesFormValue, _, callback) => {
    const saveFilesPromise = [];
    for (const item of filesFormValue) {
      const {
        name,
        content_file_id,
        tags = [],
        type,
        default_duration,
      } = item;
      const res = await saveFile({
        content_file_id: content_file_id,
        data: {
          orientation: "landscape",
          ...(type === "html" && { context: "fullscreen" }),
          ...(type !== "video" ?
            { default_duration: +default_duration } :
            { default_play_audio: true }),
        },
        description: "",
        name,
        tags,
        type,
      });
      const resJson = await res.json();

      saveFilesPromise.push(saveFiller({
        name: name,
        category_id: null,
        policy: tags.length > 0 ? [{ rule: "allow", player_tag: tags }, {rule: "deny"}] : [],
        end: null,
        start: null,
        playback_item: {
          content_items: [
            {
              content_item_data: {
                // data: resJson.user_data.content_item_data.data,
                ...(type === "html" && { data: {} }),
                ...(type !== "video" ?
                  { duration: +default_duration } :
                  { play_audio: true }),
              },
              id: resJson.id,
              playback_item_data: {}
            }
          ],
          data: { orientation: "landscape" },
        },
      }));
    }

    Promise.all(saveFilesPromise).then(res => callback(res));
  };

  const contentComponents = {
    [contentBarContents.FOLDERS_OVERVIEW]: null,
    [contentBarContents.FOLDER_DETAIL]: null,
    [contentBarContents.FILE_DETAIL]: (
      <FileDetailComponent
        contentItem={selectedContentItem}
        onClose={() => setCurrentContent(contentBarContents.FOLDER_DETAIL)}
        onEdit={handleFilesEditButton}
        onDelete={handleFilesDeleteButton}
      />
    ),
    [contentBarContents.FILES_FORM]: (
      <FilesFormComponent
        tagsList={playersTags}
        reload={setReload}
        handleSubmit={handleFilesSubmit}
      />
    ),
    [contentBarContents.FILE_EDIT_FORM]: (
      <FilesEditFormComponent
        tagsList={playersTags}
        reload={setReload}
        contentItem={selectedContentItem}
      />
    ),
  };

  useEffect(() => {
    dispatch(fetchFillers(`?name=${searchState}&limit=-1`));
  }, [dispatch, reload, searchState]);

  useEffect(() => {
    if (data.objects === 0) return;
  
    const filtered = data.objects.filter(item => {
      if (!filePathType) return true;
      if (filePathType === "widgets") return getFillerType(item).isWidget;
      return getFillerType(item).isFile;
    });
    setFillers({ ...data, objects: filtered });
  }, [dispatch, filePathType, data]);

  const actions = {
    handleFileDetailButton,
    handleFilesCreateButton,
    handleFilesEditButton,
    handleFilesDeleteButton,
    onContentbarClose: () => setCurrentContent(null),
  };

  const generalProps = {
    data: { folders, fillers },
    sideBar: <SideBar />,
    contentBar: contentComponents[currentContent],
    ...actions,
  };

  return (
    <>
      <HeaderComponent
        title="Филлеры"
        right={
          <ButtonToolbar>
            <Whisper
              trigger="click"
              placement="bottomEnd"
              speaker={
                <Popover arrow={false}>
                  <FilterFilesForm value={filePathType} onChange={handleFolderDetailButton} />
                </Popover>
              }
            >
              <IconButton icon={<MdTune size="1.5em" />} appearance="ghost" />
            </Whisper>
            {/* <Whisper
              trigger="click"
              placement="bottomEnd"
              speaker={
                <Popover arrow={false}>
                  <SortFilesForm value={sortKey} onChange={setSortKey} />
                </Popover>
              }
            >
              <IconButton icon={<MdSort size="1.5em" />} appearance="ghost" />
            </Whisper> */}
          </ButtonToolbar>
        }
      />
      {isDesktop ? <Desktop {...generalProps} /> : <Mobile {...generalProps} />}
    </>
  );

}

export default Fillers;
