import { useSelector } from "react-redux";
import { Message, Avatar, Tag } from "rsuite";

import HeaderComponent from "components/HeaderComponent";
import FileItemComponent from "components/files/FileItemComponent";
import { convertBytes, getThumbnail } from "helpers/utils";

const PlaylistsFilesComponent = () => {
  const { data: playlist, playlistPlayback } = useSelector(state => state.playlist);

  return (
    <div>
      <HeaderComponent
        title={playlist.name}
        tag="h3"
        gutter={0}
      />
  
      <HeaderComponent title="Список файлов" tag="h6" className="inner-header" />
      {(playlistPlayback.length > 0) ? (
        <div className="item-list">
          {playlistPlayback.map(({ content_items }) => {
            const contentItem = content_items[0].content_item;
            const file = contentItem.content_files[0].content_file;
            const duration = `${contentItem.data.default_duration || file.data.duration} сек`;
            return (
              <div key={`${contentItem.id}`}>
                <FileItemComponent
                  icon={<Avatar src={getThumbnail(contentItem)} size="lg" />}
                  title={contentItem.name}
                  subTitle={<><Tag>{convertBytes(file.size).mb}</Tag> <Tag>{duration}</Tag></>}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <Message type="warning">Нет ни одного файла</Message>
      )}

    </div>
  );
};

export default PlaylistsFilesComponent;
