import React, { useCallback, useRef } from "react";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { MdDragHandle } from "react-icons/md";

import useLayout from "hooks/useLayout";
import { iconStyle } from "constants";

const ItemTypes = {
  CARD: 'card',
};

const DraggableItemsComponent = ({ orderItems, setOrderItems, highlightId }) => {
  const { isMobile } = useLayout();

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setOrderItems((prevCards) => {
      const copyCards = [...prevCards];
      copyCards.splice(dragIndex, 1);
      copyCards.splice(hoverIndex, 0, prevCards[dragIndex]);
      return copyCards;
    });
  }, []);

  const renderCard = useCallback((card, index) => {
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        text={card.name}
        highlightId={highlightId}
        moveCard={moveCard}
      />
    )
  }, []);

  return (
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
      {orderItems.map((card, i) => renderCard(card, i))}
    </DndProvider>
  )
};

const Card = ({ id, text, index, moveCard, highlightId }) => {
  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (
    <div
      ref={ref}
      className={`item-draggable mb-10 item-draggable-index-${id} ${id === highlightId ? "item-draggable-hightlight" : ""}`}
      style={{ opacity }}
      data-handler-id={handlerId}
    >
      <MdDragHandle size={iconStyle.HEADER_ICON_SIZE} />
      <span className="ml-10">{text}</span>
    </div>
  )
};

export default DraggableItemsComponent;