import { configureStore } from "@reduxjs/toolkit";

import login from "redux/slices/loginSlice";
import players from "redux/slices/playersSlice";
import player from "redux/slices/playerSlice";
import categories from "redux/slices/categoriesSlice";
import reboots from "redux/slices/rebootsSlice";
import brightness from "redux/slices/brightnessSlice";
import campaigns from "redux/slices/campaignsSlice";
import campaign from "redux/slices/campaignSlice";
import playlists from "redux/slices/playlistsSlice";
import playlist from "redux/slices/playlistSlice";
import reports from "redux/slices/reportsSlice";
import files from "redux/slices/filesSlice";
import fillers from "redux/slices/fillersSlice";
import widgets from "redux/slices/widgetsSlice";
import analytics from "redux/slices/analyticsSlice";
import settings from "redux/slices/settingsSlice";
import tags from "redux/slices/tagsSlice";
import notifications from "redux/slices/notificationsSlice";
import layout from "redux/slices/layoutSlice";
import statistics from "redux/slices/statisticsSlice";

export const store = configureStore({
  reducer: {
    login,
    players,
    player,
    categories,
    reboots,
    brightness,
    campaigns,
    campaign,
    playlists,
    playlist,
    reports,
    files,
    fillers,
    widgets,
    analytics,
    settings,
    tags,
    notifications,
    layout,
    statistics,
  },
  devTools: process.env.NODE_ENV !== "production",
});
