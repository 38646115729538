const FILES_FILTERS = {
  all: { label: "Все файлы", value: "" },
  image: { label: "Изображения", value: "type=image" },
  video: { label: "Видео", value: "type=video" },
  html: { label: "Виджеты", value: "type=html" },
};
const FILES_SORTS = {
  "created_at.asc": { label: "По дате ▲", value: "" },
  "created_at.desc": { label: "По дате ▼", value: "order_by=created_at.desc" },
  "name.asc": { label: "По имени ▲", value: "order_by=name.asc" },
  "name.desc": { label: "По имени ▼", value: "order_by=name.desc" },
};
const FOLDERS_SORTS = {
  ...FILES_SORTS,
  "created_at.desc": { label: "По дате ▼", value: "order_by=id.desc" },
};

const FILLERS_FILTERS = [
  { label: "Все филлеры", value: "" },
  { label: "Филлеры из файлов", value: "widgets" },
  { label: "Филлеры-виджеты", value: "files" },
];
const FILLERS_SORTS = {
  "created_at.asc": { label: "По дате ▲", value: "" },
  "created_at.desc": { label: "По дате ▼", value: "order_by=created_at.desc" },
  "name.asc": { label: "По имени ▲", value: "order_by=name.asc" },
  "name.desc": { label: "По имени ▼", value: "order_by=name.desc" },
};

export {
  FILES_FILTERS,
  FILES_SORTS,
  FOLDERS_SORTS,

  FILLERS_FILTERS,
  FILLERS_SORTS,
};
