import { useMemo, useState } from "react";

import FilesListComponent from "components/files/FilesListComponent";
import ButtonCreateComponent from "components/buttons/ButtonCreateComponent";
import DrawerComponent from "components/DrawerComponent";

const Desktop = ({
  data: { folders, fillers },
  sideBar,
  contentBar,
  handleFileDetailButton,
  handleFilesCreateButton,
  handleFilesEditButton,
  handleFilesDeleteButton,
}) => {

  const [drawerOpen, setDrawerOpen] = useState(false);

  // Приводим к формату как в files
  const files = useMemo(() => fillers.objects.map(item => ({
    ...item.playback_item.content_items[0].content_item,
    id: item.id,
    tags: Object.values(item.policy_references.player_tag),
    fillerContentItemId: item.playback_item.content_items[0].content_item.id,
    })), [fillers.objects]);

  const handleOnFileDetailDrawer = (item) => {
    setDrawerOpen(true);
    handleFileDetailButton(item);
  };
  const handleOnFilesCreateDrawer = () => {
    setDrawerOpen(true);
    handleFilesCreateButton();
  };
  const handleOnFilesEditDrawer = item => {
    setDrawerOpen(true);
    handleFilesEditButton(item);
  };

  const handleOnCloseDrawer = () => setDrawerOpen(false);

  return (
    <>
      <div className="caps-list files-list">
        <div className="item-list__item">
          <div className="item-list__item-content">
            <ButtonCreateComponent size="sm" onClick={handleOnFilesCreateDrawer}>
              Добавить файлы
            </ButtonCreateComponent>
          </div>
        </div>
        <FilesListComponent
          items={files}
          status={fillers.status}
          error={fillers.error}
          onClick={handleOnFileDetailDrawer}
          onEdit={handleOnFilesEditDrawer}
          onRemove={handleFilesDeleteButton}
        />
      </div>
      {sideBar}
      <DrawerComponent
        open={drawerOpen}
        onClose={handleOnCloseDrawer}
        size="full"
        title="Детали записи"
      >
        {contentBar}
      </DrawerComponent>
    </>
  );
};

export default Desktop;
