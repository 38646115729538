import { Radio, RadioGroup } from "rsuite";

import { FILES_FILTERS, FILES_SORTS, FOLDERS_SORTS } from "constants/filters";

export const FilterFilesForm = ({ value, onChange }) => (
  <div className="caps-main">
    <h6 className="mb-20">Фильтры</h6>
    <RadioGroup
      name="filter-list"
      value={value}
      onChange={onChange}
    >
      {Object.keys(FILES_FILTERS).map(f => (
        <Radio key={`filter-${f}`} value={f}>
          {FILES_FILTERS[f].label}
        </Radio>
      ))}
    </RadioGroup>
  </div>
);

export const SortFilesForm = ({ value, onChange }) => (
  <div className="caps-main">
    <h6 className="mb-20">Сортировка</h6>
    <RadioGroup
      name="sort-list"
      value={value}
      onChange={onChange}
    >
      {Object.keys(FILES_SORTS).map(f => (
        <Radio key={`sort-${f}`} value={f}>
          {FILES_SORTS[f].label}
        </Radio>
      ))}
    </RadioGroup>
  </div>
);

export const SortFoldersForm = ({ value, onChange }) => (
  <div className="caps-main">
    <h6 className="mb-20">Сортировка</h6>
    <RadioGroup
      name="sort-list"
      value={value}
      onChange={onChange}
    >
      {Object.keys(FOLDERS_SORTS).map(f => (
        <Radio key={`sort-${f}`} value={f}>
          {FILES_SORTS[f].label}
        </Radio>
      ))}
    </RadioGroup>
  </div>
);