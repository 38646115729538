import {
  InputGroup,
  Input,
  Placeholder,
  TagPicker,
  Avatar,
} from "rsuite";
import { MIN_DURATION, NO_IMAGE_URL } from "constants";

const FileInfoRenderComponent = ({
  formValue,
  file: { name, fileKey, type },
  tags,
  onChangeForm,
}) => {
  const handleChange = (v, e) => onChangeForm(v, e.target.name, fileKey);

  if (!formValue) {
    return (<Placeholder.Paragraph graph="image" />);
  }

  return (
    <div key={fileKey} style={{ width: "100%" }}>
      <div style={{ float: "left", width: 60 }}>
        <Avatar size="lg" src={formValue.type === "html" ? NO_IMAGE_URL : formValue.thumb} />
      </div>
      <div style={{ overflow: "hidden", paddingLeft: 15 }}>
        <div className="inner-form">
          <small>Название</small>
          <Input
            name="name"
            defaultValue={formValue.name}
            onChange={handleChange}
          />
        </div>
        <div className="inner-form">
        <small>Теги</small>
          <TagPicker
            data={tags.map(({ id, name }) => ({ label: name, value: id }))}
            onChange={value => onChangeForm(value, "tags", fileKey)}
            block
          />
        </div>
        {formValue.type === "image" ? (
          <div className="inner-form">
            <small>Длительность</small>
            <InputGroup>
              <Input
                type="number"
                name="default_duration"
                postfix="сек"
                min={MIN_DURATION}
                defaultValue={formValue.default_duration}
                onChange={handleChange}
              />
              <InputGroup.Addon>сек</InputGroup.Addon>
            </InputGroup>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FileInfoRenderComponent;
