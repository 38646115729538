import {
  IconButton,
  Whisper,
  Popover,
  Dropdown,
} from "rsuite";
import { MdMoreVert } from "react-icons/md";

import classNames from "classnames";

const CardComponent = ({
  onClick,
  title,
  thumbFiles = [],
  onPreview,
  unit,
  date,
  subTitle,
  badgeColor,
  menuItems,
  menuItemSelect,
  bottom,
  className,
}) => {
  const handleStopPropagation = e => { e.stopPropagation(); };

  const handlePreview = (e, thumb) => {
    handleStopPropagation(e);
    onPreview({ open: true, path: thumb.path, type: thumb.type });
  };

  const renderPlayerMenu = ({ onClose, left, top, className }, ref) => {
    const handleSelect = (eventKey, e) => {
      handleStopPropagation(e);
      onClose();
      menuItemSelect(eventKey);
    };
    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu onSelect={handleSelect}>
          {menuItems.map(({ key, label }) => (
            <Dropdown.Item
              className={`item-card-menu-action-${key}`}
              eventKey={key}
              key={key}
            >
              {label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Popover>
    );
  };

  return (
    <div
      className={classNames("item-card", { [className]: className })}
      onClick={onClick}
    >
      {thumbFiles.length > 0 && (
        <div className="item-card-picture">
          {thumbFiles.map((thumb) => (
            <img key={`${thumb.path}`} src={thumb.preview} alt={title} onClick={(e) => handlePreview(e, thumb)} />
          ))}
        </div>
      )}
      <div className="item-card-menu">
        {menuItems && (
          <Whisper
            placement="bottomEnd"
            trigger="click"
            speaker={renderPlayerMenu}
            onClick={handleStopPropagation}
          >
            <IconButton icon={<MdMoreVert />} size="lg" appearance="link" />
          </Whisper>
        )}
      </div>
      <div className="item-card__header">
        {badgeColor && <span className={`item-card__header-badge badge badge-${badgeColor}`} />}
        <div className="item-card__header-title">{title}</div>
      </div>
      {/* {thumbFiles.length > 0 && (
        <div className="item-card__body-picture">
          {thumbFiles.map((thumb) => (
            <img key={`${thumb.path}`} src={thumb.preview} alt={title} onClick={(e) => handlePreview(e, thumb)} />
          ))}
        </div>
      )} */}
      {(unit || date) ? (
        <div className="item-card__body">
          <span className="item-card__body-unit">
            {unit}
          </span>
          <span className="item-card__body-date">
            {date}
          </span>
        </div>
      ) : null}
      <div className="item-card__body-sub">
        {subTitle}
      </div>
      {bottom ? (
        <div className="item-card__bottom">
          {bottom}
        </div>
      ) : null}
    </div>
  );
};

export default CardComponent;
