import { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Form,
  Button,
  Schema,
  CheckPicker,
} from "rsuite";

import CloseButtonComponent from "components/CloseButtonComponent";
import InputFieldComponent from "components/forms/InputFieldComponent";
import HeaderComponent from "components/HeaderComponent";
import usePopup from "hooks/usePopup";
import { fetchRoles, createUser, putUser } from "redux/slices/settingsSlice";
import { buildDeepObjectFromFlat } from "helpers/utils";
import { accountsFormInitialData } from "constants/form";

const { StringType, ArrayType } = Schema.Types;
const model = Schema.Model({
  email: StringType().isEmail("Введите валидный e-mail").isRequired("Обязательное поле"),
  "profile.first_name": StringType().isRequired("Обязательное поле"),
  "profile.last_name": StringType().isRequired("Обязательное поле"),
  roles: ArrayType().minLength(1, "Выберите роль"),
});

const AccountsFormComponent = ({ defaultValues = {}, onClose, reload }) => {
  const dispatch = useDispatch();
  const { roles } = useSelector(state => state.settings);

  const formRef = useRef();
  const [formValue, setFormValue] = useState(accountsFormInitialData);
  const { responseToaster } = usePopup();

  const handleSubmit = async () => {
    if (!formRef.current.check()) {
      return;
    }
    const { id, ...payload } = buildDeepObjectFromFlat(formValue);
    let response;
    if (id) {
      response = await putUser(id, payload);
    }  else {
      response = await createUser(payload);
      setFormValue(accountsFormInitialData);
    }
    reload(prev => !prev);
    responseToaster(response);
  };

  useEffect(() => {
    if (defaultValues.id) {
      setFormValue(defaultValues);
    } else {
      setFormValue(accountsFormInitialData);
    }
  }, [defaultValues.id]);

  useEffect(() => {
    dispatch(fetchRoles("?limit=-1"));
  }, [dispatch]);

  return (
    <Form
      model={model}
      ref={formRef}
      onChange={setFormValue}
      formValue={formValue}
      fluid
    >
      <HeaderComponent
        title={`${formValue.id ? "Изменить" : "Создать"} пользователя`}
        tag="h4"
        gutter={0}
        right={<CloseButtonComponent onClose={onClose} />}
      />

      <InputFieldComponent name="email" label="Email" />
      <InputFieldComponent name="profile.first_name" label="Имя" />
      <InputFieldComponent name="profile.last_name" label="Фамилия" />
      {roles.objects ? (
        <InputFieldComponent
          name="roles"
          label="Роли"
          data={roles.objects.map(({ id, title }) => ({ label: title, value: id }))}
          accepter={CheckPicker}
          block
        />
      ) : null}

      <br /><hr />
      <Form.Group>
        <Button appearance="primary" onClick={handleSubmit}>Сохранить</Button>
      </Form.Group>
    </Form>
  );
};

export default AccountsFormComponent;
