import { useState } from "react";
import { FlexboxGrid, Modal, Tag, Message, ButtonGroup, IconButton } from "rsuite";

import HeaderComponent from "components/HeaderComponent";
import { convertBytes, getThumbnail } from "helpers/utils";
import { MdDeleteOutline, MdDownload, MdEdit } from "react-icons/md";

const FileDetailComponent = ({ contentItem, onEdit, onDelete }) => {
  const [openFile, setOpenFile] = useState(false);

  const handleOpenFile = () => {
    setOpenFile(file);
  };
  const handleCloseFile = () => setOpenFile(false);

  const handleDelete = id => console.log(id);

  const file = contentItem.content_files[0].content_file;
  const duration = `${contentItem.data.default_duration || file.data.duration} сек`;

  return (
    <div>
      <HeaderComponent
        title={contentItem.name}
        tag="h3"
        gutter={0}
      />

      <img
        src={getThumbnail(contentItem, "preview")}
        alt={file.name}
        className="flex-image"
        onClick={handleOpenFile}
      />

      <HeaderComponent
        title="Управление"
        tag="h6"
        gutter={0}
      />
      <ButtonGroup className="space-between">
        <IconButton icon={<MdDownload size="1.4em" />} onClick={() => {}} />
        <IconButton icon={<MdEdit size="1.4em" />} onClick={() => onEdit(contentItem)} />
        <IconButton icon={<MdDeleteOutline size="1.4em" color="red" />} onClick={() => onDelete(contentItem.id)} />
      </ButtonGroup>

      <HeaderComponent
        title="Данные файла"
        tag="h6"
        gutter={0}
      />
      <>
        <FlexboxGrid justify="space-between" style={{ lineHeight: 2.2 }}>
          <FlexboxGrid.Item>Название</FlexboxGrid.Item>
          <FlexboxGrid.Item>{file.name}</FlexboxGrid.Item>
        </FlexboxGrid>
        <FlexboxGrid justify="space-between" style={{ lineHeight: 2.2 }}>
          <FlexboxGrid.Item>Разрешение</FlexboxGrid.Item>
          <FlexboxGrid.Item>{`${file.data.width}x${file.data.height}`}</FlexboxGrid.Item>
        </FlexboxGrid>
        <FlexboxGrid justify="space-between" style={{ lineHeight: 2.2 }}>
          <FlexboxGrid.Item>Контейнер</FlexboxGrid.Item>
          <FlexboxGrid.Item>{file.name.split(".").slice(-1)}</FlexboxGrid.Item>
        </FlexboxGrid>
        {contentItem.type === "video" ? (
          <>
            <FlexboxGrid justify="space-between" style={{ lineHeight: 2.2 }}>
              <FlexboxGrid.Item>Кодек</FlexboxGrid.Item>
              <FlexboxGrid.Item>{file.data.probe?.streams[0]?.codec_long_name}</FlexboxGrid.Item>
            </FlexboxGrid>
            <FlexboxGrid justify="space-between" style={{ lineHeight: 2.2 }}>
              <FlexboxGrid.Item>Битрейт</FlexboxGrid.Item>
              <FlexboxGrid.Item>{convertBytes(file.data.probe?.streams[0]?.bit_rate).mb}</FlexboxGrid.Item>
            </FlexboxGrid>
            <FlexboxGrid justify="space-between" style={{ lineHeight: 2.2 }}>
              <FlexboxGrid.Item>Частота кадров</FlexboxGrid.Item>
              <FlexboxGrid.Item>{file.data.probe?.streams[0]?.avg_frame_rate}</FlexboxGrid.Item>
            </FlexboxGrid>
          </>
        ) : null}
        <FlexboxGrid justify="space-between" style={{ lineHeight: 2.2 }}>
          <FlexboxGrid.Item>Хронометраж</FlexboxGrid.Item>
          <FlexboxGrid.Item>{duration}</FlexboxGrid.Item>
        </FlexboxGrid>
        <FlexboxGrid justify="space-between" style={{ lineHeight: 2.2 }}>
          <FlexboxGrid.Item>Вес</FlexboxGrid.Item>
          <FlexboxGrid.Item>{convertBytes(file.size).mb}</FlexboxGrid.Item>
        </FlexboxGrid>
      </>

      <HeaderComponent
        title="Теги"
        tag="h6"
        gutter={0}
      />
      {(contentItem.tags.length > 0) ? (
        contentItem.tags.map(({ id, name }) => (
          <Tag key={id} size="lg" closable onClose={() => handleDelete(id)}>
            {name}
          </Tag>
        ))
      ) : (
        <Message type="warning">Ни один тег не привязан</Message>
      )}

      <Modal size="lg" open={!!openFile} onClose={handleCloseFile}>
        <Modal.Header>
          <Modal.Title>{file.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {file.type === "video"
            ? <video width="100%" controls ><source src={file.path} type="video/mp4"/></video>
            : <img className="flex-image" src={getThumbnail(contentItem, "preview")} alt={file.name} />
          }
        </Modal.Body>
      </Modal>
    </div>

  );
};

export default FileDetailComponent;
