import { Col, FlexboxGrid, Modal } from "rsuite";

import LoaderComponent from "components/LoaderComponent";
import ErrorComponent from "components/ErrorComponent";
import CardComponent from "components/CardComponent";
import useLayout from "hooks/useLayout";
import { loadingStatus } from "helpers/fetcher";
import { formatDate } from "helpers/date";
import { CAMPAIGNS_STATES } from "constants";
import { useState } from "react";

const CampaignsGridComponent = ({
  buttonCreate,
  items,
  status,
  error,
  onClick,
  onEdit,
  onClone,
  onDelete,
}) => {
  const [preview, setPreview] = useState({ open: false, path: "", type: "" });

  const { gridBreakPoints } = useLayout();

  const menuItems = [
    { key: "edit", label: "Редактировать", action: item => onEdit(item) },
    { key: "clone", label: "Клонировать", action: item => onClone(item) },
    { key: "delete", label: "Удалить", action: item => onDelete(item.id) },
  ];

  const filterMenuItems = {
    running: menuItems.filter(m => m.key !== "delete"),
    finished: menuItems.filter(m => m.key !== "edit"),
    deleted: null,
  };

  if (status !== loadingStatus.SUCCEEDED) {
    return <LoaderComponent />;
  }

  if (status === loadingStatus.FAILED) {
    return <ErrorComponent message={error} />;
  }

  return (
    <>
      <FlexboxGrid className="grid-items" align="top">
        {buttonCreate && (
          <FlexboxGrid.Item as={Col} {...gridBreakPoints}>
            {buttonCreate}
          </FlexboxGrid.Item>
        )}
        {items.map(({ ad_campaign: adCampaign, playback_items: playbackItems, players, players_count }, index) => {
          const playbackInterval = [
            formatDate(adCampaign.revision.start_datetime, ".").short,
            formatDate(adCampaign.revision.end_datetime, ".").short,
          ];
          return (
            <FlexboxGrid.Item as={Col} {...gridBreakPoints} key={`${adCampaign.id}`}>
              <CardComponent
                title={adCampaign.name}
                thumbFiles={[playbackItems[0].content_items[0].content_item.content_files[0].content_file]}
                onPreview={setPreview}
                unit={`${3600 / adCampaign.revision.interval}/час`}
                date={playbackInterval.join("-")}
                subTitle={`Хронометраж: ${adCampaign.revision.duration} сек`}
                badgeColor={CAMPAIGNS_STATES[adCampaign.state].color}
                onClick={() => onClick(adCampaign.id)}
                menuItems={filterMenuItems[adCampaign.state] || menuItems}
                menuItemSelect={key => menuItems.find(it => it.key === key).action(adCampaign)}
              />
            </FlexboxGrid.Item>
          )}
        )}
      </FlexboxGrid>
      <Modal size="lg" open={preview.open} onClose={() => setPreview({ open: false, path: "", type: "" })}>
        <Modal.Body>
          {preview.type === "video"
            ? <video width="100%" controls><source src={preview.path} type="video/mp4"/></video>
            : <img className="flex-image" src={preview.path} alt="" />
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CampaignsGridComponent;
