import { memo, useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import HeaderComponent from "components/HeaderComponent";
import DrawerComponent from "components/DrawerComponent";
import CampaignsSideNavComponent from "components/campaign/CampaignsSideNavComponent";
import ReportsSchedulesFormComponent from "components/campaign/reports/ReportsSchedulesFormComponent";
import CampaignExportComponent from "components/campaign/CampaignExportComponent";

import { fetchReports, fillForm } from "redux/slices/reportsSlice";
import useLayout from "hooks/useLayout";
import { reportsFormInitialData } from "constants/form";
import Mobile from "./Mobile";
import Desktop from "./Desktop";

const DRAWERS = { export: "export" };

const contentBarContents = {
  REPORTS_OVERVIEW: "reports_overview",
  REPORTS_FORM: "reports_form",
};

const Reports = () => {
  const [currentContent, setCurrentContent] = useState(contentBarContents.REPORTS_OVERVIEW);
  const [drawerType, setDrawerType] = useState();
  const navigate  = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector(state => state.reports);
  const { isDesktop } = useLayout();

  const SideBar = memo(() => <CampaignsSideNavComponent handleExportAction={handleExportDrawerOpen} />);

  const handleFormButton = (_, reportsId) => {
    dispatch(fillForm(reportsFormInitialData));
    setCurrentContent(contentBarContents.REPORTS_FORM);
    navigate(`/campaigns/report-schedules${reportsId ? `/${reportsId}` : ""}`);
  };

  const handleDrawerClose = () => setDrawerType();
  const handleExportDrawerOpen = () => setDrawerType(DRAWERS.export);

  const contentComponents = useMemo(() => ({
    [contentBarContents.REPORTS_OVERVIEW]: null,
    [contentBarContents.REPORTS_FORM]: (
      <ReportsSchedulesFormComponent
        onClose={() => setCurrentContent(contentBarContents.REPORTS_OVERVIEW)}
      />
    ),
  }), []);

  useEffect(() => {
    dispatch(fetchReports());
  }, [dispatch]);

  const actions = { handleFormButton, onContentbarClose: () => setCurrentContent(null) };
  
  return (
    <>
      <HeaderComponent title="Расписания статистики" />
      {isDesktop ? (
        <Desktop
          data={data}
          sideBar={<SideBar />}
          contentBar={contentComponents[currentContent]}
          {...actions}
        />
      ) : (
        <Mobile
          data={data}
          sideBar={<SideBar />}
          contentBar={contentComponents[currentContent]}
          {...actions}
        />
      )}
      <DrawerComponent
        open={!!drawerType}
        onClose={handleDrawerClose}
        placement="left"
      >
        {drawerType === DRAWERS.export && <CampaignExportComponent />}
      </DrawerComponent>
    </>
  );

}

export default Reports;
