import { Form } from "rsuite";

const InputFieldComponent = ({
  name = `${Math.random()}`, label, accepter, help, tooltip, required, errorComponent, subComponent, children, ...rest
}) => (
  <Form.Group controlId={name}>
    <Form.ControlLabel className={required ? "required" : ""}>{label}</Form.ControlLabel>
    {children ? children : <Form.Control name={name} accepter={accepter} {...rest} />}
    {errorComponent}
    {help && <Form.HelpText>{help}</Form.HelpText>}
    {tooltip && <Form.HelpText tooltip>{tooltip}</Form.HelpText>}
    {subComponent}
  </Form.Group>
);

export default InputFieldComponent;
