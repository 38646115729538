import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";
import { campaigns } from "constants/endpoints";

const getCampaigns = async (query) => {
  const response = await fetcher(`${campaigns.ALL}${query}`);
  return response.json();
};

const fetchCampaigns = createAsyncThunk("campaigns", async (query = "") => {
  return await getCampaigns(query);
});

const fetchCampaignsReport = async (query) => {
  const response = await fetcher(`${campaigns.ALL}/xlsx${query}`);
  return response.blob();
};

const initialState = {
  objects: [],
  meta: {},
  status: loadingStatus.IDLE,
  error: null,
};

const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    addCampaigns(state, { payload }) {
      state.objects = [...state.objects, ...payload];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCampaigns.pending, (state, action) => {
        state.status = loadingStatus.LOADING;
      })
      .addCase(fetchCampaigns.fulfilled, (state, action) => {
        state.status = loadingStatus.SUCCEEDED;
        // Add any fetched posts to the array
        state.objects = action.payload.objects;
        state.meta = action.payload.meta;
      })
      .addCase(fetchCampaigns.rejected, (state, action) => {
        state.status = loadingStatus.FAILED;
        state.error = `${action.error.name}: ${action.error.message}`;
      })
  }
});

export const { addCampaigns } = campaignsSlice.actions;

export default campaignsSlice.reducer;

export {
  getCampaigns,
  fetchCampaigns,
  fetchCampaignsReport,
};
