import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Message } from "rsuite";

import HeaderComponent from "components/HeaderComponent";
import CardComponent from "components/CardComponent";
import { fetchPlayerCampaigns } from "redux/slices/playerSlice";
import { formatDate } from "helpers/date";
import { campaigns as campaignsUrl } from "constants/urls";
import { CAMPAIGNS_STATES } from "constants";

const PlayerCampaignsComponent = () => {
  const dispatch = useDispatch();
  const { data: player, campaigns } = useSelector(state => state.player);
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams({
      // state: "pending", "running",
      player_id: player.id,
      exchange: 0,
      limit: -1,
    });
    dispatch(fetchPlayerCampaigns(`?${query.toString()}`));
  }, [dispatch, player.id]);


  return (
    <div>
      <HeaderComponent
        title={player.name}
        tag="h3"
        gutter={0}
      />
  
  
      <HeaderComponent title="Список кампаний" tag="h6" className="inner-header" />
      {(campaigns.length > 0) ? (
        campaigns.map(({ ad_campaign: adCampaign }) => {
          const playbackInterval = [
            formatDate(adCampaign.revision.start_datetime, ".").short,
            formatDate(adCampaign.revision.end_datetime, ".").short,
          ];
          return (
            <CardComponent
              className="hovered"
              key={adCampaign.id}
              title={adCampaign.name}
              unit={`${3600 / adCampaign.revision.interval}/ч`}
              date={playbackInterval.join("-")}
              subTitle={`Хронометраж: ${adCampaign.revision.duration} сек`}
              badgeColor={CAMPAIGNS_STATES[adCampaign.state].color}
              onClick={() => navigate(`${campaignsUrl.ALL}/detail/${adCampaign.id}`)}
            />
          );
        })
      ) : (
        <Message type="warning">Нет ни одной кампании</Message>
      )}

    </div>
  );
};

export default PlayerCampaignsComponent;
