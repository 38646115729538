import { memo, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { IconButton, ButtonToolbar, RadioGroup, Radio, Whisper, Popover, Tag, Loader } from "rsuite";
import { MdTune, MdSort, MdExpandMore, MdExpandLess } from "react-icons/md";
import InfiniteScroll from "react-infinite-scroller";

import DrawerComponent from "components/DrawerComponent";
import HeaderComponent from "components/HeaderComponent";
import SelectComponent from "components/forms/SelectComponent";
// import CampaignsOverviewComponent from "components/campaign/CampaignsOverviewComponent";
import CampaignsSideNavComponent from "components/campaign/CampaignsSideNavComponent";
import CampaignsFormComponent from "components/campaign/CampaignsFormComponent";
import CampaignsDetailComponent from "components/campaign/CampaignsDetailComponent";
import CampaignExportComponent from "components/campaign/CampaignExportComponent";
import CategoriesComponent from "components/CategoriesComponent";
import { getCampaigns, fetchCampaigns, addCampaigns } from "redux/slices/campaignsSlice";
import { fetchCategories } from "redux/slices/categoriesSlice";
import { fetchPlayers } from "redux/slices/playersSlice";
import { deleteCampaign } from "redux/slices/campaignSlice";
import { playersCheckTreeToggle } from "redux/slices/layoutSlice";
import useLayout from "hooks/useLayout";
import useFilter from "hooks/useFilter";
import usePopup from "hooks/usePopup";
import { calculateInterval } from "helpers/utils";
import { loadingStatus } from "helpers/fetcher";
import nextItems from "helpers/next-items";
import Mobile from "./Mobile";
import Desktop from "./Desktop";
import { campaignsFormInitialData } from "constants/form";
import { CAMPAIGNS_STATES } from "constants";

const DRAWERS = { export: "export" };

const contentBarContents = {
  CAMPAIGNS_OVERVIEW: "campaigns_overview",
  CAMPAIGNS_FORM: "campaigns_form",
  CAMPAIGNS_DETAIL: "campaigns_detail",
};

const PAGE_FILTERS = {
  all: { label: "Все кампании", value: "" },
  online: { label: CAMPAIGNS_STATES.running.label, value: "type=normal&type=pipeline&exchange=0&state=running" },
  offline: { label: CAMPAIGNS_STATES.pending.label, value: "type=normal&type=pipeline&exchange=0&state=pending" },
  not_activated: { label: CAMPAIGNS_STATES.finished.label, value: "type=normal&type=pipeline&exchange=0&state=finished" },
  deleted: { label: CAMPAIGNS_STATES.deleted.label, value: "type=normal&type=pipeline&exchange=0&state=deleted" },
};

const SORTS = {
  "created_at.asc": { label: "По дате ▲", value: "" },
  "created_at.desc": { label: "По дате ▼", value: "order_by=created_at.desc" },
  "name.asc": { label: "По имени ▲", value: "order_by=name.asc" },
  "name.desc": { label: "По имени ▼", value: "order_by=name.desc" },
};

const Campaigns = () => {
  // TODO: переименовать во всех pages
  const [currentComponent, setCurrentComponent] = useState(contentBarContents.CAMPAIGNS_OVERVIEW);
  const [selectedContentItem, setSelectedContentItem] = useState();
  const [drawerType, setDrawerType] = useState();
  const [reload, setReload] = useState();
  const [extraQueryString, setExtraQueryString] = useState("type=normal&type=pipeline&exchange=0");
  const navigate  = useNavigate();
  const { "*": path } = useParams();
  const dispatch = useDispatch();
  const data = useSelector(state => state.campaigns);
  const { searchState, playersCheckTreeState } = useSelector(state => state.layout);
  const {
    filterKey,
    sortKey,
    additionalParams,
    setFilterKey,
    setSortKey,
    setAdditionalParam,
    queryString,
  } = useFilter({
    filters: PAGE_FILTERS,
    sorts: SORTS,
    externalParams: ["name", "player_id"],
  });
  const { isDesktop } = useLayout();
  const { responseToaster } = usePopup();

  const SideBar = memo(() => <CampaignsSideNavComponent handleExportAction={handleExportDrawerOpen} />);

  const handleDetailButton = (id) => {
    setCurrentComponent(contentBarContents.CAMPAIGNS_DETAIL);
    navigate(`/campaigns/detail/${id}`);
  };

  const handleCreateButton = () => {
    setCurrentComponent(contentBarContents.CAMPAIGNS_FORM);
    setSelectedContentItem();
    navigate("/campaigns/form");
  };

  const handleEditButton = (item) => {
    setCurrentComponent(contentBarContents.CAMPAIGNS_FORM);
    const {
      ad_campaign_id, created_at, created_by, creator_id, id,
      duration, end_datetime, requirements, start_datetime, 
      layout_id, interval, ...rest
    } = item.revision;
    const payload = {
      ...rest,
      category_id: item.category_id,
      algorithm: item.algorithm,
      id: item.id,
      advertiser: item.advertiser_id,
      name: item.name,
      description: item.description,
      interval: calculateInterval(interval, true).interval,
    };
    setSelectedContentItem(payload);
    navigate(`/campaigns/form/${id}`);
  };
  
  const handleCloneButton = item => {
    setCurrentComponent(contentBarContents.CAMPAIGNS_FORM);
    const {
      ad_campaign_id, created_at, created_by, creator_id, id,
      duration, end_datetime, requirements, start_datetime, 
      layout_id, interval, ...rest
    } = item.revision;
    const payload = {
      ...campaignsFormInitialData,
      ...rest,
      isClone: true,
      id: item.id,
      advertiser: item.advertiser_id,
      name: item.name,
      description: item.description,
      interval: calculateInterval(interval, true).interval,
    };
    setSelectedContentItem(payload);
    navigate(`/campaigns/clone/${item.id}`);
  };

  const handleDeleteButton = async id => {
    const res = await deleteCampaign(id);
    setReload(prev => !prev);
    responseToaster(res);
  };

  const handleDrawerClose = () => setDrawerType();
  const handleExportDrawerOpen = () => setDrawerType(DRAWERS.export);

  const onLoadMore = async () => {
    const _items = await nextItems(queryString, data.meta.offset, data.meta.limit, getCampaigns);
    if (_items.objects.length > 0) dispatch(addCampaigns(_items.objects));
  }

  const contentComponents = {
    [contentBarContents.CAMPAIGNS_OVERVIEW]: null,
    [contentBarContents.CAMPAIGNS_FORM]: (
      <CampaignsFormComponent
        reload={setReload}
        defaultValues={selectedContentItem}
        onClose={() => setCurrentComponent(contentBarContents.CAMPAIGNS_OVERVIEW)}
      />
    ),
    [contentBarContents.CAMPAIGNS_DETAIL]: (
      <CampaignsDetailComponent
        reload={setReload}
        onEdit={handleEditButton}
        onClone={handleCloneButton}
        onDelete={handleDeleteButton}
        onClose={() => setCurrentComponent(contentBarContents.CAMPAIGNS_OVERVIEW)}
      />
    ),
  };

  useEffect(() => setAdditionalParam("name", searchState), [searchState]);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(queryString);
      const qs = `${queryString}${queryString ? `&${extraQueryString}` : `?${extraQueryString}`}`;
      dispatch(fetchCampaigns(qs));
    }, 10);
    return () => clearTimeout(timer);
  }, [queryString, extraQueryString, reload]);

  useEffect(() => {
    if (!path) setCurrentComponent(contentBarContents.CAMPAIGNS_OVERVIEW);
  }, [path]);

  useEffect(() => {
    dispatch(fetchCategories("?type=campaigns"));
  }, []);

  const actions = {
    handleCreateButton,
    handleDetailButton,
    handleEditButton,
    handleCloneButton,
    handleDeleteButton,
    onContentbarClose: () => setCurrentComponent(null),
  };

  return (
    <>
      <HeaderComponent
        title={
          <>
            {isDesktop ? "Кампании" : <div>Кампании</div>}
            {" "}
            <Tag size="sm">{PAGE_FILTERS[filterKey].label}</Tag>
          </>
        }
        right={
          <ButtonToolbar>
            <IconButton
              icon={playersCheckTreeState ? <MdExpandLess size="1.7em" /> : <MdExpandMore size="1.7em" />}
              appearance="link"
              onClick={() => dispatch(playersCheckTreeToggle())}
            />
            <Whisper
              trigger="click"
              placement="bottomEnd"
              speaker={
                <Popover arrow={false}>
                  <FilterForm
                    value={filterKey}
                    onChange={setFilterKey}
                    selectedPlayer={additionalParams.player_id}
                    onChangePlayer={id => setAdditionalParam("player_id", id)}
                  />
                </Popover>
              }
            >
              <IconButton icon={<MdTune size="1.5em" />} appearance="ghost" />
            </Whisper>
            <Whisper
              trigger="click"
              placement="bottomEnd"
              speaker={
                <Popover arrow={false}>
                  <SortForm value={sortKey} onChange={setSortKey} />
                </Popover>
              }
            >
              <IconButton icon={<MdSort size="1.5em" />} appearance="ghost" />
            </Whisper>
          </ButtonToolbar>
        }
      />
      {playersCheckTreeState ? (
        <CategoriesComponent
          onQueryString={setExtraQueryString}
          categoryType="campaigns"
        />
      ) : (
        ""
      )}
      <InfiniteScroll
        initialLoad={false}
        threshold={0}
        hasMore={data.status !== loadingStatus.LOADING && data.objects.length < data.meta.total}
        loader={<p className="text-center"><Loader size="md" /></p>}
        loadMore={onLoadMore}
      >
        {isDesktop ? (
          <Desktop
            data={data}
            sideBar={<SideBar />}
            contentBar={contentComponents[currentComponent]}
            {...actions}
          />
        ) : (
          <Mobile
            data={data}
            sideBar={<SideBar />}
            contentBar={contentComponents[currentComponent]}
            {...actions}
          />
        )}
      </InfiniteScroll>
      <DrawerComponent
        open={!!drawerType}
        onClose={handleDrawerClose}
        placement="left"
      >
        {drawerType === DRAWERS.export && <CampaignExportComponent />}
      </DrawerComponent>
    </>
  );
};

const FilterForm = ({ value, onChange, selectedPlayer, onChangePlayer }) => {
  const dispatch = useDispatch();
  const { objects: players } = useSelector(state => state.players);

  useEffect(() => {
    dispatch(fetchPlayers("?hide_children=1&limit=-1"));
  }, [dispatch]);

  return (
    <div className="caps-main">
      <h6 className="mb-20">Фильтры</h6>
      <RadioGroup
        name="filter-list"
        value={value}
        onChange={onChange}
      >
        {Object.keys(PAGE_FILTERS).map(f => (
          <Radio key={`filter-${f}`} value={f}>
            {PAGE_FILTERS[f].label}
          </Radio>
        ))}
      </RadioGroup>
      <hr />
      <SelectComponent
        items={[
          { value: "", label: "Отобрать по устройству"},
          ...players.map(({ id, name }) => ({ value: id, label: name })),
        ]}
        value={selectedPlayer}
        onChange={onChangePlayer}
      />
    </div>
  );
};

const SortForm = ({ value, onChange }) => (
  <div className="caps-main">
    <h6 className="mb-20">Сортировка</h6>
    <RadioGroup
      name="sort-list"
      value={value}
      onChange={onChange}
    >
      {Object.keys(SORTS).map(f => (
        <Radio key={`sort-${f}`} value={f}>
          {SORTS[f].label}
        </Radio>
      ))}
    </RadioGroup>
  </div>
);


export default Campaigns;
