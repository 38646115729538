import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Message } from "rsuite";

import HeaderComponent from "components/HeaderComponent";
import CardComponent from "components/CardComponent";
import ActivatePinComponent from "components/player/PlayersGridComponent/ActivatePinComponent";
import { formatDate } from "helpers/date";
import { playerState } from "helpers/player";
import { players as playersUrl } from "constants/urls";

const CampaignsPlayersComponent = () => {
  const { data: campaign, campaignPlayers } = useSelector(state => state.campaign);
  const navigate = useNavigate();

  return (
    <div>
      <HeaderComponent
        title={campaign.name}
        tag="h3"
        gutter={0}
      />
  
      <HeaderComponent title="Список устройств" tag="h6" className="inner-header" />
      {(campaignPlayers.length > 0) ? (
        campaignPlayers.map(({ player: item }) => {
          const state = playerState(item.devices[0]);

          return (
            <CardComponent
              key={item.id}
              title={item.name}
              unit=""
              date={state.activated ?
                <span className={state.color}>
                  {formatDate(item.created_at, ".").full_datetime}
                </span> :
                  <ActivatePinComponent pin={state.pin} />
              }
              subTitle={item.user_data.address}
              badgeColor={state.color}
              onClick={() => navigate(`${playersUrl.ALL}/detail/${item.id}`)}
            />
          );
        })
      ) : (
        <Message type="warning">Нет ни одного устройства</Message>
      )}

    </div>
  );
};

export default CampaignsPlayersComponent;
