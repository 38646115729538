import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";
import { categories, players } from "constants/endpoints";

const fetchCategories = createAsyncThunk("categories", async (query = "") => {
  const response = await fetcher(`${categories.ALL}${query}`);
  return response.json();
});

const fetchCategoriesPlayers = createAsyncThunk("categories/players", async (query = "") => {
  const response = await fetcher(`${players.ALL}${query}`);
  return response.json();
});

const createCategory = async (data) => fetcher(categories.ALL, {
  method: "POST",
  body: JSON.stringify(data),
});
const patchCategory = async (id, data) => fetcher(`${categories.ALL}/${id}`, {
  method: "PATCH",
  body: JSON.stringify(data),
});
const deleteCategory = (id) => fetcher(`${categories.ALL}/${id}`, { method: "DELETE" });

const initialState = {
  data: [],
  status: loadingStatus.IDLE,
  error: null,

  categoriesPlayers: [],
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCategories.pending, (state, action) => {
        state.status = loadingStatus.LOADING;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = loadingStatus.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = loadingStatus.FAILED;
        state.error = `${action.error.name}: ${action.error.message}`;
      })

      .addCase(fetchCategoriesPlayers.fulfilled, (state, action) => {
        state.categoriesPlayers = action.payload.objects;
      })
  }
});

export default categoriesSlice.reducer;

// export const { postAdded, postUpdated, reactionAdded } = playerSlice.actions;
export {
  fetchCategories,
  fetchCategoriesPlayers,
  createCategory,
  patchCategory,
  deleteCategory,
};
