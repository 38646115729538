
import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  ButtonToolbar,
  Button,
  Schema,
  SelectPicker,
} from "rsuite";

import HeaderComponent from "components/HeaderComponent";
import CloseButtonComponent from "components/CloseButtonComponent";
import InputFieldComponent from "components/forms/InputFieldComponent";
import { fetchFolders } from "redux/slices/filesSlice";
import { saveFile } from "redux/slices/filesSlice";
import { saveFiller } from "redux/slices/fillersSlice";
import usePopup from "hooks/usePopup";
import { MIN_DURATION } from "constants";

const fieldRule = (opt = {}) => {
  const base = Schema.Types.StringType();
  if (opt.minlength) base.minLength(opt.minlength, `Длина не менее ${opt.minlength} символов`);
  if (opt.maxlength) base.maxLength(opt.maxlength, `Длина не более ${opt.maxlength} символов`);
  base.isRequired("Обязательное поле");
  return base;
};

const WidgetInstallFormComponent = ({ data, onClose }) => {
  const formRef = useRef();
  const initialFormData = {
    name: "",
    description: "",
    duration: MIN_DURATION
  };
  data.version.data.options.forEach(({ name }) => {
    initialFormData[name] = "";
  });
  const [formValue, setFormValue] = useState(initialFormData);
  const [installTarget, setInstallTarget] = useState("content");
  const [advertiserId,  setAdvertiserId] = useState();
  const dispatch = useDispatch();
  const { folders } = useSelector(state => state.files);
  const { responseToaster } = usePopup();

  const handleSubmit = async () => {
    if (!formRef.current.check() || (installTarget === "content" && !advertiserId)) {
      return;
    }

    const { name, description, duration, ...options } = formValue;
    const res = await saveFile({
      name,
      description,
      application_id: data.version.application_id, 
      application_options: options,
      ...(installTarget === "content" && { advertiser_id: advertiserId }),
      data: {
        context: "fullscreen",
        default_duration: +duration,
        orientation: "landscape",
      },
      type: "html",
      tags: [],
    });
    const resJson = await res.json();

    if (installTarget === "filler" && resJson.id) {
      const resFiller = await saveFiller({
        name: name,
        policy: [],
        end: null,
        start: null,
        playback_item: {
          content_items: [
            {
              content_item_data: {
                data: resJson.user_data.content_item_data.data,
                duration: +duration
              },
              id: resJson.id,
              playback_item_data: {}
            }
          ],
          data: { orientation: "landscape" },
        },
      })
      if (resFiller.ok) setFormValue(initialFormData);
      responseToaster(resFiller);
    } else {
      if (res.ok) setFormValue(initialFormData);
      responseToaster(res);
    }    
  };

  useEffect(() => {
    dispatch(fetchFolders("?limit=-1"));
  }, [dispatch]);

  return (
    <Form
      ref={formRef}
      onChange={setFormValue}
      formValue={formValue}
      fluid
    >
      <HeaderComponent
        title={`Установить виджет "${data.name}"`}
        tag="h3"
        gutter={0}
        right={<CloseButtonComponent onClose={onClose} />}
      />

      <InputFieldComponent
        name="name"
        label="Название *"
        rule={fieldRule()}
      />
      <InputFieldComponent name="description" label="Описание" />

      {data.version.data.options.map(field => (
        <InputFieldComponent
          key={field.name}
          label={`${field.title} *`}
          name={field.name}
          type={field.type}
          help={`Например: ${field.example}`}
          rule={fieldRule(field.options)}
        />
      ))}
  
      <InputFieldComponent label="Место установки">
        <SelectPicker
          data={[
            { label: "Контент", value: "content" },
            { label: "Филлеры", value: "filler" },
          ]}
          onChange={setInstallTarget}
          value={installTarget}
          block
        />
      </InputFieldComponent>

      {installTarget === "content" && (
        <InputFieldComponent label="Выберите папку *">
          <SelectPicker
            data={folders.objects.map(({ id, name }) => ({ label: name, value: id }))}
            value={advertiserId}
            onChange={setAdvertiserId}
            block
          />
        </InputFieldComponent>
      )}

      <InputFieldComponent
        type="number"
        name="duration"
        label="Длительность *"
        min={MIN_DURATION}
      />

      <Form.Group>
        <ButtonToolbar>
          <Button appearance="primary" onClick={handleSubmit}>Сохранить</Button>
        </ButtonToolbar>
      </Form.Group>
    </Form>
  );
};

export default WidgetInstallFormComponent;
